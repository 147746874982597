import React, { useEffect } from 'react';
import { Alert, Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ArtifactsList from '../../components/artifacts/ArtifactsList';
import PageWrapper from '../../components/PageWrapper';
import DepartmentsList from '../../components/departments/DepartmentsList';
import { getUserRootActorUUID } from '../../redux/storages/selectors';
import { getDepartment, getDepartmentUUID } from '../../redux/departments/selectors/departmentsSelector';
import useURLParams from '../../hooks/useURLParams';
import {
  GET_SINGLE_DEPARTMENT_FAILURE,
  GET_SINGLE_DEPARTMENT_REQUEST,
  GET_SINGLE_DEPARTMENT_SUCCESS,
  RESET_SINGLE_DEPARTMENT,
} from '../../redux/departments/slices/departmentsSlice';
import ArtifactCard from '../../components/artifacts/ArtifactCard';
import {
  GET_SINGLE_ARTIFACT_FAILURE,
  GET_SINGLE_ARTIFACT_REQUEST,
  GET_SINGLE_ARTIFACT_SUCCESS, RESET_SINGLE_ARTIFACT,
} from '../../redux/departments/slices/artifactsSlice';
import { getArtifact, getArtifactUUID, getIsFetchingArtifact } from '../../redux/departments/selectors/artifactsSelector';
import useDepartmentsAPI from '../../api/useDepartmentsAPI';
import useArtifactsAPI from '../../api/useArtifactsAPI';
import ListOfContractors from '../../components/contractors/ListOfContractors';
import { getProfileUUID } from '../../redux/profile/selectors';

export default function DepartmentContractorsPage() {
  const dispatch = useDispatch();

  const artifact = useSelector(getArtifact);
  const artifactUUID = useSelector(getArtifactUUID);
  const isFetchingArtifact = useSelector(getIsFetchingArtifact);

  const department = useSelector(getDepartment);

  const actorUUID = useSelector(getProfileUUID);

  const { readListOfDepartments } = useDepartmentsAPI();
  const { readListOfArtifacts } = useArtifactsAPI();

  const { getURLParams, clearSearchParams } = useURLParams();

  const {
    department: activeDepartment,
    artifact: activeArtifact,
  } = getURLParams();

  useEffect(() => {
    if (activeArtifact) {
      readListOfArtifacts(
        activeArtifact,
        { },
        [
          GET_SINGLE_ARTIFACT_REQUEST,
          GET_SINGLE_ARTIFACT_SUCCESS,
          GET_SINGLE_ARTIFACT_FAILURE,
        ],
      );
    } else {
      dispatch(RESET_SINGLE_ARTIFACT);
    }
  }, [activeArtifact]);

  useEffect(() => {
    if (activeDepartment) {
      readListOfDepartments(activeDepartment, {}, [
        GET_SINGLE_DEPARTMENT_REQUEST,
        GET_SINGLE_DEPARTMENT_SUCCESS,
        GET_SINGLE_DEPARTMENT_FAILURE,
      ]);
    } else {
      dispatch(RESET_SINGLE_DEPARTMENT);
    }
  }, [activeDepartment]);

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Подрядчики
        </Flex>
      )}
    >
      <Flex>
        <Flex
          vertical
          className="w-full"
        >
          <DepartmentsList
            hideAddButton
            hideKPIButton
            hideExpand
            // defaultConfig={{
            //   params: {
            //     usersSearch: [actorUUID],
            //   },
            // }}
            name="department_list"
            currentDepartment={department}
          />
          {activeDepartment ? (
            <ListOfContractors
              parent={activeDepartment}
              cardClassName="mt-6"
            />
          ) : (
            <Alert
              showIcon
              type="info"
              className="mt-6 text-blue-500 text-lg"
              message="Для просмотра списка подрядчиков необходимо выбрать отдел."
            />
          )}
        </Flex>

        {activeArtifact && (
        <ArtifactCard
          className="ml-6"
          isFetching={isFetchingArtifact}
          artifact={artifact}
          artifactUUID={artifactUUID}
          onBackArtCallback={() => {
            clearSearchParams(['artifact']);
          }}
          hideDeleteButton
          hideCopyURLButton
        />
        )}
      </Flex>
    </PageWrapper>
  );
}
