import { Flex } from 'antd';
import React from 'react';
import UsersVacationsRequestCard from '../../../pages/admin/userVacations/UsersVacationsRequestCard';
import UsersAgreedVacationsRequestCard from '../../../pages/admin/userVacations/UsersAgreedVacationsRequestCard';
import useURLParams from '../../../hooks/useURLParams';

export default function DepartmentVacations() {
  const { getURLParams } = useURLParams();

  const {
    d: activeDepartment,
  } = getURLParams();

  return (
    <Flex vertical className="w-full">
      <Flex className="w-full">
        <UsersVacationsRequestCard
          customParent={activeDepartment}
          cardClassName="blue-base-card"
        />
      </Flex>
      <Flex className="w-full mt-4">
        <UsersAgreedVacationsRequestCard
          customParent={activeDepartment}
          cardClassName="blue-base-card"
        />
      </Flex>
    </Flex>
  );
}
