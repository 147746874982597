import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'antd';
import {
  Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import EventsManagmentPage from './EventsManagmentPage';
import UniGeneralButtons from '../../components/commonComponents/uniGeneralButtons/UniGeneralButtons';
import PageWrapper from '../../components/PageWrapper';
import MetaeventsPanelPage from './MetaeventsPanelPage';
import './EventsPanelPage.scss';

const tabs = [
  { name: 'metaevents', title: 'Управление метасобытиями' },
  { name: 'triggers', title: 'Управление триггерами' },
];

export default function EventsPanelPage() {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get('tab') || 'metaevents';

  const [activeTab, setActiveTab] = useState(currentTab);

  const switchViewFunc = (name) => {
    setActiveTab(name);
    const params = new URLSearchParams();
    params.set('tab', name);
    history.push({
      pathname: '/events',
      search: params.toString(),
    });
  };

  useEffect(() => {
    if (!searchParams.get('tab')) {
      const params = new URLSearchParams();
      params.set('tab', 'triggers');
      history.replace({
        pathname: '/events',
        search: params.toString(),
      });
    }
  }, [history]);

  const renderContent = () => {
    switch (activeTab) {
      case 'triggers':
        return <EventsManagmentPage />;
      case 'metaevents':
        return <MetaeventsPanelPage />;
      default:
        return <MetaeventsPanelPage />;
    }
  };

  return (
    <PageWrapper
      title="Управление событиями"
      needWrapperClassName={false}
    >
      <Flex className="events-panel-tabs mb-3">
        <UniGeneralButtons
          viewMode={activeTab}
          switchViewFunc={switchViewFunc}
          tabs={tabs}
        />
      </Flex>
      {renderContent()}
    </PageWrapper>
  );
}

UniGeneralButtons.propTypes = {
  viewMode: PropTypes.string.isRequired,
  switchViewFunc: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
};
