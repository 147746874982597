/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/**
 * Начальное состояние хранилища
 * @typedef {Object} StoragesState
 * @property {Object|null} userRoot - Данные пользователя (на Delivery)
 * @property {boolean} isFetchingUser - Флаг загрузки данных пользователя (на Delivery)
 * @property {Object|null} vacationStorage - Storage для отпусков
 * @property {boolean} isFetchingVacationSt - Флаг загрузки storage отпусков
 * @property {Object|null} monthlyReportsStorage - Storage для данных ежемесячных отчетов
 * @property {boolean} isFetchingMonthlyReportsSt - Флаг загрузки storage ежемесячных отчетов
 * @property {Object|null} eventsStorage - Storage для событий
 * @property {boolean} isFetchingEventsSt - Флаг загрузки storage событий
 */
const initialState = {
  userRoot: null,
  isFetchingUser: true,
  vacationStorage: null,
  isFetchingVacationSt: true,
  monthlyReportsStorage: null,
  isFetchingMonthlyReportsSt: true,
  eventsStorage: null,
  isFetchingEventsSt: true,
};

const storagesSlice = createSlice({
  name: 'storagesSlice',
  initialState,
  reducers: {
    /**
     * Сброс определенного хранилища
     * @param {StoragesState} state - Текущее состояние
     * @param {Object} action - Action с типом хранилища для сброса
     */
    RESET_STORAGE(state, action) {
      if (action?.payload === 'vacationStorage') {
        state.vacationStorage = null;
        state.isFetchingVacationSt = true;
      }
      if (action?.payload === 'monthlyReportsStorage') {
        state.monthlyReportsStorage = null;
        state.isFetchingMonthlyReportsSt = true;
      }
    },
    SET_ROOT_USER(state, action) {
      state.userRoot = action.payload;
      state.isFetchingUser = false;
    },
    GET_ROOT_USER_REQUEST(state) {
      state.userRoot = null;
      state.isFetchingUser = true;
    },
    GET_ROOT_USER_SUCCESS(state, action) {
      const { payload } = action;

      state.userRoot = payload;
      state.isFetchingUser = false;
    },
    GET_ROOT_USER_FAILURE(state) {
      state.isFetchingUser = false;
    },
    GET_VACATION_STORAGE_REQUEST(state) {
      state.isFetchingVacationSt = true;
    },
    GET_VACATION_STORAGE_SUCCESS(state, action) {
      const { payload } = action;

      state.vacationStorage = payload;
      state.isFetchingVacationSt = false;
    },
    GET_VACATION_STORAGE_FAILURE(state) {
      state.isFetchingVacationSt = false;
    },
    GET_MONTHLY_REPORTS_STORAGE_REQUEST(state) {
      state.isFetchingMonthlyReportsSt = true;
    },
    GET_MONTHLY_REPORTS_STORAGE_SUCCESS(state, action) {
      const { payload } = action;
      state.monthlyReportsStorage = payload;
      state.isFetchingMonthlyReportsSt = false;
    },
    GET_MONTHLY_REPORTS_STORAGE_FAILURE(state) {
      state.isFetchingMonthlyReportsSt = false;
    },
    GET_EVENTS_STORAGE_REQUEST(state) {
      state.isFetchingEventsSt = true;
    },
    GET_EVENTS_STORAGE_SUCCESS(state, action) {
      const { payload } = action;
      state.eventsStorage = payload;
      state.isFetchingEventsSt = false;
    },
    GET_EVENTS_STORAGE_FAILURE(state) {
      state.isFetchingEventsSt = false;
    },
  },
});

export const {
  RESET_STORAGE,
  SET_ROOT_USER,
  GET_ROOT_USER_REQUEST,
  GET_ROOT_USER_SUCCESS,
  GET_ROOT_USER_FAILURE,
  GET_VACATION_STORAGE_REQUEST,
  GET_VACATION_STORAGE_SUCCESS,
  GET_VACATION_STORAGE_FAILURE,
  GET_MONTHLY_REPORTS_STORAGE_REQUEST,
  GET_MONTHLY_REPORTS_STORAGE_SUCCESS,
  GET_MONTHLY_REPORTS_STORAGE_FAILURE,
  GET_EVENTS_STORAGE_REQUEST,
  GET_EVENTS_STORAGE_SUCCESS,
  GET_EVENTS_STORAGE_FAILURE,
} = storagesSlice.actions;

export default storagesSlice.reducer;
