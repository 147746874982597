import React, { useState } from 'react';
import { string, shape, bool } from 'prop-types';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import EmployeeRequestForm from '../commonComponents/entityCardComponents/EmployeeRequestForm';

export default function EditRequest({ uuid, row, isDepartmentRoutes }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const { params } = row;

  const onOpenModal = () => setModalOpen(true);

  const onCancelModal = () => setModalOpen(false);

  return (
    <>
      <Button
        onClick={() => onOpenModal()}
        className="flex items-center"
        disabled={row.params.status !== 'pending' || isDepartmentRoutes}
      >
        <EditOutlined />
        Редактировать
      </Button>
      <Modal
        title="Редактоировать заявку"
        open={isModalOpen}
        onCancel={() => onCancelModal()}
        destroyOnClose
        width={750}
        footer={null}
      >
        <EmployeeRequestForm
          requestUUID={uuid}
          params={params}
          isEditMode
          closeModal={onCancelModal}
        />
      </Modal>
    </>
  );
}

EditRequest.propTypes = {
  uuid: string.isRequired,
  row: shape({
    params: shape({
      description: string,
      property: string,
      status: string,
      type: string,
    }),
  }).isRequired,
  isDepartmentRoutes: bool.isRequired,
};
