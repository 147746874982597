import { useDispatch } from 'react-redux';
import { get, omit } from 'lodash';
import useLogsAPI from './useLogsAPI';
import {
  entityCreate, entityDelete, entityRead, entityUpdate, getListAndReadEntities,
} from './actions/entity';
import { SERVICE_PARTITION_NAME } from './app_config';
import antNotification from '../utils/antNotification';

export default function useMetaeventsAPI() {
  const dispatch = useDispatch();

  const {
    createLog,
  } = useLogsAPI();

  const readListOfMetaevents = (entityUUIDs, data = {}, constants) => {
    try {
      return dispatch(
        entityRead({
          data: {
            entity_type: 'metaevent',
            entity_uuid: entityUUIDs,
            ...data,
          },
          constants: constants || [
            'READ_LIST_OF_METAEVENTS_REQUEST',
            'READ_LIST_OF_METAEVENTS_SUCCESS',
            'READ_LIST_OF_METAEVENTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список метасобытий!');
      return null;
    }
  };

  const getListOfMetaevents = (parent, data = {}, constants) => {
    try {
      return dispatch(
        getListAndReadEntities({
          data: {
            entity_type: 'metaevent',
            parent,
            depth: 0,
            ...data,
          },
          constants: constants || [
            'GET_LIST_OF_METAEVENTS_REQUEST',
            'GET_LIST_OF_METAEVENTS_SUCCESS',
            'GET_LIST_OF_METAEVENTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список метасобытий!');
      return null;
    }
  };

  const createMetaevent = async (parent, data, constants) => {
    try {
      const newMetaeventConfig = {
        parent,
        entity_type: 'metaevent',
        ...omit(data, ['files']),
      };

      const newFormData = new FormData();

      newFormData.append('data', JSON.stringify(newMetaeventConfig));

      if (data?.files?.length !== 0) {
        for (let i = 0; i < data?.files?.length; i += 1) {
          newFormData.append('file', data?.files?.[i]);
        }
      }

      const newMetaevent = await dispatch(
        entityCreate({
          data: newFormData,
          constants: constants || [
            'CREATE_METAEVENT_REQUEST',
            'CREATE_METAEVENT_SUCCESS',
            'CREATE_METAEVENT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(newMetaevent, '[0].uuid', '')) {
        createLog({
          parent: get(newMetaevent, '[0].uuid', ''),
          type: 'CREATE',
          entityType: 'metaevent',
          values: get(newMetaevent, '[0].params', ''),
          files: data?.files || [],
        });
      }

      return newMetaevent;
    } catch (e) {
      antNotification.error('Не удалось создать метасобытие!');
      return null;
    }
  };

  const updateMetaevent = async (entityUUID, data, constants) => {
    try {
      const updateMetaeventConfig = {
        entity_uuid: entityUUID,
        entity_type: 'metaevent',
        ...omit(data, ['files']),
      };

      const newFormData = new FormData();

      newFormData.append('data', JSON.stringify(updateMetaeventConfig));

      if (data?.files?.length !== 0) {
        for (let i = 0; i < data?.files?.length; i += 1) {
          newFormData.append('file', data?.files?.[i]);
        }
      }

      const updatedMetaevent = await dispatch(
        entityUpdate({
          data: newFormData,
          constants: constants || [
            'UPDATE_METAEVENT_REQUEST',
            'UPDATE_METAEVENT_SUCCESS',
            'UPDATE_METAEVENT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
      if (get(updatedMetaevent, '[0].uuid', '')) {
        createLog({
          parent: get(updatedMetaevent, '[0].uuid', ''),
          type: 'UPDATE',
          entityType: 'metaevent',
          values: get(data, 'params', {}),
          files: data?.files || [],
        });
      }

      return updatedMetaevent;
    } catch (e) {
      antNotification.error('Не удалось изменить метасобытие!');
      return null;
    }
  };

  const deleteMetaevent = (entityUUID, data, constants) => {
    try {
      return dispatch(
        entityDelete({
          data: {
            entity_uuid: entityUUID,
            entity_type: 'metaevent',
            ...data,
          },
          constants: constants || [
            'DELETE_METAEVENT_REQUEST',
            'DELETE_METAEVENT_SUCCESS',
            'DELETE_METAEVENT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось удалить метасобытие!');
      return null;
    }
  };

  return {
    createMetaevent,
    readListOfMetaevents,
    getListOfMetaevents,
    updateMetaevent,
    deleteMetaevent,
  };
}
