import React from 'react';
import { Flex } from 'antd';
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';
import { func, string } from 'prop-types';

export default function PencilEditButton({
  onClick,
  className = '',
}) {
  return (
    <Flex
      onClick={onClick}
      justify="center"
      align="center"
      style={{
        width: '22px',
        height: '22px',
        border: '1px solid blue',
      }}
      className={`cursor-pointer !border-blue-500 text-blue-500 rounded-full ${className}`}
    >
      <Icon path={mdiPencil} size={0.7} />
    </Flex>
  );
}

PencilEditButton.propTypes = {
  onClick: func,
  className: string,
};
