import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List, Spin, Tag } from 'antd';
import useLogsAPI from '../../../api/useLogsAPI';

const LOG_TYPES = {
  CREATE: {
    color: '#10b981',
    text: 'Создание',
  },
  UPDATE: {
    color: '#3b82f6',
    text: 'Обновление',
  },
  DELETE: {
    color: '#ef4444',
    text: 'Удаление',
  },
};

export default function MetaeventLogs({ metaeventUuid }) {
  const { getListOfLogs } = useLogsAPI();
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLogs = async () => {
      if (metaeventUuid) {
        setIsLoading(true);
        try {
          const res = await getListOfLogs({
            parent: metaeventUuid,
            affixTypes: ['metaeventLog'],
          });
          setLogs(res?.data || []);
        } catch (error) {
          console.error('Error loading logs:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchLogs();
  }, [metaeventUuid]);

  const renderLogContent = (log) => {
    const { type, values } = log.params;
    const logType = LOG_TYPES[type] || { color: '#9ca3af', text: type };

    return (
      <>
        <div className="log-header">
          <Tag color={logType.color}>{logType.text}</Tag>
          <span className="log-date">
            {new Date(log.created).toLocaleDateString('ru-RU', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </div>
        <div className="log-details">
          {values.title && (
            <div className="log-field">
              <span className="field-label">Название:</span>
              <span className="field-value">{values.title}</span>
            </div>
          )}
          {values.instruction && (
            <div className="log-field">
              <span className="field-label">Инструкция:</span>
              <pre className="field-value">{values.instruction}</pre>
            </div>
          )}
          {values.trigger && (
            <div className="log-field">
              <span className="field-label">Тип триггера:</span>
              <span className="field-value">{values.trigger}</span>
            </div>
          )}
        </div>
        <div className="log-author">
          {log.uinfo && (
            <span>
              {log.uinfo.first_name}
              {' '}
              {log.uinfo.last_name}
            </span>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="metaevent-logs">
      <Spin spinning={isLoading}>
        <List
          dataSource={logs}
          renderItem={(log) => (
            <List.Item>
              {renderLogContent(log)}
            </List.Item>
          )}
          locale={{ emptyText: 'Нет логов' }}
        />
      </Spin>
    </div>
  );
}

MetaeventLogs.propTypes = {
  metaeventUuid: PropTypes.string.isRequired,
};
