import React, { useEffect } from 'react';
import { capitalize, get, keys } from 'lodash';
import { Flex, Spin } from 'antd';
import {
  mdiHomeOutline,
  mdiAccountGroupOutline,
  mdiFileDocumentOutline,
  mdiCalendarOutline,
  mdiAccountPlusOutline,
  mdiHistory,
} from '@mdi/js';
import Icon from '@mdi/react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import useURLParams from '../../hooks/useURLParams';

import DepartmentOverview from './departmentViews/DepartmentOverview';
import DepartmentContractors from './departmentViews/DepartmentContractors';

import PageWrapper from '../PageWrapper';
import useDepartmentsAPI from '../../api/useDepartmentsAPI';
import {
  GET_SINGLE_DEPARTMENT_FAILURE,
  GET_SINGLE_DEPARTMENT_REQUEST,
  GET_SINGLE_DEPARTMENT_SUCCESS,
  RESET_SINGLE_DEPARTMENT,
} from '../../redux/departments/slices/departmentsSlice';
import { getDepartment, getIsFetchingDepartment } from '../../redux/departments/selectors/departmentsSelector';
import DepartmentVacations from './departmentViews/DepartmentVacations';
import DepartmentRequests from './departmentViews/DepartmentRequests';
import DepartmentReportsView from './departmentViews/DepartmentReportsView';
import DepartmentArtifactsView from './departmentViews/DepartmentArtifactsView';
import DepartmentLogsView from './departmentViews/DepartmentLogsView';

const DEPARTMENT_VIEWS_DEFAULT = [
  {
    label: 'Обзор',
    path: 'overview',
    component: DepartmentOverview,
    icon: mdiHomeOutline,
  },
  {
    label: 'Артефакты',
    path: 'artifacts',
    component: DepartmentArtifactsView,
    icon: mdiFileDocumentOutline,
  },
  {
    label: 'Подрядчики',
    path: 'contractors',
    component: DepartmentContractors,
    icon: mdiAccountGroupOutline,
  },
  {
    label: 'Отпуска',
    path: 'vacations',
    component: DepartmentVacations,
    icon: mdiCalendarOutline,
  },
  {
    label: 'Заявки',
    path: 'requests',
    component: DepartmentRequests,
    icon: mdiAccountPlusOutline,
  },
  {
    label: 'Отчеты',
    path: 'reports',
    component: DepartmentReportsView,
    icon: mdiFileDocumentOutline,
  },
];

const DEPARTMENT_VIEWS_SETTINGS = [
  // {
  //   label: 'Права доступа',
  //   path: 'access',
  //   component: DepartmentAccessView,
  //   icon: mdiAccountGroupOutline,
  // },
  {
    label: 'Логи',
    path: 'log',
    component: DepartmentLogsView,
    icon: mdiHistory,
  },
];

export default function MainDepartmentLayer() {
  const dispatch = useDispatch();
  const { readListOfDepartments } = useDepartmentsAPI();
  const { getURLParams, setSearchParams, addAndRemoveSearchParams } = useURLParams();
  const { v: view = 'overview', d: activeDepartment } = getURLParams();

  const department = useSelector(getDepartment);
  const isFetchingDepartment = useSelector(getIsFetchingDepartment);

  const CurrentView = DEPARTMENT_VIEWS_DEFAULT.find((v) => v.path === view)?.component
   || DEPARTMENT_VIEWS_SETTINGS.find((v) => v.path === view)?.component;

  const renderTabGeneral = ({ label, path, icon }) => (
    <Flex
      key={path}
      onClick={() => addAndRemoveSearchParams({ v: path }, ['artifact'])}
      style={{ border: '1px solid blue' }}
      className={cn(
        'mr-2 bg-sky-100 text-sky-500 text-lg border !border-sky-200 hover:!border-sky-400 cursor-pointer rounded-md px-5 py-3',
        { 'bg-sky-500 !text-white': view === path },
      )}
    >
      <Icon
        path={icon}
        size={1}
        className={cn('mr-2', { 'text-white': view === path })}
      />
      {label}
    </Flex>
  );

  const renderTabSettings = ({ label, path, icon }) => (
    <Flex
      key={path}
      onClick={() => addAndRemoveSearchParams({ v: path }, ['artifact'])}
      style={{ border: '1px solid blue' }}
      className={cn(
        'mr-2 bg-orange-100 text-orange-500 text-lg border !border-orange-200 hover:!border-orange-400 cursor-pointer rounded-md px-5 py-3',
        { 'bg-orange-500 !text-white': view === path },
      )}
    >
      <Icon
        path={icon}
        size={1}
        className={cn('mr-2', { 'text-white': view === path })}
      />
      {label}
    </Flex>
  );

  useEffect(() => {
    if (!activeDepartment) {
      dispatch(RESET_SINGLE_DEPARTMENT);
      return;
    }

    readListOfDepartments(activeDepartment, {}, [
      GET_SINGLE_DEPARTMENT_REQUEST,
      GET_SINGLE_DEPARTMENT_SUCCESS,
      GET_SINGLE_DEPARTMENT_FAILURE,
    ]);
  }, [activeDepartment]);

  return (
    <PageWrapper title={`Отдел ${get(department, 'params.title')}`}>
      <Spin className="w-full h-full" spinning={isFetchingDepartment}>
        <Flex vertical className="w-full h-full -mt-4">
          <Flex className="mb-4">
            <Flex vertical>
              <span className="text-sm text-gray-500">Управление</span>
              <Flex className="p-0.5 w-full bg-white">
                {DEPARTMENT_VIEWS_DEFAULT.map(renderTabGeneral)}
              </Flex>
            </Flex>
            <Flex vertical>
              <span className="text-sm text-gray-500">Настройки</span>
              <Flex className="p-0.5 w-full bg-white">
                {DEPARTMENT_VIEWS_SETTINGS.map(renderTabSettings)}
              </Flex>
            </Flex>
          </Flex>
          {!isFetchingDepartment && (
            <Flex className="w-full h-full">
              {CurrentView && <CurrentView />}
            </Flex>
          )}
        </Flex>
      </Spin>
    </PageWrapper>
  );
}
