import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Flex } from 'antd';
import { getDepartmentUUID } from '../../../redux/departments/selectors/departmentsSelector';
import ListOfLogs from '../../logs/ListOfLogs';
import CardOfEntityLogs from '../../logs/CardOfEntityLogs';

export default function DepartmentLogsView() {
  const departmentUUID = useSelector(getDepartmentUUID);

  const [currentEntity, setCurrentEntity] = useState(null);

  return (
    <Flex className="w-full">
      <ListOfLogs
        onSelectLog={setCurrentEntity}
        parent={departmentUUID}
        cardClassName={currentEntity ? 'w-2/3' : 'w-full'}
      />
      {currentEntity && (
      <CardOfEntityLogs
        currentLog={currentEntity?.log}
        onSelectLog={setCurrentEntity}
        currentEntity={currentEntity?.entity}
      />
      )}
    </Flex>
  );
}
