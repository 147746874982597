import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';

import {
  Alert, Card, Flex,
  Row, Space, Typography,
} from 'antd';

import DayOffPage from './dayOff/DayOffPage';
import PageWrapper from '../../components/PageWrapper';
import RequestVacationsCard from './vacationsRequests/RequestVacationsCard';
import VacationInfo from '../admin/userVacations/VacationInfo';
import UserReportForLastMonth from './statistics/personal/UserReportForLastMonth';
import PreAgreedUserVacations from './vacationsRequests/components/PreAgreedUserVacations';
import AgreedUserVacations from './vacationsRequests/components/AgreedUserVacations';
import StartWorkRecordTaskCard from '../../components/layout/startWorkRecord/StartWorkRecordTaskCard';

import { getProfileInfo } from '../../redux/profile/selectors';
import useURLParams from '../../hooks/useURLParams';

const { Paragraph } = Typography;

export default function TimeDashboard({
  title = 'Дашборд',
  actorUUID = '',
}) {
  const myProfileData = useSelector(getProfileInfo);

  const { getURLParams } = useURLParams();

  const { v: vacation } = getURLParams();

  const actor = actorUUID || myProfileData?.uuid;

  return (
    <>
      <PageWrapper
        title={title}
      >
        <Space direction="vertical" size="middle">
          <Card
            title="Мои задачи"
          >
            <Flex gap={8}>
              <StartWorkRecordTaskCard />
              <UserReportForLastMonth
                actor={actor}
              />
              <PreAgreedUserVacations
                actor={actor}
              />
            </Flex>

            <h3>Успешные задачи</h3>
            <hr />
            <Flex gap={8}>
              <StartWorkRecordTaskCard
                successTask
              />
              <UserReportForLastMonth
                actor={actor}
                onlySuccessTask
              />
              <AgreedUserVacations
                actor={actor}
              />
            </Flex>
          </Card>
          <Card
            title="Месячная статистика"
          >
            <Alert
              style={{ paddingInline: '16px', paddingBlock: '16px' }}
              message="Общие правила по отправке отчетов"
              type="info"
              showIcon
              className="w-100"
              description={(
                <div>
                  <Paragraph style={{ marginBottom: 2 }}>
                    1. Отправка отчета допускается только за прошедшие месяцы.
                    Досрочная отправка отчетов крайне нежелательна.
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 2 }}>
                    2. Статистика вычисляется на основании данных с &quot;Месячная статистика&quot;.
                  </Paragraph>
                </div>
              )}
            />
            <Row gutter={16} style={{ marginTop: 20 }}>
              <DayOffPage
                actor={actor}
              />
            </Row>
          </Card>
          <RequestVacationsCard actor={actor} />
        </Space>
      </PageWrapper>
      {vacation
        ? (
          <VacationInfo
            vacationUUID={vacation}
            showApprove={false}
          />
        )
        : null}
    </>
  );
}

TimeDashboard.propTypes = {
  title: string,
  actorUUID: string,
};

TimeDashboard.defaultProps = {
  title: 'Дашборд',
  actorUUID: '',
};
