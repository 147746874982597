import React from 'react';
import { Flex } from 'antd';
import { mdiArrowLeftBold, mdiArrowRightBold } from '@mdi/js';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import Icon from '@mdi/react';

export default function MonthNavigation({ selectedMonth, onPrevMonth, onNextMonth }) {
  return (
    <Flex align="center" className="mt-0 text-xl">
      <Icon
        className="mx-2 cursor-pointer text-blue-500 hover:bg-sky-100"
        onClick={onPrevMonth}
        path={mdiArrowLeftBold}
        size={1.2}
      />
      <Flex justify="center" className="text-xl text-blue-500" style={{ width: 130 }}>
        {capitalize(dayjs(selectedMonth).format('MMMM YYYY'))}
      </Flex>
      <Icon
        className="ml-2 cursor-pointer text-blue-500 hover:bg-sky-100"
        onClick={onNextMonth}
        path={mdiArrowRightBold}
        size={1.2}
      />
    </Flex>
  );
}
