import React, { useEffect } from 'react';
import { Alert, Card, Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ArtifactsList from '../../components/artifacts/ArtifactsList';
import PageWrapper from '../../components/PageWrapper';
import DepartmentsList from '../../components/departments/DepartmentsList';
import { getUserRootActorUUID } from '../../redux/storages/selectors';
import { getDepartment, getDepartmentUUID } from '../../redux/departments/selectors/departmentsSelector';
import useURLParams from '../../hooks/useURLParams';
import {
  GET_SINGLE_DEPARTMENT_FAILURE, GET_SINGLE_DEPARTMENT_REQUEST, GET_SINGLE_DEPARTMENT_SUCCESS, RESET_SINGLE_DEPARTMENT,
} from '../../redux/departments/slices/departmentsSlice';
import DepartmentReports from '../../components/departments/DepartmentReports';
import VacationsForDepartment from '../../components/departments/commonComponents/VacationsForDepartment';
import useDepartmentsAPI from '../../api/useDepartmentsAPI';
import UsersVacationsRequestCard from '../admin/userVacations/UsersVacationsRequestCard';
import UsersAgreedVacationsRequestCard from '../admin/userVacations/UsersAgreedVacationsRequestCard';
import { getProfileUUID } from '../../redux/profile/selectors';

export default function DepartmentVacationsPage() {
  const dispatch = useDispatch();

  const actorUUID = useSelector(getProfileUUID);

  const department = useSelector(getDepartment);
  const departmentUUID = useSelector(getDepartmentUUID);

  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams();

  const {
    readListOfDepartments,
  } = useDepartmentsAPI();

  const {
    department: activeDepartment,
    artifact: activeArtifact,
  } = getURLParams();

  useEffect(() => {
    if (activeDepartment) {
      readListOfDepartments(activeDepartment, {}, [
        GET_SINGLE_DEPARTMENT_REQUEST,
        GET_SINGLE_DEPARTMENT_SUCCESS,
        GET_SINGLE_DEPARTMENT_FAILURE,
      ]);
    } else {
      dispatch(RESET_SINGLE_DEPARTMENT);
    }
  }, [activeDepartment]);

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl w-full"
        >
          Отпуска отдела
        </Flex>
      )}
    >
      <Flex
        vertical
        className="w-full"
      >
        <DepartmentsList
          hideAddButton
          hideKPIButton
          hideExpand
          // defaultConfig={{
          //   params: {
          //     usersSearch: [actorUUID],
          //   },
          // }}
          name="department_list"
          currentDepartment={department}
        />
        {activeDepartment ? (
        // <Card
        //   className="w-full h-max p-0 pb-2 mt-2 blue-base-card"
        //   title={(
        //     <Flex
        //       align="center"
        //       className="text-xl"
        //     >
        //       Отпуска
        //     </Flex>
        // )}
        // >
        //   <VacationsForDepartment
        //     departmentUUID={departmentUUID}
        //   />
        // </Card>

          <>
            <Flex className="w-full mt-6">
              <UsersVacationsRequestCard
                customParent={activeDepartment}
                cardClassName="blue-base-card"
              />
            </Flex>
            <Flex className="w-full mt-6">
              <UsersAgreedVacationsRequestCard
                customParent={activeDepartment}
                cardClassName="blue-base-card"
              />
            </Flex>
          </>
        ) : (
          <Alert
            showIcon
            type="info"
            className="mt-6 text-blue-500 text-lg"
            message="Для просмотра отпусков необходимо выбрать отдел."
          />
        )}
      </Flex>
    </PageWrapper>
  );
}
