import React, { useMemo, useState } from 'react';
import {
  Button,
  Card, Divider, Space,
} from 'antd';
import dayjs from 'dayjs';

import { CSVLink } from 'react-csv';

import { capitalize, get } from 'lodash';
import Icon from '@mdi/react';
import { mdiFileExportOutline } from '@mdi/js';
import DateSwitcher from '../../../components/commonComponents/dateSwitcher/DateSwitcher';
import StatisticsCard from './StatisticsCard';
import PageWrapper from '../../../components/PageWrapper';

export default function AdminStatistics() {
  const [config, setConfig] = useState({
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
  });

  const [dataTable, setDataTable] = useState([]);

  const onChangeDate = (date) => {
    setConfig({
      startDate: dayjs(date).format('YYYY-MM-DD'),
      endDate: dayjs(date).endOf('month').format('YYYY-MM-DD'),
    });
  };

  const getStatistics = useMemo(() => dataTable.map((i) => ({
    Имя: `${i?.uinfo?.first_name} ${i?.uinfo?.last_name}`,
    'Проектных часов': get(i, 'totalWorks'),
    Бенчи: get(i, 'totalBenches'),
    'Нерабочих часов': get(i, 'totalDayOff'),
    Отгул: get(i, 'dayoff'),
    Больничный: get(i, 'sickday'),
    Отпуск: get(i, 'vacation'),
    'Бенч 1 месяц': get(i, 'bench_1_month'),
    'Бенч больше месяца': get(i, 'bench_over_1_month'),
    'Бенч больше 3-х месяцев': get(i, 'bench_over_3_month'),
  })), [JSON.stringify(dataTable)]);

  return (
    <PageWrapper
      title={capitalize('Промежуточная статистика')}
    >
      <Card
        title={(
          <Space split={<Divider type="vertical" />} align="center">
            <DateSwitcher
              currentDate={config.startDate}
              onChangeHandler={onChangeDate}
            />

            <Button type="primary" disabled={!dataTable.length}>
              <CSVLink
                data={getStatistics}
                filename={`отчет за ${dayjs(config.startDate).format('YYYY-MM')}.csv`}
                className="flex items-center gap-2"
                onClick={() => {
                  if (!dataTable.length) {
                    return false;
                  }
                }}
              >
                <Icon path={mdiFileExportOutline} size={1} />
                Экспорт в CSV
              </CSVLink>
            </Button>
          </Space>
        )}
        className="card-h-full"
      >
        <div className="relative h-full w-full flex box-border overflow-auto">
          <div className="relative h-full w-full flex flex-col box-border pl-2 overflow-auto">
            <StatisticsCard
              dataTable={dataTable}
              startMonth={config.startDate}
              endMonth={config.endDate}
              setDataTable={setDataTable}
            />
          </div>
        </div>
      </Card>
    </PageWrapper>
  );
}
