import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Badge, Col, Descriptions, Flex, Form, Modal, Row,
} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { head } from 'lodash';
import dayjs from 'dayjs';
import { diffDays, formatDate } from '../utils/dateTimeUtils';
import antNotification from '../utils/antNotification';
import useVacationsAPI from '../api/useVacationsAPI';
import AddVacationForm from '../pages/user/vacationsRequests/components/AddVacationForm';
import { getProfileInfo, getProfileUUID } from '../redux/profile/selectors';
import useMetaeventsAPI from '../api/useMetaeventsAPI';
import { getDeliveryPartition } from '../redux/config/selectors';
import useEventsAPI from '../api/useEventsAPI';
import { getEventsStorageUUID } from '../redux/storages/selectors';

export default function useTriggersVacation() {
  const { updateVacation } = useVacationsAPI();
  const { getListOfMetaevents } = useMetaeventsAPI();
  const { createEvent } = useEventsAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const profileActor = useSelector(getProfileInfo);
  const profileActorUUID = useSelector(getProfileUUID);

  const eventsStorageUUID = useSelector(getEventsStorageUUID);

  const [trigger, setTrigger] = useState('');

  const [options, setOptions] = useState({});

  function CreateVacationRequestComponent() {
    const [isModalOpen, setModalOpen] = useState(true);

    const [form] = Form.useForm();

    const initValues = {
      start_date: null,
      end_date: null,
      status: 'created',
      type: 'vacation',
      comment: '',
    };

    const { saveCallback, actor, metaevent } = options;

    const onFinish = async (values) => {
      saveCallback?.();
      await createEvent(eventsStorageUUID, {
        actor,
        params: {
          vacation: head(values)?.uuid,
          metaevent: metaevent?.uuid,
        },
      });
    };

    const showConfirm = () => {
      Modal.confirm({
        title: 'Вы уверены в этом?',
        icon: <ExclamationCircleFilled />,
        content: 'Изменения не будут сохранены',
        centered: true,
        onOk() {
          setModalOpen(false);
          form.resetFields();
        },
        onCancel() {
          setModalOpen(true);
        },
      });
    };

    const onCancelModal = () => {
      if (form.isFieldsTouched()) {
        showConfirm();
        return;
      }
      form.resetFields();
      setModalOpen(false);
    };
    console.log('isModalOpen', profileActor);
    useEffect(() => {
      if (!isModalOpen) {
        setTrigger('');
        setOptions({});
      }
    }, [isModalOpen]);

    return (
      <Modal
        open={isModalOpen}
        title="Заявка на отпуск"
        width={1250}
        centered
        destroyOnClose
        footer={null}
        onCancel={onCancelModal}
      >
        <AddVacationForm
          metaevent={metaevent}
          form={form}
          initValues={initValues}
          onCancelModal={onCancelModal}
          saveCallback={onFinish}
          actor={actor}
        />
      </Modal>
    );
  }

  function PreAgreeVacationRequestComponent() {
    const [isModalOpen, setModalOpen] = useState(true);

    const { vacation, saveCallback, metaevent } = options;

    const approveVacation = async () => {
      try {
        await updateVacation(vacation.uuid, {
          params: {
            status: 'pre-agreed',
            agreed: {
              uinfo: profileActor.uinfo,
              actor: profileActorUUID,
            },
          },
        });
        saveCallback();
        setModalOpen(false);
        antNotification.success('Заявка на отпуск предварительно согласована.');
      } catch {
        antNotification.error('Ошибка!');
      }
    };

    useEffect(() => {
      if (!isModalOpen) {
        setTrigger('');
        setOptions({});
      }
    }, [isModalOpen]);

    return (
      <Modal
        open={isModalOpen}
        title={`Предварительное согласование отпуска для ${vacation.uinfo.first_name} ${vacation.uinfo.last_name}?`}
        width={500}
        onCancel={() => setModalOpen(false)}
        onOk={approveVacation}
      >
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={24}>
            <Alert
              className="w-full mb-2 -p-8"
              message={metaevent?.params?.instruction_title || 'Правила по согласованиям отпусков'}
              description={(
                <Flex vertical>
                  <pre className="whitespace-pre-wrap font-sans ">
                    {metaevent?.params?.instruction}
                  </pre>
                </Flex>
            )}
              type="info"
              showIcon
            />
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Имя:">
                {vacation.uinfo.first_name}
              </Descriptions.Item>
              <Descriptions.Item label="Фамилия:">
                {vacation.uinfo.last_name}
              </Descriptions.Item>
              <Descriptions.Item label="Дата начала:">
                {formatDate(vacation.params.start_date)}
              </Descriptions.Item>
              <Descriptions.Item label="Дата окончания:">
                {formatDate(vacation.params.end_date)}
              </Descriptions.Item>
              <Descriptions.Item label="Количество дней:">
                <Badge
                  count={diffDays(vacation.params.start_date, vacation.params.end_date)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Комментарий:">
                {vacation.params.comment || '-'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Modal>
    );
  }

  function ApproveVacationRequestComponent() {
    const [isModalOpen, setModalOpen] = useState(true);

    const { vacation, saveCallback, metaevent } = options;

    const approveVacation = async () => {
      try {
        await updateVacation(vacation.uuid, {
          params: {
            status: 'approved',
            reviewed: {
              uinfo: profileActor.uinfo,
              actor: profileActorUUID,
            },
          },
        });
        saveCallback();
        setModalOpen(false);
        antNotification.success('Заявка на отпуск утверждена.');
      } catch {
        antNotification.error('Ошибка!');
      }
    };

    useEffect(() => {
      if (!isModalOpen) {
        setTrigger('');
        setOptions({});
      }
    }, [isModalOpen]);

    return (
      <Modal
        open={isModalOpen}
        title={`Утверждение отпуска для ${vacation.uinfo.first_name} ${vacation.uinfo.last_name}?`}
        width={500}
        onCancel={() => setModalOpen(false)}
        onOk={approveVacation}
      >
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={24}>
            <Alert
              message={metaevent?.params?.instruction_title || 'Правила по утверждению отпусков'}
              className="w-full mb-2 -p-8"
              description={(
                <Flex vertical>
                  <pre className="whitespace-pre-wrap font-sans ">
                    {metaevent?.params?.instruction}
                  </pre>
                </Flex>
            )}
              type="info"
              showIcon
            />
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Имя:">
                {vacation.uinfo.first_name}
              </Descriptions.Item>
              <Descriptions.Item label="Фамилия:">
                {vacation.uinfo.last_name}
              </Descriptions.Item>
              <Descriptions.Item label="Дата начала:">
                {formatDate(vacation.params.start_date)}
              </Descriptions.Item>
              <Descriptions.Item label="Дата окончания:">
                {formatDate(vacation.params.end_date)}
              </Descriptions.Item>
              <Descriptions.Item label="Количество дней:">
                <Badge
                  count={diffDays(vacation.params.start_date, vacation.params.end_date)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Комментарий:">
                {vacation.params.comment || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Дополнительная информация после согласования:">
                {vacation.params.additionalComment || '-'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Modal>
    );
  }

  function RejectVacationRequestComponent() {
    const [isModalOpen, setModalOpen] = useState(true);

    const { vacation, saveCallback, metaevent } = options;

    const rejectVacation = async () => {
      try {
        await updateVacation(vacation.uuid, {
          params: {
            status: 'rejected',
            reviewed: {
              uinfo: profileActor.uinfo,
              actor: profileActorUUID,
            },
          },
        });
        saveCallback();
        setModalOpen(false);
        antNotification.success('Заявка на отпуск успешно отклонена.');
      } catch {
        antNotification.error('Что-то пошло не так.');
      }
    };

    useEffect(() => {
      if (!isModalOpen) {
        setTrigger('');
        setOptions({});
      }
    }, [isModalOpen]);

    return (
      <Modal
        open={isModalOpen}
        title="Отклонение заявки на отпуск"
        icon={<ExclamationCircleFilled />}
        okText="Отклонить"
        okButtonProps={{
          danger: true,
        }}
        onCancel={() => setModalOpen(false)}
        cancelText="Отмена"
        onOk={rejectVacation}
      >
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={24}>
            <Alert
              message={metaevent?.params?.instruction_title || 'Правила по отклонению отпусков'}
              className="w-full mb-2 -p-8"
              description={(
                <Flex vertical>
                  <pre className="whitespace-pre-wrap font-sans ">
                    {metaevent?.params?.instruction}
                  </pre>
                </Flex>
            )}
              type="info"
              showIcon
            />
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Имя:">
                {vacation.uinfo.first_name}
              </Descriptions.Item>
              <Descriptions.Item label="Фамилия:">
                {vacation.uinfo.last_name}
              </Descriptions.Item>
              <Descriptions.Item label="Дата начала:">
                {formatDate(vacation.params.start_date)}
              </Descriptions.Item>
              <Descriptions.Item label="Дата окончания:">
                {formatDate(vacation.params.end_date)}
              </Descriptions.Item>
              <Descriptions.Item label="Количество дней:">
                <Badge
                  count={diffDays(vacation.params.start_date, vacation.params.end_date)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Комментарий:">
                {vacation.params.comment || '-'}
              </Descriptions.Item>
              {vacation?.params?.status === 'pre-agreed' && (
              <Descriptions.Item label="Дополнительная информация после согласования:">
                {vacation.params.additionalComment || '-'}
              </Descriptions.Item>
              )}
            </Descriptions>
          </Col>
        </Row>
      </Modal>
    );
  }

  async function run(type, newOptions = {}) {
    const metaevents = await getListOfMetaevents(partitionUUID, {
      params: {
        trigger: type,
        groups: profileActor?.uinfo?.groups,
      },
    });

    // Получаем группы текущего пользователя
    const userGroups = new Set(profileActor?.uinfo?.groups || []);

    // Находим метасобытие с наибольшим весом через reduce
    const selectedMetaevent = metaevents?.data?.reduce((best, current) => {
      // Вычисляем вес текущего метасобытия
      const metaeventGroups = new Set(current.params?.groups || []);
      let weight = 0;

      if (metaeventGroups.size > 0 && userGroups.size > 0) {
        const matchingGroups = [...metaeventGroups].filter((group) => userGroups.has(group));
        weight = Math.round((matchingGroups.length / metaeventGroups.size) * 10);
      }

      // Если это первое метасобытие или его вес больше предыдущего лучшего
      if (!best || weight > best.weight) {
        return { ...current, weight };
      }

      // Если веса равны, сравниваем по дате создания
      if (weight === best.weight && dayjs(current.created).isAfter(dayjs(best.created))) {
        return { ...current, weight };
      }

      return best;
    }, null);

    console.log('Selected metaevent:', selectedMetaevent);

    setOptions({ ...newOptions, metaevent: selectedMetaevent?.weight > 0 ? selectedMetaevent : null });
    setTrigger(type);
  }

  const EventComponent = useMemo(() => {
    switch (trigger) {
      case 'create-vacation-request':
        return CreateVacationRequestComponent;
      case 'reject-vacation-request':
        return RejectVacationRequestComponent;
      case 'approve-vacation-request':
        return ApproveVacationRequestComponent;
      case 'pre-approve-vacation-request':
        return PreAgreeVacationRequestComponent;
      default:
        return () => null;
    }
  }, [trigger]);

  return {
    run,
    EventComponent,
  };
}
