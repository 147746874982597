import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button, Space, Tag, Alert,
} from 'antd';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import axiosRequest from '../../../api/api';

const { TextArea } = Input;

export default function CreateVacationRequestForm({ trigger, onSubmit, onCancel }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [instructionTitle, setInstructionTitle] = useState('');
  const [instruction, setInstruction] = useState('');

  const handleSubmit = (values) => {
    onSubmit({
      ...values,
      trigger: trigger.value,
      groups: selectedGroups,
    });
    form.resetFields();
  };

  const toggleGroup = (groupUuid) => {
    setSelectedGroups((prev) => {
      if (prev.includes(groupUuid)) {
        return prev.filter((id) => id !== groupUuid);
      }
      return [...prev, groupUuid];
    });
    form.setFieldValue('groups', selectedGroups);
  };

  function getListOfAllGroups() {
    return dispatch(axiosRequest.post(
      'utility/actor/list',
      [
        'GET_LIST_OF_GROUPS_REQUEST',
        {
          type: 'GET_LIST_OF_GROUPS_SUCCESS',
          payload: (res) => {
            console.log(res);
            setGroups(res?.data || []);
            return get(res, 'data', res);
          },
        },
        'GET_LIST_OF_GROUPS_FAILURE',
      ],
      {
        actor_type: 'group',
        not_banned: true,
      },
    ));
  }

  useEffect(() => {
    getListOfAllGroups();
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Form.Item
        name="title"
        label="Название метасобытия"
        rules={[{ required: true, message: 'Введите название' }]}
      >
        <Input placeholder="Название метасобытия" />
      </Form.Item>

      <div className="p-4 border border-dashed border-gray-400 rounded-md mb-4">
        <Form.Item
          name="instruction_title"
          label="Название инструкции"
          rules={[{ required: true, message: 'Введите название инструкции' }]}
        >
          <Input
            placeholder="Введите название инструкции"
            onChange={(e) => setInstructionTitle(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="instruction"
          label="Инструкция"
          rules={[{ required: true, message: 'Введите инструкцию' }]}
        >
          <TextArea
            rows={6}
            placeholder="Опишите инструкцию для этого метасобытия"
            onChange={(e) => setInstruction(e.target.value)}
          />
        </Form.Item>

        <span className="text-sm text-gray-500 mb-3">Предпросмотр инструкции</span>
        <Alert
          message={instructionTitle || 'Название инструкции не указано'}
          description={(
            <div>
              <p className="whitespace-pre-wrap">
                {instruction || 'Инструкция не заполнена'}
              </p>
            </div>
        )}
          type="info"
          showIcon
        />
      </div>

      <Form.Item
        name="groups"
        label="Группы пользователей"
        rules={[{
          validator: () => {
            if (selectedGroups.length === 0) {
              return Promise.reject('Выберите хотя бы одну группу');
            }
            return Promise.resolve();
          },
        }]}
      >
        <div className="groups-selector">
          {groups.map((group) => (
            <Tag
              key={group.uuid}
              className={selectedGroups.includes(group.uuid) ? 'selected' : ''}
              onClick={() => toggleGroup(group.uuid)}
            >
              {group.uinfo?.group_name}
            </Tag>
          ))}
        </div>
      </Form.Item>

      <Form.Item className="form-actions">
        <Space>
          <Button onClick={onCancel}>
            Отмена
          </Button>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

CreateVacationRequestForm.propTypes = {
  trigger: PropTypes.shape({
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
