/* eslint-disable default-param-last */

import { useDispatch, useSelector } from 'react-redux';
import { SERVICE_PARTITION_NAME } from './app_config';
import {
  getListOfEntities,
} from './actions/entity';
import antNotification from '../utils/antNotification';
import { getDeliveryPartition } from '../redux/config/selectors';
import { getUserRootActorUUID } from '../redux/storages/selectors';
import {
  IS_USER_DEPARTMENT_MANAGEMENT_FAILURE,
  IS_USER_DEPARTMENT_MANAGEMENT_REQUEST,
  IS_USER_DEPARTMENT_MANAGEMENT_SUCCESS,
} from '../redux/pages/pageAccessSlice';
import { getProfileUUID } from '../redux/profile/selectors';

export default function usePageAccessAPI() {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);
  const actorUUID = useSelector(getProfileUUID);

  const checkAccessToDepartmentPanel = () => {
    try {
      return dispatch(
        getListOfEntities({
          data: {
            entity_type: 'department',
            parent: partitionUUID,
            depth: 0,
            params: {
              usersSearch: [actorUUID],
            },
          },
          constants: [
            IS_USER_DEPARTMENT_MANAGEMENT_REQUEST,
            IS_USER_DEPARTMENT_MANAGEMENT_SUCCESS,
            IS_USER_DEPARTMENT_MANAGEMENT_FAILURE,
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      return null;
    }
  };

  return {
    checkAccessToDepartmentPanel,
  };
}
