import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { get } from 'lodash';
import {
  Button, Card, Flex, Input, Modal, Spin,
} from 'antd';
import dayjs from 'dayjs';
import {
  AutoSizer, List,
} from 'react-virtualized';
import { getDeliveryPartition } from '../../redux/config/selectors';
import { getProfileUUID } from '../../redux/profile/selectors';
import useURLParams from '../../hooks/useURLParams';
import { getDefaultDepartmentUUID } from '../../redux/departments/selectors/defaultDepartmentSelector';
import useDepartments from '../../redux/departments/hooks/useDepartments';
import useArtifacts from '../../redux/departments/hooks/useArtifacts';
import AntDesignSearchBox54origins from '../../54origins/components/AntDesignSearchBox54origins';
import ContractorLabel from '../../components/contractors/commonComponents/ContractorLabel';
import BaseTableWithPagination from '../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import PageWrapper from '../../components/PageWrapper';
import { getFullNameOrInitials } from '../../utils/utils54origins';
import antNotification from '../../utils/antNotification';

const { TextArea } = Input;

const offices = [
  {
    id: 'd5b0eae0-1a3b-4f9e-bdd9-8a1e5c4c6de5',
    name: 'Главный офис',
    address: 'Москва, ул. Ленина, д. 1',
    manager: 'Ivan Ivanov',
  },
  {
    id: 'a9b88e5b-2d73-4939-83f4-1b1c4eec6c83',
    name: 'Филиал в Санкт-Петербурге',
    address: 'Санкт-Петербург, пл. Революции, д. 5',
    manager: 'Petr Petrov',
  },
  {
    id: 'fc3c0cb5-77bb-4c99-9f89-4a4baa6c9b62',
    name: 'Региональный офис в Екатеринбурге',
    address: 'Екатеринбург, ул. Карла Маркса, д. 10',
    manager: 'Svetlana Svetlova',
  },
  {
    id: 'e8ab3c64-4c7f-4e8f-9c7f-3c0ae7b3a6e5',
    name: 'Офис в Новосибирске',
    address: 'Новосибирск, ул. Красный проспект, д. 15',
    manager: 'Aleksey Smirnov',
  },
  {
    id: 'd1f6e6c0-bd74-4c10-8a25-1b7d7a2b9c60',
    name: 'Филиал в Казани',
    address: 'Казань, ул. Баумана, д. 6',
    manager: 'Maria Alekseevna',
  },
  {
    id: '27be8e3f-0a68-4e7a-bd3e-4d3a92e00ba3',
    name: 'Офис в Нижнем Новгороде',
    address: 'Нижний Новгород, ул. Горького, д. 8',
    manager: 'Dmitriy Kuznetsov',
  },
  {
    id: '5f8f5e7b-87a2-4e2d-9f9f-1ef8f4a4885d',
    name: 'Филиал в Ростове-на-Дону',
    address: 'Ростов-на-Дону, ул. Садовая, д. 4',
    manager: 'Olga Ivanova',
  },
  {
    id: 'a23d5b7c-68ae-4f6d-8b74-0d51c9f4a90e',
    name: 'Офис в Самаре',
    address: 'Самара, ул. Фрунзе, д. 12',
    manager: "Elena Vasil'eva",
  },
  {
    id: 'cbf1b765-01a5-4b34-bd4b-5c3c2e1a48e3',
    name: 'Филиал в Уфе',
    address: 'Уфа, ул. 50 лет Октября, д. 20',
    manager: 'Andrey Petrovich',
  },
  {
    id: 'b5c0fa00-13b7-4e6a-a5d9-1a1f0b5a4e1d',
    name: 'Офис в Челябинске',
    address: 'Челябинск, ул. Труда, д. 3',
    manager: 'Natalya Sergeyeva',
  },
  {
    id: '1a3a2d56-e1b1-4c67-a8f4-8e9a9f3e7e2f',
    name: 'Филиал в Владивостоке',
    address: 'Владивосток, ул. Светланская, д. 11',
    manager: 'Maksim Kovalev',
  },
  {
    id: '8a6c7b2e-67c9-4b1e-9c7c-4d0e0e5f9e43',
    name: 'Офис в Краснодаре',
    address: 'Краснодар, ул. Красная, д. 19',
    manager: 'Anastasiya Mikhaylova',
  },
  {
    id: '1b2e63b6-5c88-4de6-9c2b-452b49e7b7f3',
    name: 'Филиал в Омске',
    address: 'Омск, ул. Ленина, д. 30',
    manager: 'Igor Sidorov',
  },
  {
    id: 'c0c66c54-4e6f-4f29-8e5d-4d6f3e7b0d5',
    name: 'Офис в Саратове',
    address: 'Саратов, ул. Московская, д. 5',
    manager: 'Tatyana Fedorova',
  },
  {
    id: '3cde9c85-fd3c-4b38-8c1a-2c1f3e7b8cb8',
    name: 'Филиал в Тюмени',
    address: 'Тюмень, ул. Республики, д. 7',
    manager: 'Viktoriya Gromova',
  },
  {
    id: 'b0f0d6b8-6c35-42e4-98d0-4b2f6e8d3b04',
    name: 'Офис в Ярославле',
    address: 'Ярославль, ул. Свободы, д. 14',
    manager: 'Daniil Levin',
  },
  {
    id: 'f8a7e5d1-1b6f-4e2b-8513-0f0f97d5c0b2',
    name: 'Филиал в Архангельске',
    address: 'Архангельск, ул. Воскресенская, д. 2',
    manager: 'Kirill Stepanov',
  },
  {
    id: '3e5781d6-1e4f-4f71-bb0d-8f75dd6e90a9',
    name: 'Офис в Иркутске',
    address: 'Иркутск, ул. Карла Маркса, д. 22',
    manager: 'Elizaveta Nikiforova',
  },
  {
    id: '2a9f9e0c-7b29-4c3e-bf45-3e4c3f5c7e1e',
    name: 'Филиал в Туле',
    address: 'Тула, ул. Оборонная, д. 9',
    manager: 'Grigoriy Romanov',
  },
  {
    id: 'b1e0f1c1-e2d5-4f68-b0c4-1549c2c6f1d8',
    name: 'Офис в Бийске',
    address: 'Бийск, ул. Ленина, д. 45',
    manager: 'Sofya Petrova',
  },
];

export default function UsersForHrPanelPage() {
  const partitionUUID = useSelector(getDeliveryPartition);
  const actorUUID = useSelector(getProfileUUID);

  const { setSearchParams, getURLParams } = useURLParams();

  const defaultDepartmentUUID = useSelector(getDefaultDepartmentUUID);

  const { activeArtifact } = getURLParams();

  const [userOnboarding, setUserOnboarding] = useState(null);

  const [instruction, setInstruction] = useState('');
  const [office, setOffice] = useState('');

  const [wasOnBoarding, setWasOnBoarding] = useState([]);
  const addNewOnBoarding = (uuid) => setWasOnBoarding((prev) => ([...prev, uuid]));

  const {
    departments,
    isFetching: isFetchingDepartments,
  } = useDepartments({
    defaultConfig: {
      depth: 0,
      limit: null,
    },
  });

  const {
    artifacts,
    isFetching: isFetchingArtifacts,
    paginationOptions,
    valueSearch,
    onChangeSearch,
    onReloadArtifacts,
  } = useArtifacts({
    parentUUID: partitionUUID,
    defaultConfig: {
      entity_type: 'user',
      depth: 0,
      limit: 50,
    },
  });

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === activeArtifact,
  });

  //   const onRow = (record) => ({
  //     onClick: () => {
  //       const uuid = get(record, 'uuid', '');

  //       setSearchParams({ artifact: uuid });
  //     },
  //   });

  const columns = [
    {
      key: 'name',
      title: (
        <div className="flex items-center">
          <span className="mr-2">Имя</span>
          <AntDesignSearchBox54origins
            maxWidth={250}
            valueSearch={valueSearch}
            onSearch={onChangeSearch}
          />
        </div>
      ),
      dataIndex: ['uinfo'],
      width: '30%',
      render: (cell, row) => (
        <ContractorLabel
          user={row}
        />
      ),
    },
    {
      key: 'department',
      title: 'Отдел',
      dataIndex: ['parent'],
      width: '30%',
      render: (cell, row) => {
        const department = departments?.find((el) => el?.uuid === cell);
        if (department) {
          return <span className="text-blue-500">{department?.params?.title}</span>;
        }
        return <span className="text-red-500">Нет отдела</span>;
      },
    },
    {
      key: 'created',
      title: 'Добавлен',
      dataIndex: ['created'],
      width: '30%',
      className: 'text-sm text-gray-500',
      render: (cell) => dayjs(cell).format('DD MMMM YYYY HH:mm:ss'),
    //   render: (cell, row) => {
    //     const department = departments?.find((el) => el?.uuid === cell);
    //     if (department) {
    //       return <span className="text-blue-500">{department?.params?.title}</span>;
    //     }
    //     return <span className="text-red-500">Нет отдела</span>;
    //   },
    },
    {
      key: 'uuid',
      title: '',
      dataIndex: ['uuid'],
      width: '10%',
      render: (cell, row) => {
        const department = departments?.find((el) => el?.uuid === row?.parent);
        return (
          <Flex>
            {!department && !wasOnBoarding?.includes(cell) && (
            <Button
              size="small"
              onClick={() => setUserOnboarding(row)}
              style={{ border: '1px solid black' }}
              className="text-blue-500 !border-blue-500"
            >
              Онбординг
            </Button>
            )}
            {wasOnBoarding?.includes(cell) && (
            <Flex
              className="text-green-500"
            >
              Онбординг в процессе
            </Flex>
            )}
          </Flex>
        );
      },
    },
  ];

  const rowRenderer = ({ index, style, isScrolling }) => {
    if (offices[index] === undefined && isScrolling) {
      return (
        <div key={index} style={style}>
          Scrolling...
        </div>
      );
    }

    const {
      id, manager, name, address,
    } = offices[index];

    return (
      <div
        key={id}
        style={{ ...style, borderBottom: '1px solid black' }}
        className={`!border-gray-200 !w-full hover:bg-gray-100 ${id === office ? 'bg-blue-50' : ''} cursor-pointer pl-3`}
        onClick={() => setOffice(id === office ? null : id)}
      >
        <Flex
          vertical
          className="w-full mt-1"
        >
          <span>{name}</span>
          <span className="text-sm text-gray-500">{address}</span>
          <span className="text-sm text-gray-500">
            Руководитель:
            {' '}
            {manager}
          </span>
        </Flex>
      </div>
    );
  };

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Пользователи
        </Flex>
      )}
    >
      <Card
        className="w-full p-0 pb-3 h-max blue-base-card"
        title={(
          <Flex vertical>
            <Flex
              className="text-xl"
            >
              Пользователи
            </Flex>
          </Flex>
    )}
      >
        <Flex className="px-2 w-full" vertical>
          <Spin
            className="w-full h-100"
            spinning={isFetchingDepartments || isFetchingArtifacts}
          >
            <BaseTableWithPagination
              columns={columns}
              data={artifacts}
              rowKey="uuid"
              size="small"
              className="w-full h-full !mb-3"
              pageSizeOptions={[20, 50, 100]}
              rowClassName={rowClassName}
              useCustomPagination
              disablePagination={false}
              {...paginationOptions}
            />
          </Spin>
        </Flex>
      </Card>

      {userOnboarding && (
      <Modal
        title={`Планирование онбординга для пользователя ${getFullNameOrInitials(userOnboarding, 'fullname')}`}
        open={userOnboarding}
        footer={null}
        centered
        onCancel={() => setUserOnboarding(null)}
        width={550}
        destroyOnClose
      >
        <Flex className="mb-1">Инструкция:</Flex>
        <TextArea
          row={4}
          style={{
            height: 100,
          }}
          onChange={(e) => setInstruction(e.target.value)}
          value={instruction}
          placeholder="Введите инструкцию..."
        />
        <Flex className="mb-1 mt-2">Офис:</Flex>
        <Flex
          style={{
            border: '1px solid black',
          }}
          className="h-max overflow-hidden !border-gray-200"
        >
          <AutoSizer
            className="w-100"
            style={{
              height: '320px',
            }}
          >
            {({ width, height }) => (
              <List
                height={height}
                rowHeight={70}
                width={width}
                rowCount={offices?.length || 0}
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>
        </Flex>
        <Flex className="w-full mt-4" justify="end">
          <Button
            danger
            onClick={() => setUserOnboarding(null)}
          >
            Отмена
          </Button>
          <Button
            type="primary"
            className="ml-2"
            onClick={() => {
              antNotification.success('Онбординг успешно запущен!');
              addNewOnBoarding(userOnboarding?.uuid);
              setUserOnboarding(null);
            }}
          >
            Запустить
          </Button>
        </Flex>
      </Modal>
      )}
    </PageWrapper>
  );
}
