import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { string } from 'prop-types';

import useAPI from '../../../api/useAPI';
import antNotification from '../../../utils/antNotification';

import { defaultConfig } from './constants';
import { enumerateDaysBetweenDates } from '../../../utils/commonUtils';
import { getArrByType, getFirstAndLastDayCalendar } from './utils';
import paramsURL from '../../../utils/ParamsURL';

import { getAllVacations } from '../../../redux/vacations/selectors';
import { getMonthlyStorageInfo, getMonthlyStorageUUID, getVacationStorageUUID } from '../../../redux/storages/selectors';
import { getFetchingMonthlyReport, getMonthlyReportDays, getMonthlyReportUUID } from '../../../redux/monthlyReport/selectors';

import MonthlyReportConstants from '../../../redux/monthlyReport/constants';
import CalendarDayOff from './calendarDayOff/CalendarDayOff';
import useLogsAPI from '../../../api/useLogsAPI';
import useVacationsAPI from '../../../api/useVacationsAPI';
import VacationConstants from '../../../redux/vacations/constants';

function DayOffPage({ actor }) {
  const {
    getOrCreateMonthlyReport,
    updateDayInMonthlyReport,
  } = useAPI();

  const { getListOfVacations } = useVacationsAPI();

  const { createLog } = useLogsAPI();

  const vacationStorageUUID = useSelector(getVacationStorageUUID);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const monthlyStorageInfo = useSelector(getMonthlyStorageInfo);

  const vacations = useSelector(getAllVacations);
  const userDays = useSelector(getMonthlyReportDays);
  const monthlyReportUUID = useSelector(getMonthlyReportUUID);
  const isFetching = useSelector(getFetchingMonthlyReport);

  const [config, setConfig] = useState(defaultConfig);

  const {
    firstDayCalendar,
    lastDayCalendar,
  } = useMemo(() => (
    getFirstAndLastDayCalendar(dayjs(config.startDate))
  ), [config.startDate]);

  const getListDayOffs = async () => {
    const configRequest = {
      actor,
      params: {
        date: dayjs(config.startDate).format('YYYY-MM'),
      },
      params_fields: {
        actorUuid: 'actorUuid',
        date: 'date',
        days: 'days',
      },
    };

    const constants = [
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_REQUEST,
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_SUCCESS,
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_FAILURE,
    ];

    await getOrCreateMonthlyReport(monthlyStorageUUID, configRequest, constants);
  };

  const getListAgreedVacations = () => {
    const configRequest = {
      actor,
      params: {
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          dayjs(firstDayCalendar).format('YYYY-MM-DD'),
          dayjs(lastDayCalendar).format('YYYY-MM-DD'),
        ),
      },
    };
    return getListOfVacations(vacationStorageUUID, configRequest, [
      VacationConstants.GET_VACATIONS_REQUEST,
      VacationConstants.GET_VACATIONS_SUCCESS,
      VacationConstants.GET_VACATIONS_FAILURE,
    ]);
  };

  const onChangeDate = (date) => {
    const DATE = dayjs(date);

    setConfig({
      startDate: DATE.format('YYYY-MM-DD'),
      endDate: DATE.endOf('month').format('YYYY-MM-DD'),
    });

    paramsURL.set({ month: DATE.format('YYYY-MM') });
  };

  const updateDayCallback = async (type, dayDate, options = {}) => {
    try {
      const newDay = {
        type,
        created: dayjs().toString(),
        rangeDates: [dayjs(dayDate).format('YYYY-MM-DD')],
        wasChoiceOfDay: true,
        ...options,
      };

      const oldDay = userDays.find(({ rangeDates }) => rangeDates[0] === dayjs(dayDate).format('YYYY-MM-DD'));

      const newDays = [...userDays].filter(({ rangeDates }) => rangeDates[0] !== dayjs(dayDate).format('YYYY-MM-DD'));

      createLog({
        parent: monthlyReportUUID,
        type: oldDay ? 'UPDATE' : 'CREATE',
        entityType: 'monthlyReportDay',
        values: newDay,
      });
      await updateDayInMonthlyReport(monthlyReportUUID, [...newDays, newDay]);
      await getListDayOffs();
      antNotification.success('День изменен!');
    } catch (e) {
      antNotification.error('Ошибка!');
    }
  };

  const getPrevDayCallback = (currentDate = dayjs()) => {
    const sorteredDays = userDays ?? [];

    if (!sorteredDays?.length) return null;

    return sorteredDays
      ?.sort((a, b) => dayjs(a?.rangeDates?.[0]).unix() - dayjs(b?.rangeDates?.[0]).unix())
      ?.reduce((acc, item) => {
        if (item?.hoursWorked && dayjs(item?.rangeDates?.[0]).isBefore(dayjs(currentDate))) {
          return item;
        }
        return acc;
      }, null) ?? null;
  };

  const data = useMemo(() => getArrByType([
    ...vacations, ...userDays,
  ], enumerateDaysBetweenDates(config.startDate, config.endDate)), [
    JSON.stringify(vacations),
    JSON.stringify(userDays),
    JSON.stringify(config),
    actor,
  ]);

  useEffect(() => {
    paramsURL.set({ month: dayjs(config.startDate).format('YYYY-MM') });

    if (actor
      && vacationStorageUUID
      && monthlyStorageUUID
      && actor === monthlyStorageInfo?.actor) {
      getListDayOffs();
      getListAgreedVacations();
    }
  }, [
    actor,
    vacationStorageUUID,
    firstDayCalendar,
    lastDayCalendar,
    monthlyStorageUUID,
  ]);

  return (
    <CalendarDayOff
      getPrevDayCallback={getPrevDayCallback}
      actor={actor}
      dataSource={data}
      startDate={config.startDate}
      loading={isFetching}
      changeCurrentDayCallback={onChangeDate}
      saveCallback={getListDayOffs}
      updateDayCallback={updateDayCallback}
    />
  );
}

export default DayOffPage;

DayOffPage.propTypes = {
  actor: string,
};

DayOffPage.defaultProps = {
  actor: '',
};
