export const typesOfDays = [];

export const translateTypeDay = {
  dayoff: 'Отгул',
  bench: 'Бенч',
  bench_1_month: 'Бенч 1 месяц',
  bench_over_1_month: 'Бенч больше 1-го месяца',
  bench_over_3_month: 'Бенч больше 3-х месяцев',
  sickday: 'Больничный',
  vacation: 'Отпуск',
  office_work: 'Работа в офисе',
  report_not_sent: 'Отчет не отправлен',
  // localHoliday: 'Местный выходной',
  // projectDayOff: 'Проектный выходной',
  // holidays: 'Каникулы',
  // remote_work: 'Работа удаленно',
  // work_on_day_off: 'Работа в выходной',
};
