import React from 'react';
import { Divider, Flex, Segmented } from 'antd';
import { func, object, string } from 'prop-types';
import { get } from 'lodash';
import { mdiCalendarClock, mdiCheckboxMarkedCircleOutline } from '@mdi/js';
import EntityTitleForCard from '../../commonComponents/entityCardComponents/EntityTitleForCard';
import EntityDescriptionForCard from '../../commonComponents/entityCardComponents/EntityDescriptionForCard';
import EntityDepartmentForArtifact from '../../commonComponents/entityCardComponents/EntityDepartmentForArtifact';
import EntityStatusForCard from '../../commonComponents/entityCardComponents/EntityStatusForCard';

export default function InstructionArtifactView({
  artifactsStorage,
  artifact,
  onUpdateArtifactCallback,
}) {
  const {
    params: {
      department: artifactDepartment,
      status: defaultArtStatus,
    } = {},
  } = artifact || {};

  const changeArtStatus = (statusValue) => {
    onUpdateArtifactCallback?.({ status: statusValue });
  };

  return (
    <Flex
      vertical
      className="w-full"
    >
      <Flex
        className="mx-12 max-w-full overflow-hidden"
        justify="start"
      >
        <EntityTitleForCard
          title={get(artifact, ['params', 'title'], '')}
          onEditCallback={onUpdateArtifactCallback}
        />
      </Flex>
      <Divider className="my-2" />
      <Flex className="mx-12" justify="space-between" align="center">
        <EntityStatusForCard
          className="flex items-center flex-shrink-0"
          title="Статус"
          currentValue={defaultArtStatus}
          classNameForTitle="mr-2 wrapper-title"
          classNameForButtonsBox="p-1"
          withIcons
          withBorder
          onChangeValue={changeArtStatus}
        />
        <EntityDepartmentForArtifact
          department={artifactDepartment}
          artifactsStorage={artifactsStorage}
          onEditCallback={onUpdateArtifactCallback}
        />
      </Flex>
      <Divider className="my-2" />
      <EntityDescriptionForCard
        className="mx-12"
        description={get(artifact, ['params', 'description'], '')}
        onEditCallback={onUpdateArtifactCallback}
      />
    </Flex>
  );
}

InstructionArtifactView.propTypes = {
  storageUUID: string,
  artifact: object,
  onUpdateArtifactCallback: func,
};
