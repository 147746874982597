import React, { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { Route, Switch, Redirect } from 'react-router-dom';

import Login54Origins from './54origins/components/Login54Origins';
import AppLayout from './components/layout/AppLayout';
import ThemeProvider from './providers/ThemeProvider';

import AuthHelper from './utils/authUtils';
import AppConfigProvider from './providers/AppConfigProvider';

import 'dayjs/locale/ru';
import { APP_LABEL, entityServiceURL } from './api/app_config';
import { capitalizeFirstChar } from './utils';

import './styles/baseCards.scss';

dayjs.locale('ru');

function App() {
  const checkAccess = useCallback((component) => (AuthHelper.isAuthorised()
    ? component
    : (
      <Login54Origins
        url={entityServiceURL}
        serviceName={APP_LABEL}
      />
    )), [AuthHelper]);

  useEffect(() => {
    document.title = capitalizeFirstChar(APP_LABEL);
  }, []);

  return (
    <ThemeProvider>
      <AppConfigProvider>
        <Switch>
          <Route
            exact
            path="/login"
            render={() => checkAccess(<Redirect to="/" />)}
          />
          <Route
            path="/"
            render={() => checkAccess(<AppLayout />)}
          />
        </Switch>
      </AppConfigProvider>
    </ThemeProvider>
  );
}

export default App;
