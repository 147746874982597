import React from 'react';
import { useSelector } from 'react-redux';
import { getDepartment, getDepartmentUUID } from '../../../redux/departments/selectors/departmentsSelector';
import useURLParams from '../../../hooks/useURLParams';
import DepartmentReports from '../DepartmentReports';

export default function DepartmentReportsView() {
  const department = useSelector(getDepartment);
  const departmentUUID = useSelector(getDepartmentUUID);
  return (
    <DepartmentReports
      department={department}
      departmentUUID={departmentUUID}
      className="mt-2"
    />
  );
}
