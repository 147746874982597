/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fetching: false,
  isAvailableDepartmentPanel: false,
};

const pageAccessSlice = createSlice({
  name: 'pageAccessSlice',
  initialState,
  reducers: {
    IS_USER_DEPARTMENT_MANAGEMENT_REQUEST(state) {
      state.fetching = true;
    },
    IS_USER_DEPARTMENT_MANAGEMENT_SUCCESS(state, action) {
      state.fetching = false;
      state.isAvailableDepartmentPanel = action?.payload?.total > 0;
    },
    IS_USER_DEPARTMENT_MANAGEMENT_FAILURE(state) {
      state.fetching = false;
    },
  },
});

export const {
  IS_USER_DEPARTMENT_MANAGEMENT_REQUEST,
  IS_USER_DEPARTMENT_MANAGEMENT_SUCCESS,
  IS_USER_DEPARTMENT_MANAGEMENT_FAILURE,
} = pageAccessSlice.actions;

export default pageAccessSlice.reducer;
