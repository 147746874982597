import React, { useEffect, useState } from 'react';
import {
  Table, Tag, Spin, Descriptions, Tabs, Button, Modal, Select,
  Flex,
  Tooltip,
} from 'antd';
import {
  ClockCircleOutlined, CheckCircleFilled, DeleteOutlined, PlusOutlined, CloseOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import './MetaeventsPanelPage.scss';
import { useSelector } from 'react-redux';
import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import useMetaeventStorage from '../../api/useMetaeventStorage';
import useMetaeventsAPI from '../../api/useMetaeventsAPI';
import { getMetaeventStorageUUID } from '../../redux/metaeventStorage/metaeventStorageSelectors';
import MetaeventLogs from '../../components/metaevents/logs/MetaeventLogs';
import MetaeventEvents from '../../components/metaevents/events/MetaeventEvents';
import CreateVacationRequestForm from '../../components/metaevents/forms/CreateVacationRequestForm';

const TRIGGER_NAMES = {
  'create-vacation-request': {
    name: 'Создание отпуска',
  },
  'pre-approve-vacation-request': {
    name: 'Предв. согласование',
  },
  'approve-vacation-request': {
    name: 'Утверждение отпуска',
  },
  'reject-vacation-request': {
    name: 'Отклонение отпуска',
  },
  // 'two-weeks-before-vacation': {
  //   name: 'За 2 недели до отпуска',
  // },
  // 'vacation-start': {
  //   name: 'Начало отпуска',
  // },
  // ... другие типы
};

export default function MetaeventsPanelPage() {
  useMetaeventStorage();
  const { getListOfMetaevents, deleteMetaevent, createMetaevent } = useMetaeventsAPI();
  const metaeventStorageUUID = useSelector(getMetaeventStorageUUID);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [metaevents, setMetaevents] = useState([]);
  const [selectedMetaevent, setSelectedMetaevent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectTriggerVisible, setIsSelectTriggerVisible] = useState(false);
  const [selectedTriggerForCreate, setSelectedTriggerForCreate] = useState(null);

  const columns = [
    {
      title: 'Название',
      dataIndex: ['params', 'title'],
      key: 'title',
      width: '45%',
      ellipsis: false,
      render: (title) => (
        <div className="metaevent-title">{title}</div>
      ),
    },
    {
      title: 'Тип триггера',
      dataIndex: ['params', 'trigger'],
      key: 'trigger',
      width: '15%',
      render: (trigger) => {
        const triggerInfo = TRIGGER_NAMES[trigger] || { name: trigger };
        return <Tag className="trigger-tag">{triggerInfo.name}</Tag>;
      },
    },
    {
      title: 'Создатель',
      dataIndex: 'actor',
      key: 'actor',
      width: '25%',
      render: (actor, record) => (
        <div className="creator-info">
          {record.uinfo ? `${record.uinfo.first_name} ${record.uinfo.last_name}` : actor}
        </div>
      ),
    },
    {
      title: 'Создано',
      dataIndex: 'created',
      key: 'created',
      width: '15%',
      render: (date) => (
        <Tooltip title={new Date(date).toLocaleString('ru-RU', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}
        >
          <span className="date-cell">
            {new Date(date).toLocaleDateString('ru-RU', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })}
          </span>
        </Tooltip>
      ),
    },
  ];

  const initMetaevents = async () => {
    if (metaeventStorageUUID) {
      setIsLoading(true);
      try {
        const res = await getListOfMetaevents(metaeventStorageUUID, {
          entity_type: 'metaevent',
        });
        const events = res?.data || [];
        setMetaevents(events);

        // Если есть uuid в URL, выбираем соответствующее метасобытие
        const selectedUuid = searchParams.get('metaevent');
        if (selectedUuid) {
          const selected = events.find((event) => event.uuid === selectedUuid);
          if (selected) {
            setSelectedMetaevent(selected);
          }
        }
      } catch (error) {
        console.error('Error loading metaevents:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    initMetaevents();
  }, [metaeventStorageUUID]);

  const handleRowSelect = (record) => {
    setSelectedMetaevent(record);
    const params = new URLSearchParams(location.search);
    params.set('metaevent', record.uuid);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const handleDeleteMetaevent = () => {
    Modal.confirm({
      title: 'Удаление метасобытия',
      content: 'Вы уверены, что хотите удалить это метасобытие? Это действие нельзя отменить.',
      okText: 'Удалить',
      cancelText: 'Отмена',
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        try {
          await deleteMetaevent(selectedMetaevent.uuid);
          setSelectedMetaevent(null);
          initMetaevents();
        } catch (error) {
          console.error('Error deleting metaevent:', error);
        }
      },
    });
  };

  const handleCreateMetaevent = async (values) => {
    try {
      await createMetaevent(metaeventStorageUUID, {
        entity_type: 'metaevent',
        params: {
          title: values.title,
          instruction: values.instruction,
          instruction_title: values.instruction_title,
          trigger: values.trigger,
          groups: values.groups,
        },
      });

      setSelectedTriggerForCreate(null);
      initMetaevents();
    } catch (error) {
      console.error('Error creating metaevent:', error);
    }
  };

  const renderMetaeventCard = () => {
    if (!selectedMetaevent) {
      return (
        <div className="empty-state">
          <p>Выберите метасобытие слева для просмотра деталей</p>
        </div>
      );
    }

    return (
      <div className="metaevent-card">
        <div className="metaevent-header">
          <div className="title-section">
            {/* {selectedMetaevent.isActive && (
              <div className="active-indicator">
                <CheckCircleFilled />
                Активно
              </div>
            )} */}
            <Flex>
              <Icon
                path={mdiArrowLeft}
                className="!cursor-pointer"
                size={1}
                onClick={() => {
                  searchParams.delete('metaevent');
                  history.push({
                    pathname: location.pathname,
                    search: searchParams.toString(),
                  });
                  setSelectedMetaevent(null);
                }}
              />
              <h3 className="!ml-3 !mb-1">{selectedMetaevent.params.title}</h3>
            </Flex>
          </div>
          <Button
            type="text"
            danger
            size="small"
            icon={<DeleteOutlined />}
            onClick={handleDeleteMetaevent}
          >
            Удалить
          </Button>
        </div>

        <div className="metaevent-content">
          <div className="content-section">
            <div className="section-title">Тип триггера</div>
            <Tag
              color="blue"
              className="trigger-tag !w-fit"
            >
              {TRIGGER_NAMES[selectedMetaevent.params.trigger]?.name || selectedMetaevent.params.trigger}
            </Tag>
          </div>
          <div className="content-section">
            <div className="section-title">Название инструкции</div>
            {selectedMetaevent.params.instruction_title ? (
              <div className="section-content instruction">
                <pre>{selectedMetaevent.params.instruction_title}</pre>
              </div>
            ) : (
              <div className="section-content instruction">
                <pre>Название инструкции не указано</pre>
              </div>
            )}
          </div>
          <div className="content-section">
            <div className="section-title">Инструкция</div>
            <div className="section-content instruction">
              <pre>{selectedMetaevent.params.instruction}</pre>
            </div>
          </div>
          <div className="content-section">
            <div className="section-title">Группы</div>
            <div className="section-content instruction">
              <pre>{selectedMetaevent.params.groups.join(', ')}</pre>
            </div>
          </div>

          <div className="content-section">
            <div className="section-title">Информация</div>
            <div className="section-content info-grid">
              {/* <div className="info-item">
                <div className="info-label">UUID</div>
                <div className="info-value">{selectedMetaevent.uuid}</div>
              </div> */}
              <div className="info-item">
                <div className="info-label">Создатель</div>
                <div className="info-value">
                  {selectedMetaevent.uinfo
                    ? `${selectedMetaevent.uinfo.first_name} ${selectedMetaevent.uinfo.last_name}`
                    : selectedMetaevent.actor}
                </div>
              </div>
              <div className="info-item">
                <div className="info-label">Создано</div>
                <div className="info-value">
                  {new Date(selectedMetaevent.created).toLocaleDateString('ru-RU', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </div>
              </div>
              {selectedMetaevent.modified !== selectedMetaevent.created && (
                <div className="info-item">
                  <div className="info-label">Обновлено</div>
                  <div className="info-value">
                    {new Date(selectedMetaevent.modified).toLocaleDateString('ru-RU', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          {selectedMetaevent && (
            <div className="content-section">
              <Tabs
                items={[
                  {
                    key: 'events',
                    label: 'События',
                    children: <MetaeventEvents metaeventUuid={selectedMetaevent.uuid} />,
                  },
                  {
                    key: 'logs',
                    label: 'Логи',
                    children: <MetaeventLogs metaeventUuid={selectedMetaevent.uuid} />,
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="metaevents-panel mt-2">
      <div className="metaevents-list">
        <Flex align="center" className="table-header">
          <div className="header-left">
            <h2>Метасобытия</h2>
            <div className="table-stats">
              Всего:
              {' '}
              {metaevents.length}
            </div>
          </div>
          <Button
            type="primary"
            size="small"
            className="create-button"
            icon={<PlusOutlined />}
            onClick={() => {
              searchParams.delete('metaevent');
              history.push({
                pathname: location.pathname,
                search: searchParams.toString(),
              });
              setSelectedMetaevent(null);
              setSelectedTriggerForCreate({});
            }}
          >
            Создать
          </Button>
        </Flex>
        <Spin spinning={isLoading}>
          <Table
            columns={columns}
            dataSource={metaevents}
            rowKey="uuid"
            pagination={false}
            onRow={(record) => ({
              onClick: () => handleRowSelect(record),
              className: selectedMetaevent?.uuid === record.uuid ? 'selected-row' : '',
            })}
            size="middle"
          />
        </Spin>
      </div>

      {selectedMetaevent && (
        <div className="metaevent-details">
          {renderMetaeventCard()}
        </div>
      )}

      {!selectedMetaevent && selectedTriggerForCreate && (
        <div className="metaevent-details">
          <div className="metaevent-card">
            <div className="metaevent-header">
              <div className="title-section">
                <Icon
                  onClick={() => setSelectedTriggerForCreate(null)}
                  path={mdiArrowLeft}
                  className="!cursor-pointer"
                  size={1}
                />
                <h3 className="!ml-3 !mb-1">Новое метасобытие</h3>
              </div>
            </div>
            <div className="metaevent-content">
              <div className="content-section">
                <div className="section-title">Тип триггера</div>
                <Select
                  placeholder="Выберите тип триггера"
                  style={{ width: '100%' }}
                  onChange={(value) => setSelectedTriggerForCreate({
                    ...selectedTriggerForCreate,
                    value,
                  })}
                >
                  {Object.entries(TRIGGER_NAMES).map(([value, info]) => (
                    <Select.Option key={value} value={value}>
                      {info.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              {selectedTriggerForCreate?.value && (
                <div className="content-section">
                  <div className="section-header">
                    <div className="section-title">Форма создания</div>
                  </div>
                  <CreateVacationRequestForm
                    trigger={{
                      value: selectedTriggerForCreate.value,
                      ...TRIGGER_NAMES[selectedTriggerForCreate.value],
                    }}
                    onSubmit={handleCreateMetaevent}
                    onCancel={() => setSelectedTriggerForCreate(null)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
