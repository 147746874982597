import React from 'react';

import { convertTimeFormatToHours } from '../../departments/commonComponents/utils';

export const convertTimeFormat = (input) => {
  if (!input) return '';
  const match = input.match(/(\d+)h(\d+)m/);
  if (!match) return '';

  const [, hours, minutes] = match;
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

export const renderWorkTime = (dayData) => {
  if (!dayData?.startWork && !dayData?.finishWork && !dayData?.hoursWorked) return null;

  return (
    <>
      {' : '}
      {convertTimeFormat(dayData.startWork)}
      -
      {convertTimeFormat(dayData.finishWork)}
      {' ('}
      {convertTimeFormatToHours(dayData.hoursWorked)}
      {') '}
    </>
  );
};
