import React, { useEffect } from 'react';
import { Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ArtifactsList from '../../artifacts/ArtifactsList';
import { getDepartment, getDepartmentUUID } from '../../../redux/departments/selectors/departmentsSelector';
import useURLParams from '../../../hooks/useURLParams';
import { ENABLE_RESET_PROPERTY } from '../../../redux/reset/resetSlice';
import ArtifactCard from '../../artifacts/ArtifactCard';
import useArtifactsAPI from '../../../api/useArtifactsAPI';
import {
  GET_SINGLE_ARTIFACT_REQUEST,
  GET_SINGLE_ARTIFACT_SUCCESS,
  GET_SINGLE_ARTIFACT_FAILURE,
  RESET_SINGLE_ARTIFACT,
} from '../../../redux/departments/slices/artifactsSlice';
import { getArtifact, getArtifactUUID } from '../../../redux/departments/selectors/artifactsSelector';

export default function DepartmentContractors() {
  const dispatch = useDispatch();

  const {
    readListOfArtifacts,
  } = useArtifactsAPI();

  const department = useSelector(getDepartment);
  const departmentUUID = useSelector(getDepartmentUUID);

  const artifact = useSelector(getArtifact);
  const artifactUUID = useSelector(getArtifactUUID);

  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams();
  const { d: activeDepartment, artifact: activeArtifact } = getURLParams();

  useEffect(() => {
    if (activeArtifact) {
      readListOfArtifacts(
        activeArtifact,
        { },
        [
          GET_SINGLE_ARTIFACT_REQUEST,
          GET_SINGLE_ARTIFACT_SUCCESS,
          GET_SINGLE_ARTIFACT_FAILURE,
        ],
      );
    } else {
      dispatch(RESET_SINGLE_ARTIFACT);
    }
  }, [activeArtifact]);

  return (
    <Flex className="w-full" justify="space-between">
      <ArtifactsList
        cardClassName={activeArtifact ? 'w-1/2' : 'w-full'}
        name="artifact_list"
        // hideAddEmployee
        hideAddArtifact
        parent={activeDepartment}
        title="Подрядчики"
        showUserCreateButton
        department={department}
        defaultConfig={{ entity_type: 'user' }}
        departmentUUID={activeDepartment}
        onCreateArtifactCallback={(uuid) => {
          setSearchParams({ artifact: uuid });
          dispatch(ENABLE_RESET_PROPERTY(['artifact_list', 'employees_list_for_department']));
        }}
      />

      {activeArtifact && (
      <ArtifactCard
        hideDeleteButton
        hideCopyURLButton
        // hideBackButton
        className="w-1/2 ml-6"
        artifact={artifact}
        artifactUUID={artifactUUID}
        onBackArtCallback={() => {
          clearSearchParams(['artifact']);
        }}
        onUpdateArtCallback={() => {
          dispatch(ENABLE_RESET_PROPERTY(['artifact_list', 'artifact_list_without_department']));
        }}
        onDeleteArtCallback={() => {
          clearSearchParams(['artifact']);
          dispatch(ENABLE_RESET_PROPERTY(['artifact_list', 'artifact_list_without_department']));
        }}
      />
      )}

    </Flex>
  );
}
