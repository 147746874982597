import React, { useState } from 'react';

import { Flex } from 'antd';

import PageWrapper from '../../components/PageWrapper';
import ListOfLogs from '../../components/logs/ListOfLogs';
import CardOfEntityLogs from '../../components/logs/CardOfEntityLogs';

export default function EntitiesAndLogsPage() {
  const [currentEntity, setCurrentEntity] = useState(null);

  return (
    <PageWrapper
      title="Логи"
      className="w-full"
    >
      <Flex className="w-full">
        <ListOfLogs
          onSelectLog={setCurrentEntity}
          cardClassName={currentEntity ? 'w-2/3' : 'w-full'}
        />
        {currentEntity && (
        <CardOfEntityLogs
          currentLog={currentEntity?.log}
          onSelectLog={setCurrentEntity}
          currentEntity={currentEntity?.entity}
        />
        )}
      </Flex>
    </PageWrapper>
  );
}
