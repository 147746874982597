import { useDispatch } from 'react-redux';
import { get, omit } from 'lodash';
import useLogsAPI from './useLogsAPI';
import {
  entityCreate, entityDelete, entityRead, entityUpdate, getListAndReadEntities,
} from './actions/entity';
import { SERVICE_PARTITION_NAME } from './app_config';
import antNotification from '../utils/antNotification';

export default function useEventsAPI() {
  const dispatch = useDispatch();

  const {
    createLog,
  } = useLogsAPI();

  const readListOfEvents = (entityUUIDs, data = {}, constants) => {
    try {
      return dispatch(
        entityRead({
          data: {
            entity_type: 'event',
            entity_uuid: entityUUIDs,
            ...data,
          },
          constants: constants || [
            'READ_LIST_OF_EVENTS_REQUEST',
            'READ_LIST_OF_EVENTS_SUCCESS',
            'READ_LIST_OF_EVENTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список событий!');
      return null;
    }
  };

  const getListOfEvents = (parent, data = {}, constants) => {
    try {
      return dispatch(
        getListAndReadEntities({
          data: {
            entity_type: 'event',
            parent,
            depth: 0,
            ...data,
          },
          constants: constants || [
            'GET_LIST_OF_EVENTS_REQUEST',
            'GET_LIST_OF_EVENTS_SUCCESS',
            'GET_LIST_OF_EVENTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список событий!');
      return null;
    }
  };

  const createEvent = async (parent, data, constants) => {
    try {
      const newEventConfig = {
        parent,
        entity_type: 'event',
        ...omit(data, ['files']),
      };

      const newFormData = new FormData();

      newFormData.append('data', JSON.stringify(newEventConfig));

      if (data?.files?.length !== 0) {
        for (let i = 0; i < data?.files?.length; i += 1) {
          newFormData.append('file', data?.files?.[i]);
        }
      }

      const newEvent = await dispatch(
        entityCreate({
          data: newFormData,
          constants: constants || [
            'CREATE_EVENT_REQUEST',
            'CREATE_EVENT_SUCCESS',
            'CREATE_EVENT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(newEvent, '[0].uuid', '')) {
        createLog({
          parent: get(newEvent, '[0].uuid', ''),
          type: 'CREATE',
          entityType: 'event',
          values: get(newEvent, '[0].params', ''),
          files: data?.files || [],
        });
      }

      return newEvent;
    } catch (e) {
      antNotification.error('Не удалось создать событие!');
      return null;
    }
  };

  const updateEvent = async (entityUUID, data, constants) => {
    try {
      const updateEventConfig = {
        entity_uuid: entityUUID,
        entity_type: 'event',
        ...omit(data, ['files']),
      };

      const newFormData = new FormData();

      newFormData.append('data', JSON.stringify(updateEventConfig));

      if (data?.files?.length !== 0) {
        for (let i = 0; i < data?.files?.length; i += 1) {
          newFormData.append('file', data?.files?.[i]);
        }
      }

      const updatedEvent = await dispatch(
        entityUpdate({
          data: newFormData,
          constants: constants || [
            'UPDATE_EVENT_REQUEST',
            'UPDATE_EVENT_SUCCESS',
            'UPDATE_EVENT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
      if (get(updatedEvent, '[0].uuid', '')) {
        createLog({
          parent: get(updatedEvent, '[0].uuid', ''),
          type: 'UPDATE',
          entityType: 'event',
          values: get(data, 'params', {}),
          files: data?.files || [],
        });
      }

      return updatedEvent;
    } catch (e) {
      antNotification.error('Не удалось изменить событие!');
      return null;
    }
  };

  const deleteEvent = (entityUUID, data, constants) => {
    try {
      return dispatch(
        entityDelete({
          data: {
            entity_uuid: entityUUID,
            entity_type: 'event',
            ...data,
          },
          constants: constants || [
            'DELETE_EVENT_REQUEST',
            'DELETE_EVENT_SUCCESS',
            'DELETE_EVENT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось удалить событие!');
      return null;
    }
  };

  return {
    createEvent,
    readListOfEvents,
    getListOfEvents,
    updateEvent,
    deleteEvent,
  };
}
