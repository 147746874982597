// External libraries
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { concat, get } from 'lodash';

// API hooks
import useMonthlyReportsAPI from '../api/useMonthlyReportsAPI';
import useVacationsAPI from '../api/useVacationsAPI';

// Selectors
import { getProfileUUID } from '../redux/profile/selectors';
import {
  getMonthlyStorageInfo,
  getMonthlyStorageUUID,
  getVacationStorageUUID,
} from '../redux/storages/selectors';

// Utils
import { enumerateDaysBetweenDates } from '../utils/commonUtils';
import { computeStatisticsForReport } from '../utils/reportUtils';

/**
 * Хук для автоматической отправки отчета за предыдущий месяц.
 * Отслеживает изменения в хранилищах и обновляет статистику отчета,
 * учитывая одобренные отпуска.
 *
 * @returns {void}
 *
 * @requires monthlyStorageUUID - UUID хранилища месячных отчетов
 * @requires vacationStorageUUID - UUID хранилища отпусков
 * @requires actorUUID - UUID текущего пользователя
 * @requires monthlyStorageInfo - Информация о хранилище месячных отчетов
 *
 * @example
 * function MyComponent() {
 *   useWatcherPreviousMonthReport();
 *   return <div>...</div>;
 * }
 */

export default function useWatcherPreviousMonthReport() {
  const {
    updateMonthlyReport,
    getOrCreateMonthlyReport,
  } = useMonthlyReportsAPI();

  const { getListOfVacations } = useVacationsAPI();

  const vacationStorageUUID = useSelector(getVacationStorageUUID);
  const monthlyStorageInfo = useSelector(getMonthlyStorageInfo);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const actorUUID = useSelector(getProfileUUID);

  const lastMonth = dayjs().subtract(1, 'month').format('YYYY-MM');

  const startMonth = dayjs(lastMonth).startOf('month').format('YYYY-MM-DD');
  const endMonth = dayjs(lastMonth).endOf('month').format('YYYY-MM-DD');

  const getVacations = async () => {
    const res = await getListOfVacations(vacationStorageUUID, {
      actor: actorUUID,
      params: {
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          dayjs(startMonth).format('YYYY-MM-DD'),
          dayjs(endMonth).format('YYYY-MM-DD'),
        ),
      },
    }, [
      'GET_VACATIONS_FOR_AUTO_REPORT_REQUEST',
      'GET_VACATIONS_FOR_AUTO_REPORT_SUCCESS',
      'GET_VACATIONS_FOR_AUTO_REPORT_FAILURE',
    ]);
    return get(res, 'data', []);
  };

  const initFunc = async () => {
    const report = await getOrCreateMonthlyReport(monthlyStorageUUID, {
      actor: actorUUID,
      params: {
        date: lastMonth,
      },
    }, [
      'GET_MONTHLY_REPORT_FOR_AUTO_REPORT_REQUEST',
      'GET_MONTHLY_REPORT_FOR_AUTO_REPORT_SUCCESS',
      'GET_MONTHLY_REPORT_FOR_AUTO_REPORT_FAILURE',
    ]);

    const reportStatus = get(report, ['params', 'status'], '');
    const reportUUID = get(report, 'uuid', '');

    if (!report || !reportUUID || reportStatus === 'auto' || reportStatus === 'submitted') {
      return;
    }

    const vacations = await getVacations();

    const records = concat(get(report, 'params.days', []), vacations);

    const data = computeStatisticsForReport(records, startMonth, endMonth);

    await updateMonthlyReport(report?.uuid, {
      entity_type: 'monthlyReport',
      actor: actorUUID,
      params: {
        status: 'auto',
        statistics: {
          ...data,
          created: dayjs().toString(),
        },
      },
      logs: [],
    }, [
      'AUTO_UPDATE_MONTHLY_REPORT_REQUEST',
      'AUTO_UPDATE_MONTHLY_REPORT_SUCCESS',
      'AUTO_UPDATE_MONTHLY_REPORT_FAILURE',
    ]);
  };

  useEffect(() => {
    if (actorUUID
          && monthlyStorageUUID
          && monthlyStorageInfo?.actor === actorUUID
          && vacationStorageUUID) {
      initFunc();
    }
  }, [
    monthlyStorageInfo?.actor,
    actorUUID,
    monthlyStorageUUID,
    vacationStorageUUID,
  ]);
}
