import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { string } from 'prop-types';
import EmployeeRequestForm from '../../commonComponents/entityCardComponents/EmployeeRequestForm';

export default function CreateEmployeeRequestButton({ departmentUUID }) {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <Button
        size="small"
        onClick={openModal}
        className="ml-2 mt-1 !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white"
      >
        Создать заявку
      </Button>
      <Modal
        open={isOpen}
        title="Создать заявку"
        onCancel={closeModal}
        footer={null}
        centered
        width={730}
        destroyOnClose
      >
        <EmployeeRequestForm
          departmentUUID={departmentUUID}
          closeModal={closeModal}
        />
      </Modal>
    </>
  );
}

CreateEmployeeRequestButton.propTypes = {
  departmentUUID: string.isRequired,
};
