import React, { useEffect } from 'react';

import { Spin } from 'antd';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { SERVICE_PARTITION_NAME } from '../api/app_config';
import antNotification from '../utils/antNotification';
import AuthHelper from '../utils/authUtils';

import useAPI from '../api/useAPI';

import {
  getConfigFetching,
  getEntityDomain,
  getHeaderPartition,
} from '../redux/config/selectors';
import {
  getAccess,
  getProfileFetching,
} from '../redux/profile/selectors';
import { getPublicInterfacesFetching } from '../redux/publicInterfaces/selectors';
import useActorsAPI from '../api/useActorsApi';

function AppConfigProvider({ children }) {
  const {
    requestGetPublicInterfaces,
    requestGetPartitions,
  } = useAPI();

  const {
    requestGetProfile,
  } = useActorsAPI();

  const history = useHistory();

  const access = useSelector(getAccess);
  const entityDomain = useSelector(getEntityDomain);
  const headerPartitions = useSelector(getHeaderPartition);

  const isProfileFetching = useSelector(getProfileFetching);
  const isConfigFetching = useSelector(getConfigFetching);
  const isPublicInterfacesFetching = useSelector(getPublicInterfacesFetching);

  const { pathname } = useLocation();

  if (pathname.includes('?identifier') || pathname.includes('&identifier')) {
    localStorage.setItem('identifier', pathname.split('identifier=')[1]);
  }

  useEffect(async () => {
    if (entityDomain) {
      const partitions = await requestGetPartitions();

      const rootPartition = partitions.filter(
        (r) => r?.partition_name === 'delivery',
      );

      if (!rootPartition.length) {
        antNotification.error(
          `Root ${SERVICE_PARTITION_NAME} partition not found. Contact the administrator, please.`,
        );
      }
    }
  }, [entityDomain]);

  useEffect(() => {
    if (headerPartitions) {
      requestGetProfile().then((response) => AuthHelper.authorizedAccess(_.get(response, 'access', false)));
    }
  }, [headerPartitions]);

  useEffect(() => {
    AuthHelper.removeSession();

    if (AuthHelper.isAuthorised()) {
      requestGetPublicInterfaces();
    }
  }, [access]);

  useEffect(() => {
    if (AuthHelper.isAuthorised()) {
      if (pathname === '/') {
        history.push('/deliverydashboard');
      }
    }
  }, [pathname]);

  const everythingLoaded = (!isProfileFetching && !isConfigFetching && !isPublicInterfacesFetching) || false;

  return everythingLoaded ? children : (
    <Spin
      size="large"
      spinning
      className="h-screen flex items-center justify-center"
    />
  );
}

export default compose(withRouter)(AppConfigProvider);
