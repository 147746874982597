import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button, Space,
} from 'antd';

const { TextArea } = Input;

export default function CMERejectVacationForm({ form, onSubmit, onCancel }) {
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      className="meta-event-form"
    >
      <Form.Item
        name="title"
        label="Название метасобытия"
        rules={[{ required: true, message: 'Введите название' }]}
      >
        <Input placeholder="Например: Уведомление сотрудника об отказе" />
      </Form.Item>

      <Form.Item
        name="instruction"
        label="Инструкция для пользователя"
        rules={[{ required: true, message: 'Добавьте инструкцию' }]}
      >
        <TextArea
          rows={4}
          placeholder="Опишите причину отказа и дальнейшие действия"
        />
      </Form.Item>

      <Form.Item className="form-actions">
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button onClick={onCancel}>
            Отмена
          </Button>
          <Button type="primary" htmlType="submit">
            Создать метасобытие
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

CMERejectVacationForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
