import { Modal, Switch } from 'antd';
import { capitalize } from 'lodash';
import { func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAPI from '../../../../api/useAPI';
import antNotification from '../../../../utils/antNotification';
import { getDeliveryPartition } from '../../../../redux/config/selectors';

export default function AccessSwith({
  actorPerm, fullName, actor, updateCallback,
}) {
  const {
    createEntityPermissions,
  } = useAPI();
  const isAdminOrRoot = actorPerm?.is_root || actorPerm?.is_admin;

  const deliveryPartition = useSelector(getDeliveryPartition);

  const [isChecked, setIsChecked] = useState(isAdminOrRoot);
  const [modal, contextHolder] = Modal.useModal();

  const requestDelete = async () => {
    const data = {
      entity_uuid: deliveryPartition,
      data: [
        {
          actor,
          set: false,
        },
      ],
    };
    await createEntityPermissions({ data });
    antNotification.success('Доступ убран.');
    setIsChecked(false);
    updateCallback();
  };

  const requestAdd = async () => {
    const data = {
      entity_uuid: deliveryPartition,
      data: [
        {
          actor,
          set: true,
        },
      ],
    };
    await createEntityPermissions({ data });
    antNotification.success('Доступ выдан.');
    setIsChecked(true);
    updateCallback();
  };

  const deletePerm = () => {
    modal.warning({
      title: `${capitalize(fullName)}`,
      content: 'Вы уверены, что хотите убрать доступ к статистике этому пользователю',
      width: 'auto',
      centered: true,
      keyboard: true,
      maskClosable: true,
      onOk: () => requestDelete(),
    });
  };

  const addPerm = () => {
    modal.warning({
      title: `${capitalize(fullName)}`,
      content: 'Вы уверены, что хотите дать доступ к статистике этому пользователю',
      width: 'auto',
      centered: true,
      keyboard: true,
      maskClosable: true,
      onOk: () => requestAdd(),
    });
  };
  const onChange = (bool) => {
    if (bool) {
      addPerm();
    } else {
      deletePerm();
    }
  };
  useEffect(() => {
    if (actorPerm) {
      setIsChecked(actorPerm?.is_root || actorPerm?.is_admin || actorPerm?.set);
    }
  }, [actorPerm]);
  return (
    <>
      <Switch
        checkedChildren="Да"
        unCheckedChildren="Нет"
        checked={isChecked}
        disabled={isAdminOrRoot}
        onChange={onChange}
      />
      {contextHolder}
    </>
  );
}

AccessSwith.propTypes = {
  actorPerm: object,
  fullName: string,
  actor: string,
  updateCallback: func,
};
