import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DepartmentCard from '../DepartmentCard';
import { getDepartment, getDepartmentUUID } from '../../../redux/departments/selectors/departmentsSelector';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import useURLParams from '../../../hooks/useURLParams';
import {
  GET_SINGLE_DEPARTMENT_FAILURE,
  GET_SINGLE_DEPARTMENT_REQUEST,
  GET_SINGLE_DEPARTMENT_SUCCESS,
  SET_SINGLE_DEPARTMENT,
} from '../../../redux/departments/slices/departmentsSlice';
import { ENABLE_RESET_PROPERTY } from '../../../redux/reset/resetSlice';

export default function DepartmentOverview() {
  const dispatch = useDispatch();

  const { readListOfDepartments } = useDepartmentsAPI();

  const { getURLParams, clearSearchParams } = useURLParams();
  const { d: activeDepartment } = getURLParams();

  const department = useSelector(getDepartment);
  const departmentUUID = useSelector(getDepartmentUUID);

  const onEditDepCallback = (data) => {
    if (data) {
      dispatch({
        type: SET_SINGLE_DEPARTMENT.type,
        payload: data,
      });
    } else if (activeDepartment) {
      readListOfDepartments(activeDepartment, {}, [
        GET_SINGLE_DEPARTMENT_REQUEST,
        GET_SINGLE_DEPARTMENT_SUCCESS,
        GET_SINGLE_DEPARTMENT_FAILURE,
      ]);
    }
  };

  return (
    <DepartmentCard
      department={department}
      departmentUUID={departmentUUID}
      className="!mt-0"
      onEditDepartmentCallback={onEditDepCallback}
      onBackDepartmentCallback={() => {
        clearSearchParams(['department']);
      }}
      onDeleteDepartmentCallback={() => {
        clearSearchParams(['department']);
        dispatch(ENABLE_RESET_PROPERTY('department_list'));
      }}
    />
  );
}
