import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import dayjs from 'dayjs';
import useLogsAPI from './useLogsAPI';
import { entityUpdate, getOrCreateSingle } from './actions/entity';
import { SERVICE_PARTITION_NAME } from './app_config';
import antNotification from '../utils/antNotification';
import { getMD5HashFromString } from '../utils';
import { getProfileUUID } from '../redux/profile/selectors';

export default function useMonthlyReportsAPI() {
  const dispatch = useDispatch();

  const actorUUID = useSelector(getProfileUUID);

  const {
    createLog,
  } = useLogsAPI();

  const getOrCreateMonthlyReport = async (parentUUID, data, constants, options) => {
    try {
      dispatch(
        getOrCreateSingle({
          data: {
            parent: parentUUID,
            entity_type: 'monthlyReport',
            with_files: false,
            key: getMD5HashFromString(`monthlyReport${parentUUID}${data?.params?.date}${data?.actor ?? actorUUID}`),
            ...options,
          },
          constants: constants || [
            'GET_MONTHLY_REPORT_REQUEST',
            'GET_MONTHLY_REPORT_SUCCESS',
            'GET_MONTHLY_REPORT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
          doNotCheckError: true,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить отчет!');
      return null;
    }
  };

  const updateMonthlyReport = async (entityUUID, data, constants, options) => {
    try {
      const { createLog: createLogOptions = true } = options;

      const updatedReport = await dispatch(
        entityUpdate({
          data: {
            entity_uuid: entityUUID,
            ...data,
          },
          constants: constants || [
            'UPDATE_MONTHLY_REPORT_REQUEST',
            'UPDATE_MONTHLY_REPORT_SUCCESS',
            'UPDATE_MONTHLY_REPORT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(updatedReport, '[0].uuid', '') && createLogOptions) {
        createLog({
          parent: get(updatedReport, '[0].uuid', ''),
          type: 'UPDATE',
          entityType: 'monthlyReport',
          values: get(data, 'params', {}),
        });
      }

      return updatedReport;
    } catch (e) {
      antNotification.error('Не удалось обновить отчет!');
      return null;
    }
  };

  const updateDayCallback = async (entityUUID, {
    type,
    date,
    prevDays,
    data,
  }, constants, options = {}) => {
    try {
      const dayDate = dayjs(date).format('YYYY-MM-DD');
      const newDay = {
        type,
        created: dayjs().toString(),
        rangeDates: [dayDate],
        wasChoiceOfDay: true,
        ...data,
      };

      const oldDay = prevDays.find(({ rangeDates }) => rangeDates[0] === dayDate);

      const newDays = [...prevDays].filter(({ rangeDates }) => rangeDates[0] !== dayDate);

      createLog({
        parent: entityUUID,
        type: oldDay ? 'UPDATE' : 'CREATE',
        entityType: 'monthlyReportDay',
        values: newDay,
      });
      await updateMonthlyReport(entityUUID, {
        params: {
          days: [...newDays, newDay],
        },
      }, [
        'UPDATE_DAY_IN_MONTHLY_REPORT_REQUEST',
        'UPDATE_DAY_IN_MONTHLY_REPORT_SUCCESS',
        'UPDATE_DAY_IN_MONTHLY_REPORT_FAILURE',
      ], { createLog: false });
    } catch (e) {
      antNotification.error('Не удалось обновить день!');
    }
  };

  return {
    updateDayCallback,
    updateMonthlyReport,
    getOrCreateMonthlyReport,
  };
}
