import React, { useState } from 'react';
import {
  Modal,
  Input,
  Divider,
  Col,
  Row,
  Button,
  Flex,
} from 'antd';
import dayjs from 'dayjs';
import {
  bool, element, func, object, string,
} from 'prop-types';
import { get } from 'lodash';
import BaseReportWorkTimeManagmentForm from '../layout/startWorkRecord/BaseReportWorkTimeManagmentForm';
import useMonthlyReportsAPI from '../../api/useMonthlyReportsAPI';

const { TextArea } = Input;

const types = [
  {
    label: 'Работа в офисе',
    value: 'office_work',
    textColor: '!text-blue-600',
    bgColor: '!bg-blue-100',
  },
  {
    label: 'Отгул',
    value: 'dayoff',
    textColor: '!text-orange-600',
    bgColor: '!bg-orange-100',
  },
  {
    label: 'Больничный',
    value: 'sickday',
    textColor: '!text-red-600',
    bgColor: '!bg-red-100',
  },
];

export default function UpdateDailyReportForm({
  isOpen,
  onCancel,
  onSave,
  children,
  date,
  report,
}) {
  const { updateDayCallback } = useMonthlyReportsAPI();

  const [formState, setFormState] = useState({
    kindOfDay: 'office_work',
    comment: '',
    timeState: {
      hoursWorked: '08h00m',
      startWork: dayjs().format('HH[h]mm[m]'),
      finishWork: dayjs().add(8, 'hours').add(30, 'minutes').format('HH[h]mm[m]'),
      lunchBreak: '00h30m',
    },
  });

  const onFinish = () => {
    updateDayCallback(get(report, 'uuid'), {
      type: formState.kindOfDay,
      date,
      prevDays: get(report, 'params.days', []),
      data: {
        ...(formState.kindOfDay === 'office_work' ? formState.timeState : {}),
        comment: formState.comment,
        wasChoiceOfDay: true,
      },
    });
    onSave();
    onCancel();
  };

  return (
    <>
      {children}
      <Modal
        title={`Изменение дневного отчета ${dayjs(date).format('DD MMMM YYYY')}`}
        open={isOpen}
        onCancel={onCancel}
        width={700}
        centered
        footer={[
          <Button key="cancel" onClick={onCancel}>
            Отмена
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onFinish}
            className="bg-blue-500"
          >
            Обновить
          </Button>,
        ]}
        destroyOnClose
      >
        <Flex vertical className="py-2 w-full">
          <Flex gap={8} justify="start">
            {types.map((type) => (
              <Button
                key={type.value}
                className={`
                  h-9 px-6
                  ${type.textColor}
                  ${formState.kindOfDay === type.value
                  ? type.bgColor
                  : `!hover:${type.bgColor}`
                  }
                  !border-none hover:!border-none focus:!border-none
                  hover:${type.textColor}
                `}
                onClick={() => setFormState((prev) => ({ ...prev, kindOfDay: type.value }))}
              >
                {type.label}
              </Button>
            ))}
          </Flex>

          {formState.kindOfDay === 'office_work' && (
            <Row className="mt-0">
              <Col span={24}>
                <Divider orientation="left" className="-mb-2">
                  <span className="text-gray-500 text-sm">Настройка времени:</span>
                </Divider>
                <BaseReportWorkTimeManagmentForm
                  formState={formState}
                  setFormState={setFormState}
                  showLunchBreak
                />
              </Col>
            </Row>
          )}

          <Row className="mt-0">
            <Col span={24}>
              <Divider orientation="left" className="my-1">
                <span className="text-gray-500 text-sm">Комментарий:</span>
              </Divider>
              <TextArea
                value={formState.comment}
                onChange={(e) => setFormState((prev) => ({ ...prev, comment: e.target.value }))}
                placeholder="Введите комментарий..."
                rows={4}
              />
            </Col>
          </Row>
        </Flex>
      </Modal>
    </>
  );
}

UpdateDailyReportForm.propTypes = {
  isOpen: bool,
  onCancel: func,
  children: element,
  date: string,
  report: object,
  onSave: func,
};
