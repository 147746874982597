import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { get, omit } from 'lodash';

import { getDeliveryPartition } from '../../config/selectors';
import { getDepartments, getIsFetchingDepartments, getTotalDepartments } from '../selectors/departmentsSelector';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import { GET_DEPARTMENTS_FAILURE, GET_DEPARTMENTS_REQUEST, GET_DEPARTMENTS_SUCCESS } from '../slices/departmentsSlice';

function useDepartments({
  defaultConfig = {},
  isReduxMode = true,
  constants = [
    'GET_DEPARTMENTS_FOR_HOOK_REQUEST',
    'GET_DEPARTMENTS_FOR_HOOK_REQUEST',
    'GET_DEPARTMENTS_FOR_HOOK_REQUEST',
  ],
} = {}) {
  const partitionUuid = useSelector(getDeliveryPartition);

  const reduxDepartments = useSelector(getDepartments);
  const reduxTotal = useSelector(getTotalDepartments);
  const reduxIsFetching = useSelector(getIsFetchingDepartments);

  const [departments, setDepartments] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(true);

  const {
    getListOfDepartments,
  } = useDepartmentsAPI();

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    offset: 0,
    limit: 10,
    ...defaultConfig,
  });

  const initFunc = async () => {
    if (!isReduxMode) {
      setIsFetching(false);
    }

    const newConfig = {
      ...config,
    };

    const res = await getListOfDepartments(partitionUuid, newConfig, isReduxMode ? [
      GET_DEPARTMENTS_REQUEST,
      GET_DEPARTMENTS_SUCCESS,
      GET_DEPARTMENTS_FAILURE,
    ] : constants);

    if (res && !isReduxMode) {
      setDepartments(res?.data || []);
      setTotal(res?.total);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (partitionUuid) {
      initFunc();
    }
  }, [partitionUuid, isReduxMode, JSON.stringify(config)]);

  useEffect(() => {
    setConfig(defaultConfig);
  }, [JSON.stringify(defaultConfig)]);

  return {
    departments: isReduxMode ? reduxDepartments : departments,
    onReloadDepartments: initFunc,
    isFetching: isReduxMode ? reduxIsFetching : isFetching,
    paginationOptions: {
      total: isReduxMode ? reduxTotal : total,
      pageLimit: get(config, 'limit', 10),
      changePageLimit: (newLimit) => {
        setCurrentPage(1);
        setConfig((prev) => ({ ...prev, offset: 0, limit: newLimit }));
      },
      newCurrentPage: currentPage,
      onChangePageCallback: (newPage) => {
        setCurrentPage(newPage);
        setConfig((prev) => ({ ...prev, offset: (newPage - 1) * prev.limit }));
      },
      getPaginationOptions: (newPart) => {
        const valueSearch = get(newPart, 'search', '');

        if (valueSearch) {
          setConfig((prev) => ({
            ...prev,
            ...omit(newPart, ['search']),
            searchData: {
              value: valueSearch,
              ignore_case: true,
              fields: { params: ['title', 'id'] },
            },
          }));
        } else {
          setConfig((prev) => ({ ...omit(prev, 'searchData'), ...newPart }));
        }
      },
    },
  };
}

export default useDepartments;
