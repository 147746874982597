import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Flex, Tabs } from 'antd';

import { isRootOrAdmin } from '../../../redux/profile/selectors';

import PageWrapper from '../../../components/PageWrapper';
import MyDepartmentRoot from './MyDepartmentRoot';
import DepartmentManagement from './DepartmentManagement';
import UniGeneralButtons from '../../../components/commonComponents/uniGeneralButtons/UniGeneralButtons';
import useDepartments from '../../../redux/departments/hooks/useDepartments';
import { getUserRootActorUUID } from '../../../redux/storages/selectors';

export default function MyDepartmentPage() {
  const isAdOrRoot = useSelector(isRootOrAdmin);
  const userActorUUID = useSelector(getUserRootActorUUID);

  // const tabs = [
  //   { name: 'my_department', title: 'Мой отдел' },
  //   { name: 'management', title: 'Управление отделами' },
  // ];

  // const [activeTab, setActiveTab] = useState('my_department');

  // const switchViewFunc = (name) => {
  //   setActiveTab(name);
  // };

  const {
    departments,
    isFetching,
  } = useDepartments({
    defaultConfig: {
      limit: 1,
      params: {
        usersSearch: [userActorUUID],
      },
    },
  });

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Мой отдел
        </Flex>
      )}
    >
      <Flex
        vertical
        className="w-full"
      >
        {/* <Flex
          className="mb-3"
        >
          {(!isFetching && departments?.length) && (
          <UniGeneralButtons
            viewMode={activeTab}
            switchViewFunc={switchViewFunc}
            tabs={tabs}
          />
          )}
        </Flex> */}
        {/* {activeTab === 'my_department' && */}
        <MyDepartmentRoot isLeaderOrDeputy={!isFetching && (!!departments?.length || isAdOrRoot)} />
        {/* } */}
        {/* {activeTab === 'management' && } */}
      </Flex>
    </PageWrapper>
  );
}
