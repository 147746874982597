import {
  Button,
  Card, Divider, Flex, Modal, Segmented, Spin, Table, Tag,
  Upload,
} from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiPencil } from '@mdi/js';
import {
  bool, func, object, string,
} from 'prop-types';
import {
  capitalize, get, head, omit,
} from 'lodash';
import dayjs from 'dayjs';
import cn from 'classnames';
import { getArtifact, getArtifactUUID } from '../../redux/departments/selectors/artifactsSelector';
import { getDepartment } from '../../redux/departments/selectors/departmentsSelector';
import UserArtifactView from './view/UserArtifactView';
import FileArtifactView from './view/FileArtifactView';
import CommonInfo from '../commonComponents/entityCardComponents/CommonInfo';
import CopyURL from '../commonComponents/CopyURL';
import { removeFile } from '../../api/actions/entity';
import {
  DELETE_SINGLE_ARTIFACT_FAILURE,
  DELETE_SINGLE_ARTIFACT_REQUEST,
  DELETE_SINGLE_ARTIFACT_SUCCESS,
  UPDATE_SINGLE_ARTIFACT_FAILURE,
  UPDATE_SINGLE_ARTIFACT_REQUEST,
  UPDATE_SINGLE_ARTIFACT_SUCCESS,
} from '../../redux/departments/slices/artifactsSlice';
import useAPI from '../../api/useAPI';
import useArifactsStorage from '../../redux/departments/hooks/useArifactsStorage';
import useDefaultDepartment from '../../redux/departments/hooks/useDefaultDepartment';
import { isRootOrAdmin } from '../../redux/profile/selectors';
import useLogsAPI from '../../api/useLogsAPI';
import useArtifactsAPI from '../../api/useArtifactsAPI';
import InstructionArtifactView from './view/InstructionArtifactView';

export default function ArtifactCard({
  title,
  artifact,
  artifactUUID,
  isFetching = false,
  className = '',
  hideDeleteButton,
  hideCopyURLButton,
  hideBackButton,
  onBackArtCallback,
  onDeleteArtCallback,
  onUpdateArtCallback,
}) {
  const dispatch = useDispatch();
  const { createLog } = useLogsAPI();

  const isAdOrRoot = useSelector(isRootOrAdmin);

  const { uuid: artifactsStorageUUID } = useArifactsStorage(false);

  const { uuid: defaultDepartmentUUID } = useDefaultDepartment(false);

  const { updateArtifact, deleteArtifact } = useArtifactsAPI();

  const {
    uuid,
    entity_type: entityType,
  } = artifact || {};

  const {
    updateUserEntity,
    createEntityPermissions,
  } = useAPI();

  const getCardName = (type) => {
    switch (type) {
      case 'file': {
        return 'Файл';
      }
      case 'instruction': {
        return 'Инструкция';
      }
      case 'schedule': {
        return 'Расписание';
      }
      case 'user': {
        return 'Сотрудник';
      }
      default: return '';
    }
  };

  const onDeleteArtifact = async () => {
    if (artifact?.entity_type === 'user') {
      Modal.warning({
        title: `Сотрудник ${get(artifact, ['uinfo', 'first_name'], '')} ${get(artifact, ['uinfo', 'last_name'], '')}`,
        content: (
          <Flex
            vertical
          >
            <span>
              Вы уверены, что хотите удалить сотрудника из отдела?
            </span>
            <span>
              Сотрудник станет доступен для назначения в другой отдел.
            </span>
          </Flex>
        ),
        width: 'auto',
        centered: true,
        keyboard: true,
        maskClosable: true,
        closable: true,
        okText: 'Удалить',
        onOk: async () => {
          if (artifactUUID && defaultDepartmentUUID) {
            updateUserEntity(artifactUUID, {
              parent: defaultDepartmentUUID,
            });

            createEntityPermissions({
              data: {
                entity_uuid: artifact?.parent,
                data: [{
                  actor: artifact?.actor,
                  create: false,
                  list: false,
                  read: false,
                  update: false,
                  delete: false,
                  set: false,
                }],
              },
            });
            if (onDeleteArtCallback) {
              onDeleteArtCallback();
            }
          }
        },
      });
    } else {
      Modal.warning({
        title: `Артефакт ${get(artifact, ['params', 'title'], '')}`,
        content: 'Вы уверены, что хотите удалить артефакт?',
        width: 'auto',
        centered: true,
        keyboard: true,
        maskClosable: true,
        closable: true,
        okText: 'Удалить',
        onOk: async () => {
          if (artifactUUID) {
            await deleteArtifact(artifactUUID, {}, [
              DELETE_SINGLE_ARTIFACT_REQUEST,
              DELETE_SINGLE_ARTIFACT_SUCCESS,
              DELETE_SINGLE_ARTIFACT_FAILURE,
            ]);
            if (onDeleteArtCallback) {
              onDeleteArtCallback();
            }
          }
        },
      });
    }
  };

  const onUpdateArtifact = async (
    data = null,
    options = {},
  ) => {
    if (artifactUUID) {
      const config = {
        entity_uuid: artifactUUID,
        entity_type: artifact?.entity_type,
        files: options?.file?.filter((el) => !el?.entity),
        ...omit(options, ['file']),
      };

      if (data) {
        config.params = { ...data };
      }

      const res = await updateArtifact(artifactUUID, config, [
        UPDATE_SINGLE_ARTIFACT_REQUEST,
        UPDATE_SINGLE_ARTIFACT_SUCCESS,
        UPDATE_SINGLE_ARTIFACT_FAILURE,
      ]);

      if (onUpdateArtCallback) {
        onUpdateArtCallback(head(res));
      }
    }
  };

  const onDeleteFileCallback = async (file) => {
    if (file) {
      const res = await dispatch(removeFile(artifactUUID, file?.uuid, 'file', [
        UPDATE_SINGLE_ARTIFACT_REQUEST,
        UPDATE_SINGLE_ARTIFACT_SUCCESS,
        UPDATE_SINGLE_ARTIFACT_FAILURE,
      ]));

      if (onUpdateArtCallback) {
        onUpdateArtCallback(head(res));
      }
    }
  };

  return (
    <Card
      className={cn(`w-full h-max p-0 ${className}`, {
        'green-base-card': artifact?.entity_type === 'user',
        'blue-base-card': artifact?.entity_type === 'schedule'
      || artifact?.entity_type === 'instruction'
      || artifact?.entity_type === 'file',
      })}
      title={(
        <Flex
          className="w-full"
          justify="space-between"
        >
          <Flex
            align="center"
          >
            <Flex
              onClick={!hideBackButton && onBackArtCallback}
              align="center"
              className="cursor-pointer"
            >
              {!hideBackButton && <Icon path={mdiArrowLeft} size={0.9} />}
              <span className="ml-3 text-xl">
                {title || getCardName(artifact?.entity_type)}
              </span>
            </Flex>
            <CommonInfo
              iconSize={1}
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
              className="ml-2 mt-1"
              creator={`${capitalize(get(artifact, 'uinfo.first_name', ''))} ${get(artifact, 'uinfo.last_name', '')}`}
              createdDate={get(artifact, 'created', '')}
            />
            {!hideCopyURLButton && (
            <CopyURL
              entityType="artifact"
              entityUUID={uuid}
              className="ml-1 pt-2.5 mt-0.5 pl-2"
              iconSize={1}
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
            />
            )}
          </Flex>
          <Flex>
            {isAdOrRoot && !hideDeleteButton && (
            <Button
              size="small"
              onClick={onDeleteArtifact}
              className="ml-2 !text-red-500 border !border-red-500 hover:bg-red-100"
            >
              Удалить
            </Button>
            )}
          </Flex>
        </Flex>
          )}
    >
      <Spin
        className="w-full h-full"
        spinning={isFetching}
      >
        <Flex
          vertical
          className="py-2"
        >
          {artifact?.entity_type === 'user' && (
          <UserArtifactView
            onUpdateArtifactCallback={onUpdateArtifact}
            artifact={artifact}
          />
          )}
          {artifact?.entity_type === 'instruction' && (
          <InstructionArtifactView
            artifactsStorage={artifactsStorageUUID}
            onUpdateArtifactCallback={onUpdateArtifact}
            artifact={artifact}
          />
          )}
          {artifact?.entity_type === 'file' && (
          <FileArtifactView
            artifactsStorage={artifactsStorageUUID}
            onUpdateArtifactCallback={onUpdateArtifact}
            onDeleteFileCallback={onDeleteFileCallback}
            artifact={artifact}
          />
          )}
        </Flex>

      </Spin>
    </Card>
  );
}

ArtifactCard.propTypes = {
  title: string,
  artifact: object,
  isFetching: bool,
  artifactUUID: string,
  className: string,
  hideDeleteButton: bool,
  hideCopyURLButton: bool,
  hideBackButton: bool,
  onBackArtCallback: func,
  onDeleteArtCallback: func,
  onUpdateArtCallback: func,
};
