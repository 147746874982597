import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List, Spin, Button, Space,
} from 'antd';
import { DownOutlined, UpOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useEventsAPI from '../../../api/useEventsAPI';
import { getDeliveryPartition } from '../../../redux/config/selectors';

export default function MetaeventEvents({ metaeventUuid }) {
  const { getListOfEvents } = useEventsAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedEvents, setExpandedEvents] = useState(new Set());

  useEffect(() => {
    const fetchEvents = async () => {
      if (metaeventUuid) {
        setIsLoading(true);
        try {
          const res = await getListOfEvents(partitionUUID, {
            params: {
              metaevent: metaeventUuid,
            },
          });
          setEvents(res?.data || []);
        } catch (error) {
          console.error('Error loading events:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchEvents();
  }, [metaeventUuid]);

  const toggleEventDetails = (eventId) => {
    const newExpanded = new Set(expandedEvents);
    if (newExpanded.has(eventId)) {
      newExpanded.delete(eventId);
    } else {
      newExpanded.add(eventId);
    }
    setExpandedEvents(newExpanded);
  };

  const renderEventContent = (event) => {
    const isExpanded = expandedEvents.has(event.uuid);
    const history = useHistory();

    const handleGoToVacation = () => {
      if (event.params.vacation) {
        history.push(`/vacations/${event.params.vacation}`);
      }
    };

    return (
      <div className="event-content">
        <div className="event-main">
          <div className="event-info">
            <div className="event-title">{event.params.title}</div>
            <div className="event-meta">
              <span className="event-date">
                Создано:
                {' '}
                {new Date(event.created).toLocaleDateString('ru-RU', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
              <span className="event-creator">
                •
                {' '}
                {event.uinfo
                  ? `${event.uinfo.first_name} ${event.uinfo.last_name}`
                  : event.actor}
              </span>
            </div>
          </div>
          <Button
            type="text"
            className="details-button"
            icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
            onClick={() => toggleEventDetails(event.uuid)}
          >
            {isExpanded ? 'Скрыть детали' : 'Показать детали'}
          </Button>
        </div>
        {isExpanded && (
          <div className="event-details">
            <div className="details-grid">
              <div className="detail-item">
                <span className="detail-label">UUID:</span>
                <span className="detail-value">{event.uuid}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Создатель:</span>
                <span className="detail-value">
                  {event.uinfo ? `${event.uinfo.first_name} ${event.uinfo.last_name}` : event.actor}
                </span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Тип:</span>
                <span className="detail-value">{event.entity_type}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Параметры:</span>
                <pre className="detail-value">{JSON.stringify(event.params, null, 2)}</pre>
              </div>
            </div>
            {event.params.vacation && (
              <div className="event-actions">
                <Button
                  type="primary"
                  icon={<ArrowRightOutlined />}
                  onClick={handleGoToVacation}
                >
                  Перейти к отпуску
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="metaevent-events">
      <Spin spinning={isLoading}>
        <List
          dataSource={events}
          renderItem={(event) => (
            <List.Item>
              {renderEventContent(event)}
            </List.Item>
          )}
          locale={{ emptyText: 'Нет событий' }}
        />
      </Spin>
    </div>
  );
}

MetaeventEvents.propTypes = {
  metaeventUuid: PropTypes.string.isRequired,
};
