import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex, Input, Modal, Segmented,
} from 'antd';
import { useDispatch } from 'react-redux';
import { func, string } from 'prop-types';
import { get, head, trim } from 'lodash';
import cn from 'classnames';
import EntityDepartmentForArtifact from '../commonComponents/entityCardComponents/EntityDepartmentForArtifact';
import AntUploaderFiles from '../commonComponents/AntUploaderFiles';
import useLogsAPI from '../../api/useLogsAPI';
import useArtifactsAPI from '../../api/useArtifactsAPI';

const { TextArea } = Input;

export default function AddArtifactButton({
  parent,
  artifactsStorage,
  defaultDepartmentUUID = '',
  onCreateArtifactCallback,
}) {
  const dispatch = useDispatch();

  const { createLog } = useLogsAPI();

  const { createArtifact } = useArtifactsAPI();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedType, setSelectedType] = useState('instruction');
  const [department, setDepartment] = useState('');
  const [filesList, setFiles] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => setIsOpen(false);

  const options = [{
    label: 'Инструкция',
    value: 'instruction',
  }, {
    label: 'Файл',
    value: 'file',
  }].filter((el) => !el?.hidden);

  const onCreateArtifact = async () => {
    const newArtifact = {
      entity_type: selectedType,
      parent: department || parent,
      params: {
        department,
        status: 'active',
        description,
        title,
      },
      files: filesList,
    };
    // const newFormData = new FormData();

    // newFormData.append('data', JSON.stringify(newArtifact));

    // if (filesList?.length !== 0) {
    //   for (let i = 0; i < filesList?.length; i += 1) {
    //     newFormData.append('file', filesList?.[i]);
    //   }
    // }
    onCloseModal();
    const res = await createArtifact(department || parent, newArtifact);

    if (onCreateArtifactCallback) {
      const artifactUUID = get(head(res), 'uuid', '');
      onCreateArtifactCallback(artifactUUID);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setDepartment(defaultDepartmentUUID);
    } else {
      setTitle('');
      setDescription('');
      setSelectedType('instruction');
      setDepartment(null);
      setFiles([]);
    }
  }, [isOpen]);

  return (
    <>
      <Button
        size="small"
        onClick={onOpenModal}
        className="ml-2 mt-1 !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white"
      >
        Создать артефакт
      </Button>
      <Modal
        open={isOpen}
        title="Создание артефакта"
        onCancel={onCloseModal}
        footer={null}
        centered
        width={800}
        destroyOnClose
      >
        <Flex align="center" justify="space-between" className="mb-3">
          <Flex align="center">
            <span className="mr-1">Тип</span>
            <Segmented
              options={options}
              value={selectedType}
              onChange={setSelectedType}
            />
          </Flex>
        </Flex>
        <Flex align="center" justify="space-between" className="mb-3">
          <EntityDepartmentForArtifact
            department={department}
            artifactsStorage={artifactsStorage}
            onEditCallback={({ department: dep }) => setDepartment(dep)}
          />
        </Flex>
        {(selectedType === 'instruction'
          || selectedType === 'file'
        ) && (
        <Flex
          vertical
          className="mb-3"
        >
          <span>
            Название
          </span>
          <Input
            value={title}
            maxLength={128}
            disabled={selectedType === 'employee'}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Flex>
        )}
        {selectedType === 'instruction' && (
        <Flex
          vertical
          className="mb-3"
        >
          <span>
            Инструкция
          </span>
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            maxLength={8000}
            style={{ height: 250 }}
            row={12}
          />
        </Flex>
        )}
        {selectedType === 'file' && (
        <AntUploaderFiles
          uploadProps={{ showUploadList: { showDownloadIcon: false } }}
          filesList={filesList}
          setFiles={setFiles}
        />
        )}
        <Flex
          className="mt-3"
          justify="end"
        >
          <Button
            className="mr-2 !border-red-500 text-red-500 hover:bg-red-500 hover:!text-white"
            onClick={onCloseModal}
          >
            Отмена
          </Button>
          <Button
            className={cn('border-green-500 text-green-500 hover:bg-green-500 hover:!text-white', {
              'border !border-gray-500 text-gray-500 hover:!bg-gray-500 hover:!text-white': !trim(title)?.length,
            })}
            disabled={!trim(title)?.length}
            onClick={onCreateArtifact}
          >
            Создать
          </Button>
        </Flex>
      </Modal>
    </>
  );
}

AddArtifactButton.propTypes = {
  parent: string,
  artifactsStorage: string,
  defaultDepartmentUUID: string,
  onCreateArtifactCallback: func,
};
