import {
  Flex, Modal, Spin, Table,
  Tooltip,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import {
  bool, func, object, string,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import { get, omit } from 'lodash';
import dayjs from 'dayjs';
import DaysCalendar from '../../../components/days/DaysCalendar';
import { enumerateDaysBetweenDates } from '../../../utils/commonUtils';
import useAPI from '../../../api/useAPI';
import { getArrByType } from '../dayOff/utils';
import useVacationsAPI from '../../../api/useVacationsAPI';

export default function MonthlyReportDaysModal({
  month,
  report,
  user,
  onClose,
}) {
  const dispatch = useDispatch();

  const {
    getListOfVacations,
  } = useVacationsAPI();

  const [vacationsArr, setVacationsArr] = useState([]);

  const actor = get(report, 'actor', '');
  const rootUserUUID = get(user, 'uuid', '');

  const startMonth = dayjs(month).startOf('month').format('YYYY-MM-DD');
  const endMonth = dayjs(month).endOf('month').format('YYYY-MM-DD');

  const data = useMemo(() => getArrByType([
    ...vacationsArr, ...get(report, 'params.days', []),
  ], enumerateDaysBetweenDates(startMonth, endMonth)), [
    JSON.stringify(vacationsArr),
    JSON.stringify(report?.params?.days),
    startMonth,
    endMonth,
    actor,
  ]);

  const getListAgreedVacations = async () => {
    const configRequest = {
      actor,
      params: {
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          dayjs(startMonth).format('YYYY-MM-DD'),
          dayjs(endMonth).format('YYYY-MM-DD'),
        ),
      },
    };
    const res = await getListOfVacations(
      rootUserUUID,
      configRequest,
      [
        'GET_VACATIONS_REQUEST',
        'GET_VACATIONS_SUCCESS',
        'GET_VACATIONS_FAILURE',
      ],
    );
    setVacationsArr(res?.data);
  };

  useEffect(() => {
    if (actor && rootUserUUID) {
      getListAgreedVacations();
    }
  }, [
    actor,
    startMonth,
    endMonth,
    rootUserUUID,
  ]);

  return (
    <Modal
      open={rootUserUUID}
      title="Календарь"
      onCancel={onClose}
      centered
      width={1000}
      destroyOnClose
      footer={false}
    >
      <DaysCalendar
        data={[...data?.values()]}
        month={month}
      />
    </Modal>
  );
}

MonthlyReportDaysModal.propTypes = {
  month: string,
  report: object,
  user: object,
  onClose: func,
};
