/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const metaeventStorageSlice = createSlice({
  name: 'metaeventStorage',
  initialState: {
    isFetching: false,
    storage: null,
    storageUUID: '',
  },
  reducers: {
    GET_OR_CREATE_METAEVENT_STORAGE_REQUEST: (state) => {
      state.isFetching = true;
    },
    GET_OR_CREATE_METAEVENT_STORAGE_SUCCESS: (state, action) => {
      state.storage = action?.payload;
      state.storageUUID = action?.payload?.uuid;
      state.isFetching = false;
    },
    GET_OR_CREATE_METAEVENT_STORAGE_FAILURE: (state) => {
      state.isFetching = false;
    },
  },
});

export const {
  GET_OR_CREATE_METAEVENT_STORAGE_REQUEST,
  GET_OR_CREATE_METAEVENT_STORAGE_SUCCESS,
  GET_OR_CREATE_METAEVENT_STORAGE_FAILURE,
} = metaeventStorageSlice.actions;

export default metaeventStorageSlice.reducer;
