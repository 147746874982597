import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button, Flex,
} from 'antd';

const { TextArea } = Input;

export default function CMECreateVacationRequestForm({
  form,
  onSubmit,
  onCancel,
  isEdit,
}) {
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      className="meta-event-form"
    >
      <Form.Item
        name="title"
        label="Название метасобытия"
        rules={[{ required: true, message: 'Введите название' }]}
      >
        <Input placeholder="Не будет доступно пользователю" />
      </Form.Item>

      <Form.Item
        name="instruction"
        label="Инструкция для пользователя"
        rules={[{ required: true, message: 'Добавьте инструкцию' }]}
      >
        <TextArea
          rows={4}
          placeholder="Опишите процесс и важные детали для пользователя"
        />
      </Form.Item>

      <Flex justify="end">
        <Form.Item className="form-actions">
          <Button onClick={onCancel}>
            Отмена
          </Button>
          <Button type="primary" className="ml-2" htmlType="submit">
            {isEdit ? 'Сохранить изменения' : 'Создать метасобытие'}
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
}

CMECreateVacationRequestForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};
