import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, useHistory,
} from 'react-router-dom';

import { string } from 'prop-types';
import dayjs from 'dayjs';
import {
  capitalize, get,
} from 'lodash';
import cn from 'classnames';

import { Flex, Spin } from 'antd';
import Icon from '@mdi/react';
import {
  mdiArrowLeftBold,
  mdiArrowRightBold,
} from '@mdi/js';
import useAPI from '../../../api/useAPI';

import { getDeliveryPartition } from '../../../redux/config/selectors';

import { compareActorUUIDs } from './utils';

import BaseTable from '../../../w54_components/BaseTable/BaseTable';
import EmployeeNameForDepartment from './EmployeeNameForDepartment';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import { getListAndReadEntities } from '../../../api/actions/entity';

export default function ReportsForDepartment({
  departmentUUID = '',
  leader = '',
  deputy = '',
}) {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);

  const activeArtifact = query.get('artifact');

  const {
    getMonthlyReport,
  } = useAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [dataTable, setDataTable] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [currentMonth, setCurrentMonth] = useState(dayjs().subtract(1, 'month').format('YYYY-MM'));

  const handlePrevMonth = () => {
    setCurrentMonth(dayjs(currentMonth).subtract(1, 'month').format('YYYY-MM'));
  };

  const handleNextMonth = () => {
    setCurrentMonth(dayjs(currentMonth).add(1, 'month').format('YYYY-MM'));
  };

  const columns = [
    {
      title: 'Имя',
      key: 'name',
      dataIndex: ['uinfo'],
      width: '30%',
      render: (cell, row) => (
        <EmployeeNameForDepartment
          user={row}
          deputy={deputy}
          leader={leader}
        />
      ),
    },
    {
      title: 'Проектных часов',
      dataIndex: ['report', 'params', 'statistics', 'totalWorks'],
      render: (cell, row) => (row?.report ? (cell || 0) : '-'),
    },
    {
      title: 'Клиентский пр.',
      dataIndex: ['report', 'params', 'statistics', 'clientWork'],
      render: (cell, row) => (row?.report ? (cell || 0) : '-'),
    },
    {
      title: 'Внутренний пр.',
      dataIndex: ['report', 'params', 'statistics', 'internalWork'],
      render: (cell, row) => (row?.report ? (cell || 0) : '-'),
    },
    {
      title: 'Бенч',
      dataIndex: ['report', 'params', 'statistics', 'totalBenches'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{row?.report ? (cell || 0) : '-'}</span>,
    },
    {
      title: 'Нерабочих часов',
      dataIndex: ['report', 'params', 'statistics', 'totalDayOff'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{row?.report ? (cell || 0) : '-'}</span>,
    },
    {
      title: 'Отгул',
      dataIndex: ['report', 'params', 'statistics', 'dayoff'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{row?.report ? (cell || 0) : '-'}</span>,
    },
    {
      title: 'Больничный',
      dataIndex: ['report', 'params', 'statistics', 'sickday'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{row?.report ? (cell || 0) : '-'}</span>,
    },
    {
      title: 'Отпуск',
      dataIndex: ['report', 'params', 'statistics', 'vacation'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{row?.report ? (cell || 0) : '-'}</span>,
    }];

  const onRow = (record) => ({
    onClick: () => {
      const uuid = get(record, 'uuid', '');

      query.set('artifact', uuid);

      history.push({
        pathname: location.pathname,
        search: query.toString(),
      });
    },
  });

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === activeArtifact,
  });

  const initFunc = async () => {
    setIsFetching(true);

    const resUsers = await dispatch(
      getListAndReadEntities({
        data: {
          entity_type: 'user',
          parent: departmentUUID,
        },
        constants: [
          'GET_EMPLOYEES_FOR_DEPARTMENT_REQUEST',
          'GET_EMPLOYEES_FOR_DEPARTMENT_SUCCESS',
          'GET_EMPLOYEES_FOR_DEPARTMENT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }),
    );

    const users = resUsers?.data ?? [];

    if (!users?.length) {
      setDataTable([]);
      setIsFetching(false);
      return;
    }

    const res = await getMonthlyReport(partitionUUID, {
      depth: 0,
      params: {
        date: currentMonth,
      },
    });

    const reports = res?.data ?? [];

    setDataTable(users.map((user) => {
      const report = reports
        .find((el) => compareActorUUIDs(el, user) && el?.params?.date === currentMonth);

      return {
        ...user,
        report: report ?? null,
      };
    }));

    setIsFetching(false);
  };

  useEffect(() => {
    if (departmentUUID && currentMonth) {
      initFunc();
    }
  }, [departmentUUID, currentMonth]);

  return (
    <Flex
      vertical
    >
      <Flex
        align="center"
        className="mt-2 text-xl"
      >
        <Icon
          className="mx-2 cursor-pointer text-blue-500 hover:bg-sky-100"
          onClick={handlePrevMonth}
          style={{
            // border: '1px solid white',
          }}
          path={mdiArrowLeftBold}
          size={1.2}
        />
        <Flex
          justify="center"
          className="text-xl text-blue-500"
          style={{
            width: 130,
          }}
        >
          {capitalize(dayjs(currentMonth).format('MMMM YYYY'))}
        </Flex>
        <Icon
          className="ml-2 cursor-pointer text-blue-500 hover:bg-sky-100"
          onClick={handleNextMonth}
          path={mdiArrowRightBold}
          size={1.2}
        />
      </Flex>
      <Spin
        className="w-full h-100"
        spinning={isFetching}
      >
        <BaseTable
          data={dataTable}
          columns={columns}
          rowClassName={rowClassName}
          size="small"
          rowKey="uuid"
          onRow={onRow}
          className={cn('w-full h-full !mb-3')}
          useCustomPagination
          disablePagination
        />
      </Spin>
    </Flex>
  );
}

ReportsForDepartment.propTypes = {
  departmentUUID: string,
  leader: string,
  deputy: string,
};
