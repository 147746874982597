import React from 'react';
import { Card, Flex } from 'antd';
import EmployeeRequestsPage from '../../../pages/admin/employeeRequests/EmployeeRequestsPage';
import EmployeeRequestsTable from '../../employeeRequest/EmployeeRequestsTable';
import useURLParams from '../../../hooks/useURLParams';

export default function DepartmentRequests() {
  const { getURLParams, setSearchParams } = useURLParams();
  const { d: activeDepartment } = getURLParams();
  return (
    <Card
      className="w-full h-max p-0 pb-2 blue-base-card"
      title={(
        <Flex
          className="text-xl"
        >
          Заявки на сотрудников
        </Flex>
)}
    >
      <EmployeeRequestsTable
        isDepartmentRoutes
        departmentUUID={activeDepartment}
      />
    </Card>
  );
}
