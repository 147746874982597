import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import axiosRequest from './api';
import ProfileConstants from '../redux/profile/constants';
import ActorsConstants from '../redux/actors/constants';
import { getArrayForItem } from '../utils/commonUtils';
import { getListAndReadEntities } from './actions/entity';
import { getPublicPartition } from '../redux/config/selectors';
import publicUsersConstants from '../redux/publicUserEntity/constants';
import { getProfileInfo } from '../redux/profile/selectors';
import antNotification from '../utils/antNotification';

const useActorsAPI = () => {
  const dispatch = useDispatch();
  const publicPartition = useSelector(getPublicPartition);
  const profileInfo = useSelector(getProfileInfo);

  const getListOfActors = async (data, constants) => {
    try {
      const res = await dispatch(axiosRequest.post(
        'utility/actor/list',
        constants || [
          ActorsConstants.FETCH_ACTORS_REQUEST,
          ActorsConstants.FETCH_ACTORS_SUCCESS,
          ActorsConstants.FETCH_ACTORS_FAILURE,
        ],
        data,
      ));
      console.log(res);
      return res;
    } catch (e) {
      antNotification.error('Не удалось получить список пользователей!');
      return null;
    }
  };

  const requestGetProfile = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'utility/actor/me',
        [
          ProfileConstants.GET_PROFILE_INFO_REQUEST,
          ProfileConstants.GET_PROFILE_INFO_SUCCESS,
          ProfileConstants.GET_PROFILE_INFO_FAILURE,
        ],
        {},
        {
          partition: 'USERS',
          onSuccess: resolve,
          onFailure: reject,
          onCancel: reject,
        },
      ),
    );
  });

  function getUsersByUUID({
    uuid,
    isGroups,
  }) {
    const data = {};

    // if need get users from groups, use flag isGroups
    if (isGroups) {
      data.group_uuids = uuid;
    } else {
      data.uuids = getArrayForItem(uuid);
    }

    return new Promise((resolve, reject) => {
      dispatch(axiosRequest.post(
        'utility/actor/get',
        [
          ActorsConstants.FETCH_ACTORS_REQUEST,
          {
            type: ActorsConstants.FETCH_ACTORS_SUCCESS,
            payload: (response) => {
              const actors = response?.data || [];

              resolve(actors);

              return actors;
            },
          },
          {
            type: ActorsConstants.FETCH_ACTORS_FAILURE,
            payload: (error) => {
              reject(error.response ? error.response.data : {});

              return error.response ? error.response.data : {};
            },
          },
        ],
        data,
      ));
    });
  }

  function getListOfAllActors(data, constants = null) {
    return new Promise((resolve, reject) => {
      dispatch(axiosRequest.post(
        'utility/actor/list',
        constants || [
          ActorsConstants.FETCH_ACTORS_REQUEST,
          {
            type: ActorsConstants.FETCH_ACTORS_SUCCESS,
            payload: (res) => {
              resolve(res);
              return get(res, 'data', res);
            },
          },
          ActorsConstants.FETCH_ACTORS_FAILURE,
        ],
        data,
        {
          onFailure: reject,
        },
      ));
    });
  }

  const getMyInfoAndListOfActors = (params) => requestGetProfile()
    .then((res) => {
      const { is_admin = '', is_root = '', uinfo } = res;

      if (is_admin || is_root) {
        return getListOfAllActors(params);
      }

      const userGroups = get(uinfo, 'groups', []);

      if (userGroups.length !== 0) {
        return getUsersByUUID({
          uuid: userGroups,
          isGroups: true,
        });
      }
    });

  const getListOfActorsForVac = (params) => {
    const { is_admin = '', is_root = '', uinfo } = profileInfo || {};

    if (is_admin || is_root) {
      return getListOfAllActors(params);
    }

    const userGroups = get(uinfo, 'groups', []);

    if (userGroups.length !== 0) {
      return getUsersByUUID({
        uuid: userGroups,
        isGroups: true,
      });
    }
  };

  // PUBLIC USER ENTITY
  function getListOfAllPublicUsersEntity(data) {
    const config = {
      data: {
        entity_type: 'user',
        actor_type: ['classic_user', 'user'],
        parent: publicPartition,
        ...data,
      },
      constants: [
        publicUsersConstants.GET_PUBLIC_USERS_ENTITIES_REQUEST,
        publicUsersConstants.GET_PUBLIC_USERS_ENTITIES_SUCCESS,
        publicUsersConstants.GET_PUBLIC_USERS_ENTITIES_FAILURE,
      ],
      partition: 'PUBLIC',
    };
    return dispatch(getListAndReadEntities(config));
  }

  return {
    /// ///
    getListOfActors,
    /// ///
    requestGetProfile,
    getMyInfoAndListOfActors,
    getUsersByUUID,
    getListOfAllPublicUsersEntity,
    getListOfAllActors,
    getListOfActorsForVac,
  };
};

export default useActorsAPI;
