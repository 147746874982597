import React from 'react';
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types';
import cn from 'classnames';

import { mdiCalendarClock, mdiCheckboxMarkedCircleOutline, mdiHelp } from '@mdi/js';

import { Col } from 'antd';
import RadioButtons from '../../../w54_components/RadioButtons/RadioButtons';

const defaultBorderStyles = {
  borderRadius: '6px',
};

const options = [
  {
    value: 'active',
    label: 'active',
    className: 'radio-green',
    icon: mdiCheckboxMarkedCircleOutline,
  },
  {
    value: 'archive',
    label: 'archive',
    className: 'radio-gray',
    icon: mdiCalendarClock,
  },
];

function EntityStatusForCard({
  className,
  title,
  currentValue = 'none',
  classNameForTitle,
  classNameForButtonsBox = 'p-1',
  disabled = false,
  withIcons = false,
  withBorder = false,
  onChangeValue,
}) {
  return (
    <Col className={className}>
      <div className="flex items-center">
        {title && (
        <span className={classNameForTitle}>
          {title}
          :
        </span>
        )}
      </div>
      <div
        style={defaultBorderStyles}
        className={cn(`${classNameForButtonsBox}`, { border: withBorder })}
      >
        <RadioButtons
          value={currentValue}
          options={options}
          disabled={disabled}
          setIcons={withIcons}
          onChange={onChangeValue}
        />
      </div>
    </Col>
  );
}

EntityStatusForCard.propTypes = {
  className: string,
  title: string,
  currentValue: string,
  options: arrayOf(object).isRequired,
  classNameForTitle: string,
  classNameForButtonsBox: string,
  disabled: bool,
  withIcons: bool,
  withBorder: bool,
  onChangeValue: func,
};

export default EntityStatusForCard;
