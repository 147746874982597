import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getOrCreateSingle } from './actions/entity';

import { getDeliveryPartition } from '../redux/config/selectors';

import { getMD5HashFromString } from '../utils';
import { SERVICE_PARTITION_NAME } from './app_config';

import {
  GET_OR_CREATE_METAEVENT_STORAGE_FAILURE,
  GET_OR_CREATE_METAEVENT_STORAGE_REQUEST,
  GET_OR_CREATE_METAEVENT_STORAGE_SUCCESS,
} from '../redux/metaeventStorage/metaeventStorageSlice';

export default function useMetaeventStorage() {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);

  const getMetaeventStorage = () => {
    dispatch(getOrCreateSingle({
      data: {
        entity_type: 'metaeventStorage',
        parent: partitionUUID,
        key: getMD5HashFromString(`metaeventStorage${partitionUUID}`),
      },
      constants: [
        GET_OR_CREATE_METAEVENT_STORAGE_REQUEST,
        GET_OR_CREATE_METAEVENT_STORAGE_SUCCESS,
        GET_OR_CREATE_METAEVENT_STORAGE_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }));
  };

  useEffect(() => {
    if (partitionUUID) {
      getMetaeventStorage();
    }
  }, [partitionUUID]);

  return {
    reloadMetaeventStorage: getMetaeventStorage,
  };
}
