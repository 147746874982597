import React, { useEffect } from 'react';
import { Alert, Card, Flex } from 'antd';
import { bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeRequestsTable from '../../../components/employeeRequest/EmployeeRequestsTable';
import PageWrapper from '../../../components/PageWrapper';
import { getDepartment } from '../../../redux/departments/selectors/departmentsSelector';
import { getProfileUUID } from '../../../redux/profile/selectors';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import useURLParams from '../../../hooks/useURLParams';
import {
  GET_SINGLE_DEPARTMENT_REQUEST,
  GET_SINGLE_DEPARTMENT_SUCCESS,
  GET_SINGLE_DEPARTMENT_FAILURE,
  RESET_SINGLE_DEPARTMENT,
} from '../../../redux/departments/slices/departmentsSlice';
import DepartmentsList from '../../../components/departments/DepartmentsList';

function EmployeeRequestsPage({ isDepartmentRoutes = false }) {
  const dispatch = useDispatch();

  const currentDepartment = useSelector(getDepartment);

  const actorUUID = useSelector(getProfileUUID);

  const { readListOfDepartments } = useDepartmentsAPI();

  const { getURLParams, clearSearchParams } = useURLParams();

  const {
    department: activeDepartment,
    artifact: activeArtifact,
  } = getURLParams();

  useEffect(() => {
    if (activeDepartment) {
      readListOfDepartments(activeDepartment, {}, [
        GET_SINGLE_DEPARTMENT_REQUEST,
        GET_SINGLE_DEPARTMENT_SUCCESS,
        GET_SINGLE_DEPARTMENT_FAILURE,
      ]);
    } else {
      dispatch(RESET_SINGLE_DEPARTMENT);
    }
  }, [activeDepartment]);

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Заявки
        </Flex>
      )}
    >
      <DepartmentsList
        hideAddButton
        hideKPIButton
        hideExpand
        // defaultConfig={{
        //   params: {
        //     usersSearch: [actorUUID],
        //   },
        // }}
        name="department_list"
        currentDepartment={currentDepartment}
      />
      {activeDepartment ? (
        <Card
          className="w-full h-max p-0 pb-2 mt-6 blue-base-card"
          title={(
            <Flex
              className="text-xl"
            >
              Заявки на сотрудников
            </Flex>
      )}
        >
          <EmployeeRequestsTable
            isDepartmentRoutes={isDepartmentRoutes}
            departmentUUID={activeDepartment}
          />
        </Card>
      ) : (
        <Alert
          showIcon
          type="info"
          className="mt-6 text-blue-500 text-lg"
          message="Для просмотра списка заявок необходимо выбрать отдел."
        />
      )}
    </PageWrapper>
  );
}

export default EmployeeRequestsPage;

EmployeeRequestsPage.propTypes = {
  isDepartmentRoutes: bool,
};

EmployeeRequestsPage.defaultProps = {
  isDepartmentRoutes: false,
};
