import { useDispatch, useSelector } from 'react-redux';

import { head, omit } from 'lodash';
import { SERVICE_PARTITION_NAME, authServiceURL, currentServiceUrl } from './app_config';
import {
  entityUpdate,
  getListAndReadEntities,
  getListOfEntities,
  getOrCreateSingle,
} from './actions/entity';
import axiosRequest from './api';
import ConfigConstants from '../redux/config/constants';
import { getDeliveryPartition } from '../redux/config/selectors';
import ProfileConstants from '../redux/profile/constants';
import PublicUIConstants from '../redux/publicInterfaces/constants/types';
import { getMD5HashFromString } from '../utils';
import { getProfileInfo, getProfileUUID, isRootOrAdmin } from '../redux/profile/selectors';
import Reports54ActorsConstants from '../redux/reports54Actors/constants';
import { GET_MASQUERADE_USER_FAILURE, GET_MASQUERADE_USER_REQUEST, GET_MASQUERADE_USER_SUCCESS } from '../redux/masquerading/masqueradingSlice';
import {
  GET_MONTHLY_REPORTS_STORAGE_FAILURE,
  GET_MONTHLY_REPORTS_STORAGE_REQUEST,
  GET_MONTHLY_REPORTS_STORAGE_SUCCESS,
  GET_ROOT_USER_FAILURE,
  GET_ROOT_USER_REQUEST,
  GET_ROOT_USER_SUCCESS,
  GET_VACATION_STORAGE_FAILURE,
  GET_VACATION_STORAGE_REQUEST,
  GET_VACATION_STORAGE_SUCCESS,
  GET_EVENTS_STORAGE_REQUEST,
  GET_EVENTS_STORAGE_SUCCESS,
  GET_EVENTS_STORAGE_FAILURE,
} from '../redux/storages/storagesSlice';

const useAPI = () => {
  const dispatch = useDispatch();

  const myProfileInfo = useSelector(getProfileInfo);
  const isAdminOrRoot = useSelector(isRootOrAdmin);
  const rootPartitionUuid = useSelector(getDeliveryPartition);
  const actor = useSelector(getProfileUUID);

  function createEntityPermissions({
    data,
  }) {
    return new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'eperm/create',
          [
            'CREATE_ENTITY_PERM_REQUEST',
            'CREATE_ENTITY_PERM_SUCCESS',
            'CREATE_ENTITY_PERM_FAILURE',
          ],
          data,
          {
            partition: SERVICE_PARTITION_NAME,
            onSuccess,
            onFailure,
          },
        ),
      );
    });
  }

  function deleteEntityPerm({
    data,
  }) {
    return new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'eperm/delete',
          [
            'DELETE_ENTITY_PERMS_REQUEST',
            'DELETE_ENTITY_PERMS_SUCCESS',
            'DELETE_ENTITY_PERMS_FAILURE',
          ],
          data,
          {
            partition: SERVICE_PARTITION_NAME,
            onSuccess,
            onFailure,
          },
        ),
      );
    });
  }

  const checkEntityPerm = ({
    entity_uuid,
    perm = [],
    returnPermsList,
  }) => {
    const data = {
      entity_uuid,
      perm,
    };

    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'utility/eperm/check',
          [
            'CHECK_ENTITY_PERM_REQUEST',
            'CHECK_ENTITY_PERM_SUCCESS',
            'CHECK_ENTITY_PERM_FAILURE',
          ],
          data,
          {
            partition: 'DELIVERY',
            onSuccess: (response) => {
              const perms = omit(head(response), ['entity_uuid']);

              resolve(returnPermsList ? perms : perm.every((item) => perms[item] === true));
            },
            onFailure: reject,
          },
        ),
      );
    });
  };

  function getPermissionsOfEntity({
    data,
    constants,
  }) {
    return new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'eperm/get',
          constants || [
            'GET_ENTITY_PERMS_REQUEST',
            'GET_ENTITY_PERMS_SUCCESS',
            'GET_ENTITY_PERMS_FAILURE',
          ],
          data,
          {
            partition: SERVICE_PARTITION_NAME,
            onSuccess,
            onFailure,
          },
        ),
      );
    });
  }

  const requestGetPublicInterfaces = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'public_interfaces/bulk',
        [
          ConfigConstants.GET_PUBLIC_INTERFACES_REQUEST,
          ConfigConstants.GET_PUBLIC_INTERFACES_SUCCESS,
          ConfigConstants.GET_PUBLIC_INTERFACES_FAILURE,
        ],
        { service_domain: `${currentServiceUrl}/` },
        {
          service_domain: authServiceURL,
          service: 'auth',
        },
        {
          onSuccess: resolve,
          onFailure: reject,
          onCancel: reject,
        },
      ),
    );
  });
  const requestGetPartitions = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'partition/get',
        [
          ConfigConstants.GET_PARTITIONS_REQUEST,
          ConfigConstants.GET_PARTITIONS_SUCCESS,
          ConfigConstants.GET_PARTITIONS_FAILURE,
        ],
        {},
        {
          onSuccess: resolve,
          onFailure: reject,
          onCancel: reject,
        },
      ),
    );
  });

  const requestGetMyPermissions = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'permactions/actor/me',
        [
          ProfileConstants.GET_MY_PERMISSIONS_REQUEST,
          ProfileConstants.GET_MY_PERMISSIONS_SUCCESS,
          ProfileConstants.GET_MY_PERMISSIONS_FAILURE,
        ],
        {},
        {
          onSuccess: resolve,
          onFailure: reject,
          onCancel: reject,
        },
      ),
    );
  });

  const requestGetOpenInterfaces = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.get(
        'services_info/',
        [
          PublicUIConstants.GET_ALL_OPEN_UI_REQUEST,
          PublicUIConstants.GET_ALL_OPEN_UI_SUCCESS,
          PublicUIConstants.GET_ALL_OPEN_UI_FAILURE,
        ],
        {
          service: 'auth',
          onSuccess: resolve,
          onFailure: reject,
          onCancel: reject,
        },
      ),
    );
  });

  const getDefaultDepartment = async () => {
    if (isAdminOrRoot) {
      return dispatch(
        getOrCreateSingle({
          data: {
            entity_type: 'defaultDepartment',
            parent: rootPartitionUuid,
            key: getMD5HashFromString(`defaultDepartment${rootPartitionUuid}`),
          },
          constants: [
            'GET_OR_CREATE_DEFAULT_DEPARTMENT_REQUEST',
            'GET_OR_CREATE_DEFAULT_DEPARTMENT_SUCCESS',
            'GET_OR_CREATE_DEFAULT_DEPARTMENT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
          doNotCheckError: true,
        }),
      );
    }
    const res = await dispatch(
      getListOfEntities({
        data: {
          entity_type: 'defaultDepartment',
          parent: rootPartitionUuid,
          key: getMD5HashFromString(`defaultDepartment${rootPartitionUuid}`),
        },
        constants: [
          'LIST_DEFAULT_DEPARTMENT_REQUEST',
          'LIST_DEFAULT_DEPARTMENT_SUCCESS',
          'LIST_DEFAULT_DEPARTMENT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
        doNotCheckError: true,
      }),
    );

    return {
      uuid: head(res?.entity_uuids),
    };
  };

  const getRootUser = (actorUUID = '', parent = '') => dispatch(
    getOrCreateSingle({
      data: {
        parent: parent || rootPartitionUuid,
        entity_type: 'user',
        key: getMD5HashFromString(`user${rootPartitionUuid}${actorUUID || myProfileInfo?.uuid}`),
      },
      constants: [
        GET_ROOT_USER_REQUEST,
        GET_ROOT_USER_SUCCESS,
        GET_ROOT_USER_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const readRootUser = () => dispatch(
    getListAndReadEntities({
      data: {
        actor,
        depth: 0,
        entity_type: 'user',
        parent: rootPartitionUuid,
      },
      constants: [
        'READ_ROOT_USER_REQUEST',
        'READ_ROOT_USER_SUCCESS',
        'READ_ROOT_USER_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const updateUserEntity = (uuid, data, constants) => dispatch(
    entityUpdate({
      data: {
        entity_type: 'user',
        entity_uuid: uuid,
        ...data,
      },
      constants: constants || [
        'UPDATE_USER_REQUEST',
        'UPDATE_USER_SUCCESS',
        'UPDATE_USER_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getMasqueradeUser = (actorUUID = '') => dispatch(
    getOrCreateSingle({
      data: {
        actor: actorUUID,
        parent: rootPartitionUuid,
        entity_type: 'user',
        key: getMD5HashFromString(`user${rootPartitionUuid}${actorUUID}`),
      },
      constants: [
        GET_MASQUERADE_USER_REQUEST,
        GET_MASQUERADE_USER_SUCCESS,
        GET_MASQUERADE_USER_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const getDeliveryUsers = (options, constants) => dispatch(
    getListAndReadEntities({
      data: {
        parent: rootPartitionUuid,
        entity_type: 'user',
        ...options,
      },
      constants: constants || [
        Reports54ActorsConstants.FETCH_REPORTS_54_ACTORS_REQUEST,
        Reports54ActorsConstants.FETCH_REPORTS_54_ACTORS_SUCCESS,
        Reports54ActorsConstants.FETCH_REPORTS_54_ACTORS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getVacationStorage = (entityUUID, actorUuid, constants) => dispatch(
    getOrCreateSingle({
      data: {
        actor: actorUuid,
        parent: entityUUID,
        entity_type: 'vacationStorage',
      },
      constants: constants || [
        GET_VACATION_STORAGE_REQUEST,
        GET_VACATION_STORAGE_SUCCESS,
        GET_VACATION_STORAGE_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const getMonthlyReportsStorage = (entityUUID, actorUuid) => dispatch(
    getOrCreateSingle({
      data: {
        actor: actorUuid,
        parent: entityUUID,
        entity_type: 'monthlyReportsStorage',
      },
      constants: [
        GET_MONTHLY_REPORTS_STORAGE_REQUEST,
        GET_MONTHLY_REPORTS_STORAGE_SUCCESS,
        GET_MONTHLY_REPORTS_STORAGE_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const getEventsStorage = (entityUUID, actorUuid) => dispatch(
    getOrCreateSingle({
      data: {
        actor: actorUuid,
        parent: entityUUID,
        entity_type: 'eventsStorage',
      },
      constants: [
        GET_EVENTS_STORAGE_REQUEST,
        GET_EVENTS_STORAGE_SUCCESS,
        GET_EVENTS_STORAGE_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const updateDeliveryUser = (data) => dispatch(
    entityUpdate({
      data: {
        entity_type: 'user',
        ...data,
      },
      constants: [
        'UPDATE_DELIVERY_USER_REQUEST',
        'UPDATE_DELIVERY_USER_SUCCESS',
        'UPDATE_DELIVERY_USER_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  // Increment
  const incrementAndGetCount = (params, partition = 'SALES') => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'utility/entity/increment',
        ['', '', ''],
        params,
        {
          partition,
          onSuccess: resolve,
          onFailure: reject,
        },
      ),
    );
  });

  const updateMonthlyReport = async (options, constants) => dispatch(
    entityUpdate({
      data: options,
      constants: constants || [
        'UPDATE_MONTHLY_REPORT_REQUEST',
        'UPDATE_MONTHLY_REPORT_SUCCESS',
        'UPDATE_MONTHLY_REPORT_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const updateDayInMonthlyReport = async (entityUUID, newDays, constants) => dispatch(
    entityUpdate({
      data: {
        entity_uuid: entityUUID,
        entity_type: 'monthlyReport',
        params: {
          days: newDays,
        },
      },
      constants: constants || [
        'UPDATE_DAY_IN_MONTHLY_REPORT_REQUEST',
        'UPDATE_DAY_IN_MONTHLY_REPORT_SUCCESS',
        'UPDATE_DAY_IN_MONTHLY_REPORT_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getOrCreateMonthlyReport = (parentUUID, options, constants) => dispatch(
    getOrCreateSingle({
      data: {
        parent: parentUUID,
        entity_type: 'monthlyReport',
        with_files: false,
        key: getMD5HashFromString(`monthlyReport${parentUUID}${options?.params?.date}${options?.actor ?? myProfileInfo?.uuid}`),
        ...options,
      },
      constants: constants || [
        'GET_MONTHLY_REPORT_REQUEST',
        'GET_MONTHLY_REPORT_SUCCESS',
        'GET_MONTHLY_REPORT_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const getMonthlyReport = (parentUUID, options, constants) => dispatch(
    getListAndReadEntities({
      data: {
        parent: parentUUID,
        entity_type: 'monthlyReport',
        with_files: false,
        ...options,
      },
      constants: constants || [
        'GET_MONTHLY_REPORT_REQUEST',
        'GET_MONTHLY_REPORT_SUCCESS',
        'GET_MONTHLY_REPORT_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const getListOfMonthlyReports = (parentUUID, options) => dispatch(
    getListAndReadEntities({
      data: {
        parent: parentUUID,
        entity_type: 'monthlyReport',
        with_files: false,
        depth: 0,
        ...options,
      },
      constants: [
        'GET_LIST_OF_MONTHLY_REPORT_REQUEST',
        'GET_LIST_OF_MONTHLY_REPORT_SUCCESS',
        'GET_LIST_OF_MONTHLY_REPORT_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  return {
    checkEntityPerm,
    getRootUser,
    readRootUser,
    updateUserEntity,

    getMasqueradeUser,
    getDefaultDepartment,
    // DELIVERY USERS
    getDeliveryUsers,
    updateDeliveryUser,

    // STORAGES
    getVacationStorage,
    getMonthlyReportsStorage,
    getEventsStorage,

    requestGetPublicInterfaces,
    requestGetPartitions,
    requestGetMyPermissions,
    requestGetOpenInterfaces,

    incrementAndGetCount,

    // MONTHLY REPORTS FOR USER
    updateMonthlyReport,
    updateDayInMonthlyReport,
    getOrCreateMonthlyReport,
    getMonthlyReport,

    // MONTHLY REPORTS FOR ADMIN
    getListOfMonthlyReports,

    // PERMS
    getPermissionsOfEntity,
    createEntityPermissions,
    deleteEntityPerm,
  };
};

export default useAPI;
