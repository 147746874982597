import React from 'react';

import { Button, Result } from 'antd';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Content } from 'antd/es/layout/layout';
import {
  adminRoutes, departmentRoutes, hrRoutes, routes,
} from '../../routers/routes';

import { isRootOrAdmin } from '../../redux/profile/selectors';
import { getIsAvailableDepartmentPanel } from '../../redux/pages/pageAccessSelector';

export default function AppContent() {
  const isUserRootOrAdmin = useSelector(isRootOrAdmin);

  const isAvailableDepartmentPanel = useSelector(getIsAvailableDepartmentPanel);

  return (
    <Content className="absolute top-0 p-4 overflow-y-scroll h-full w-full">
      <Switch>
        <Route
          render={() => (
            <>
              {routes.map(({
                path, exact, component, key,
              }) => (
                <Route
                  key={key || path}
                  exact={exact || false}
                  path={path}
                  render={() => component}
                />
              ))}

              {isUserRootOrAdmin && adminRoutes.map(({
                path, exact, component, key,
              }) => (
                <Route
                  key={key || path}
                  exact={exact || false}
                  path={path}
                  render={() => component}
                />
              ))}
              {isUserRootOrAdmin && hrRoutes.map(({
                path, exact, component, key,
              }) => (
                <Route
                  key={key || path}
                  exact={exact || false}
                  path={path}
                  render={() => component}
                />
              ))}
              {(isUserRootOrAdmin || isAvailableDepartmentPanel) && departmentRoutes.map(({
                path, exact, component, key,
              }) => (
                <Route
                  key={key || path}
                  exact={exact || false}
                  path={path}
                  render={() => component}
                />
              ))}
            </>
          )}
        />
        <Route path="*">
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary">Back Home</Button>}
          />
        </Route>
      </Switch>
    </Content>
  );
}
