import React, { useEffect, useState } from 'react';
import {
  Button,
  Card, Divider, Flex, Input,
} from 'antd';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { get, head } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { eventSettings } from './utils/eventConstants';
import { getDeliveryPartition } from '../../redux/config/selectors';
import AssigningUserPopover from '../users/AssigningUserPopover';
import ListOfUsersHRM54 from '../users/ListOfUsersHRM54';
import ListOfCurrentUsers from '../users/ListOfCurrentUsers';
import { entityCreate, entityUpdate, getOrCreateSingle } from '../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../api/app_config';
import { getMD5HashFromString } from '../../utils';
import useAPI from '../../api/useAPI';

const { TextArea } = Input;

export default function CardCreateMetaevent({
  defaultValues,
  onClose,
  onCreate,
}) {
  const dispatch = useDispatch();

  const {
    createEntityPermissions,
  } = useAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [eventType, setEventType] = useState('');
  const [eventTitle, setEventTitle] = useState('');

  const [eventTextInfo, setEventTextInfo] = useState('');

  const [usersUUIDs, setUsersUUIDs] = useState([]);
  const [users, setUsers] = useState([]);

  const [metaeventsStorageUUID, setMetaeventsStorageUUID] = useState('');

  const settings = eventSettings?.find((el) => el?.value === eventType);

  const onUserSelect = (value) => {
    if (!usersUUIDs?.includes(value?.actor)) {
      setUsers((prev) => [...prev, value]);
      setUsersUUIDs((prev) => [...prev, value.actor]);
    } else {
      setUsers((prev) => prev.filter((user) => user.actor !== value?.actor));
      setUsersUUIDs((prev) => prev.filter((userUUID) => userUUID !== value?.actor));
    }
  };

  const onCreateMetaEvent = async () => {
    const newEvent = await dispatch(entityCreate({
      data: {
        parent: metaeventsStorageUUID,
        entity_type: settings?.entity_type,
        params: {
          title: eventTitle,
          textInfo: eventTextInfo,
        },
      },
      constants: [
        'CREATE_METAEVENT_REQUEST',
        'CREATE_METAEVENT_SUCCESS',
        'CREATE_METAEVENT_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }));

    if (head(newEvent)?.uuid) {
      const newUserPerms = users?.map((el) => {
        dispatch(entityUpdate({
          data: {
            entity_uuid: el?.value,
            params: {
              personal_metaevent_create_monthly_report: head(newEvent)?.uuid,
            },
          },
          constants: [
            'UPDATE_USER_REQUEST',
            'UPDATE_USER_SUCCESS',
            'UPDATE_USER_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }));
        return {
          actor: get(el, 'actor', ''),
          create: false,
          list: true,
          read: true,
          update: false,
          delete: false,
          set: false,
        };
      });

      createEntityPermissions({
        data: {
          entity_uuid: head(newEvent)?.uuid,
          data: newUserPerms,
        },
      });
    }
    if (onCreate) {
      onCreate?.();
    }
  };

  const initFunc = async () => {
    const res = await dispatch(getOrCreateSingle({
      data: {
        parent: partitionUUID,
        entity_type: 'metaeventsStorage',
        with_files: false,
        key: getMD5HashFromString(`metaeventStorage${partitionUUID}`),
      },
      constants: [
        'GET_OR_CREATE_METAEVENTS_STORAGE_REQUEST',
        'GET_OR_CREATE_METAEVENTS_STORAGE_SUCCESS',
        'GET_OR_CREATE_METAEVENTS_STORAGE_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }));

    setMetaeventsStorageUUID(res?.uuid);
  };

  useEffect(() => {
    setEventType(get(defaultValues, 'eventType', 'create_monthly_report'));
  }, [JSON.stringify(defaultValues)]);

  useEffect(() => {
    if (partitionUUID) {
      initFunc();
    }
  }, [partitionUUID]);

  return (
    <Card
      className="p-0 pb-2 mb-6 blue-base-card"
      title={(
        <Flex
          align="center"
          className="w-full"
        >
          <Flex
            align="center"
            justify="space-between"
            className="cursor-pointer w-full"
          >
            <span className="ml-3 text-xl">
              Конструктор метасобытия
            </span>
            <Icon path={mdiClose} size={0.9} onClick={onClose} className="cursor-pointer" />
          </Flex>
        </Flex>
      )}
    >
      <Flex
        className="mx-12 mt-2"
      >
        <span className="text-blue-500 text-base">Событие:</span>
        <span className="text-base ml-2">{settings?.title}</span>
      </Flex>
      <Divider className="mx-12 my-2" />
      <Flex
        align="center"
        className="mx-12 mt-2"
      >
        <span className="text-blue-500 text-base">Название:</span>
        <Input
          className="text-base ml-2"
          value={eventTitle}
          onChange={(e) => setEventTitle(e?.target?.value)}
        />
      </Flex>
      <Divider className="mx-12 my-2" />
      <Flex
        vertical
        justify=""
        className="mx-12 mt-2"
      >
        <span className="text-blue-500 text-base">Инструкция:</span>
        <TextArea
          row={4}
          className="text-base"
          value={eventTextInfo}
          onChange={(e) => setEventTextInfo(e?.target?.value)}
        />
      </Flex>
      <Divider className="my-2" />
      <Flex
        // align="center"
        className="w-full mx-12 mt-2"
      >
        <Flex
          vertical
        >
          <span className="text-blue-500 text-base">Все подрядчики:</span>
          <ListOfUsersHRM54
            parent={partitionUUID}
            selected={usersUUIDs}
            onUserSelect={onUserSelect}
          />
        </Flex>
        <Flex
          vertical
          className="ml-4"
        >
          <span className="text-blue-500 text-base">Применяется к:</span>
          <ListOfCurrentUsers
            users={users}
            onUserChange={onUserSelect}
          />
        </Flex>
      </Flex>
      <Flex
        className="mx-6 mt-4 mb-2"
        justify="end"
      >
        <Button
          type="primary"
          onClick={onCreateMetaEvent}
        >
          Создать
        </Button>
      </Flex>
    </Card>
  );
}
