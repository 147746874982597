import React from 'react';

import { Card, Flex, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import cn from 'classnames';
import { bool, string } from 'prop-types';
import useArtifacts from '../../redux/departments/hooks/useArtifacts';
import { getDeliveryPartition } from '../../redux/config/selectors';
import BaseTableWithPagination from '../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import useURLParams from '../../hooks/useURLParams';
import EmployeeLabel from '../commonComponents/entityCardComponents/EmployeeLabel';
import ContractorLabel from './commonComponents/ContractorLabel';
import { getProfileUUID } from '../../redux/profile/selectors';
import useDepartments from '../../redux/departments/hooks/useDepartments';
import useDefaultDepartment from '../../redux/departments/hooks/useDefaultDepartment';
import { getDefaultDepartmentUUID } from '../../redux/departments/selectors/defaultDepartmentSelector';
import AntDesignSearchBox54origins from '../../54origins/components/AntDesignSearchBox54origins';

export default function ListOfContractors({
  parent,
  cardTitle = 'Подрядчики',
  cardClassName = '',
  isDisabledOnRowMode = false,
}) {
  const partitionUUID = useSelector(getDeliveryPartition);
  const actorUUID = useSelector(getProfileUUID);

  const { setSearchParams, getURLParams } = useURLParams();

  const defaultDepartmentUUID = useSelector(getDefaultDepartmentUUID);

  const { activeArtifact } = getURLParams();

  const {
    departments,
    isFetching: isFetchingDepartments,
  } = useDepartments({
    defaultConfig: {
      depth: 0,
      limit: null,
    },
  });

  const {
    artifacts,
    isFetching: isFetchingArtifacts,
    paginationOptions,
    valueSearch,
    onChangeSearch,
    onReloadArtifacts,
  } = useArtifacts({
    parentUUID: parent || partitionUUID,
    defaultConfig: {
      entity_type: 'user',
      depth: 0,
      limit: 50,
    },
  });

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === activeArtifact,
  });

  const onRow = (record) => ({
    onClick: () => {
      if (isDisabledOnRowMode) return;

      const uuid = get(record, 'uuid', '');

      setSearchParams({ artifact: uuid });
    },
  });

  const columns = [
    {
      key: 'name',
      title: (
        <div className="flex items-center">
          <span className="mr-2">Имя</span>
          <AntDesignSearchBox54origins
            maxWidth={250}
            valueSearch={valueSearch}
            onSearch={onChangeSearch}
          />
        </div>
      ),
      dataIndex: ['uinfo'],
      width: '50%',
      render: (cell, row) => (
        <ContractorLabel
          user={row}
        />
      ),
    },
    {
      key: 'department',
      title: 'Отдел',
      dataIndex: ['parent'],
      width: '50%',
      render: (cell, row) => {
        const department = departments?.find((el) => el?.uuid === cell);
        if (department) {
          return <span className="text-blue-500">{department?.params?.title}</span>;
        }
        return <span className="text-red-500">Нет отдела</span>;
      },
    },
  ];

  return (
    <Card
      className={`w-full p-0 pb-3 h-max blue-base-card ${cardClassName}`}
      title={(
        <Flex vertical>
          <Flex
            className="text-xl"
          >
            {cardTitle}
          </Flex>
        </Flex>
    )}
    >
      <Flex className="px-2 w-full" vertical>
        <Spin
          className="w-full h-100"
          spinning={isFetchingDepartments || isFetchingArtifacts}
        >
          <BaseTableWithPagination
            columns={columns}
            data={artifacts}
            rowKey="uuid"
            size="small"
            className="w-full h-full !mb-3"
            onRow={onRow}
            pageSizeOptions={[20, 50, 100]}
            rowClassName={rowClassName}
            useCustomPagination
            disablePagination={false}
            {...paginationOptions}
          />
        </Spin>
      </Flex>
    </Card>
  );
}

ListOfContractors.propTypes = {
  cardTitle: string,
  cardClassName: string,
  isDisabledOnRowMode: bool,
};
