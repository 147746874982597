import {
  capitalize,
  get,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { array, func, object } from 'prop-types';
import cn from 'classnames';
import {
  Button, Col, Dropdown, Row, Table, Tag, Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiCalendarAlert, mdiCommentProcessing, mdiDotsVertical, mdiTrashCanOutline,
} from '@mdi/js';
import { getFetchingReports54Actors, getReports54Actors, getReports54ActorsTotal } from '../../../../redux/reports54Actors/selectors';
import { getInitialsNameOfActor, getNameForActor, stopPropagation } from '../../../../54origins/utils54origins';
import { capitalizeFirstChar } from '../../../../utils';

import 'dayjs/locale/ru';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import CircleActorAvatar54origins from '../../../../54origins/components/CircleActorAvatar54origins';
import { getDayjsDateFromString } from '../../../user/dayOff/utils';
import { getAdminGroupUUID, getBanGroupUUID, getGroups } from '../../../../redux/groups/selectors';
import SwitchShowReportsToActor from './SwitchShowReportsToActor';
import CustomTablePagination from '../../../../components/commonComponents/customTablePagination/CustomTablePagination';
import { translateTypeDay } from '../../../../components/days/utils/utils';

function DayUserReportUsersTable({
  defaultDataTable = [],
  setConfig,
  config,
}) {
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);

  const banGroupUUID = useSelector(getBanGroupUUID);
  const adminGroupUUID = useSelector(getAdminGroupUUID);
  const totalActors = useSelector(getReports54ActorsTotal);

  const [dataTable, setDataTable] = useState(defaultDataTable);

  const searchInTitle = (
    <Col className="d-flex align-item-end minWidth130" span={24}>
      <AntDesignSearchBox54origins
        onSearch={(value) => setConfig((prev) => ({
          ...prev,
          search_data: {
            fields: {
              uinfo: {
                first_name: 'first_name',
                last_name: 'last_name',
              },
            },
            value,
          },
        }))}
      />
    </Col>
  );

  const onHeaderRow = () => ({
    className: 'universal_header_table_row color-gray',
  });

  const onSizePerPageTable = (prevSize, newSize) => {
    setCurrentPage(1);
    setPageSize(newSize);
    setConfig((prev) => ({
      ...prev,
      offset: 0,
      limit: newSize,
    }));
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
    setConfig((prev) => ({
      ...prev,
      offset: (page - 1) * pageSize,
      limit: pageSize,
    }));
  };

  const columns = [
    {
      dataIndex: 'actor',
      key: 'onWorkPlace',
      width: 30,
      title: 'Online',
      render: (cell, row) => {
        const finishWork = get(row, ['day', 'finishWork'], '-');
        const startWork = get(row, ['day', 'startWork'], '-');
        let isOnline = false;

        if (finishWork !== '-' && startWork !== '-') {
          isOnline = dayjs().isSameOrBefore(getDayjsDateFromString(finishWork))
          && dayjs().isSameOrAfter(getDayjsDateFromString(startWork));
        }

        return (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              width: '45px',
            }}
          >
            <CircleActorAvatar54origins
              actorUUID={row?.actor}
              name={getInitialsNameOfActor(row)}
              size="large"
            />
            <div className={cn('wrapper-is-online', { green: isOnline, red: !isOnline })} />
          </div>
        );
      },
      sorter: (a, b) => {
        const finishWorkA = get(a, ['day', 'finishWork'], '-');
        const startWorkA = get(a, ['day', 'startWork'], '-');
        const finishWorkB = get(b, ['day', 'finishWork'], '-');
        const startWorkB = get(b, ['day', 'startWork'], '-');

        let isOnlineA = false;
        let isOnlineB = false;

        if (finishWorkA !== '-' && startWorkA !== '-') {
          isOnlineA = dayjs().isSameOrBefore(getDayjsDateFromString(finishWorkA))
          && dayjs().isSameOrAfter(getDayjsDateFromString(startWorkA));
        }

        if (finishWorkB !== '-' && startWorkB !== '-') {
          isOnlineB = dayjs().isSameOrBefore(getDayjsDateFromString(finishWorkB))
          && dayjs().isSameOrAfter(getDayjsDateFromString(startWorkB));
        }

        if (!isOnlineA && isOnlineB) return -1;
        if (isOnlineA && !isOnlineB) return 1;

        return 0;
      },
    },
    {
      dataIndex: 'actor_type',
      title: searchInTitle,
      width: 200,
      render: (cell, row) => (
        <Col style={{ width: 240 }}>
          {getNameForActor(row)}
          <div className="d-flex align-items-center">

            <Tag
              color="blue"
              className="fontSize10 mr-1"
            >
              {capitalize(row?.actor_type === 'classic_user' ? 'classic' : row?.actor_type)}
            </Tag>

            <Tag
              color="orange"
              className="fontSize10 mr-1"
            >
              {row?.uinfo?.internal_user ? 'Internal' : 'External'}
            </Tag>

            {row?.uinfo?.groups?.includes(banGroupUUID) && (
              <Tag
                color="orange"
                className="fontSize10 mr-1"
              >
                BANNED
              </Tag>
            )}
            {row?.uinfo?.groups?.includes(adminGroupUUID) && (
              <Tag
                color="red"
                className="fontSize10"
              >
                ADMIN
              </Tag>
            )}
          </div>
        </Col>
      ),
    },
    {
      dataIndex: ['actor'],
      key: 'showReports',
      title: 'Настройки',
      render: (_, row) => {
        const {
          uuid,
          params: {
            showBaseReport,
            showLunchBreak,
          } = {},
        } = row;

        return (
          <SwitchShowReportsToActor
            actorUUID={uuid}
            defaultShowBaseReport={showBaseReport}
            defaultShowLunchBreak={showLunchBreak}
          />
        );
      },
    },
    {
      dataIndex: ['day', 'startWork'],
      key: 'startWork',
      title: 'Начало работы',
      sorter: (a, b) => {
        if (!a?.day?.startWork) {
          return -1;
        }
        if (!b?.day?.startWork) {
          return 1;
        }
        return getDayjsDateFromString(a?.day?.startWork).unix()
        - getDayjsDateFromString(b?.day?.startWork).unix();
      },
      render: (cell) => (
        <div
          className={cell && 'desiredTimeInAllReports'}
        >
          {cell || '-'}
        </div>
      ),
    },
    {
      dataIndex: ['day', 'finishWork'],
      key: 'finishWork',
      title: 'Завершение работы',
      sorter: (a, b) => {
        if (!a?.day?.finishWork) {
          return -1;
        }
        if (!b?.day?.finishWork) {
          return 1;
        }
        return getDayjsDateFromString(a?.day?.finishWork).unix()
        - getDayjsDateFromString(b?.day?.finishWork).unix();
      },
      render: (cell) => (
        <div
          className={cell && 'desiredTimeInAllReports'}
        >
          {cell || '-'}
        </div>
      ),
    },
    {
      dataIndex: ['day', 'lunchBreak'],
      key: 'lunchBreak',
      title: 'Перерыв на обед',
      sorter: (a, b) => {
        if (!a?.day?.lunchBreak) {
          return -1;
        }
        if (!b?.day?.lunchBreak) {
          return 1;
        }
        return getDayjsDateFromString(a?.day?.lunchBreak).unix()
        - getDayjsDateFromString(b?.day?.lunchBreak).unix();
      },
      render: (cell) => (
        <div
          className={cell && 'desiredTimeInAllReports'}
        >
          {cell || '-'}
        </div>
      ),
    },
    {
      dataIndex: ['day', 'comment'],
      key: 'comment',
      title: 'План работы',
      render: (cell = '') => (cell ? (
        <div className="comment-wrapper">
          <Tooltip title={cell} color="#0958d9">
            <Icon path={mdiCommentProcessing} size={1.5} color="#0958d9" />
          </Tooltip>
        </div>
      ) : '-'),
    },
    {
      dataIndex: ['day', 'type'],
      title: 'Тип дня',
      key: 'type',
      render: (cell = '') => (
        <div>
          {cell ? translateTypeDay[cell] : '-'}
        </div>
      ),
    },
    // {
    //   dataIndex: 'actor',
    //   title: 'Вопросы',
    //   key: 'extraQuestions',
    //   render: (cell, row) => {
    //     const haveWorkQuestions = get(row, ['day', 'haveWorkQuestions'], false);
    //     const dontHaveEnoughTasks = get(row, ['day', 'dontHaveEnoughTasks'], false);

    //     const haveANotification = haveWorkQuestions || dontHaveEnoughTasks;
    //     const haveBothNotifications = haveWorkQuestions && dontHaveEnoughTasks;

    //     const templateMsg = (
    //       <>
    //         <p>{haveWorkQuestions ? 'У меня есть рабочие вопросы' : ''}</p>
    //         <p>{dontHaveEnoughTasks ? 'У меня недостаточно заданий' : ''}</p>
    //       </>
    //     );

    //     return (
    //       haveANotification ? (
    //         <Tooltip
    //           placement="left"
    //           title={templateMsg}
    //           color={haveBothNotifications ? '#ff0000' : '#f89451cb'}
    //         >
    //           <Icon color={haveBothNotifications ? '#ff0000' : '#f89451cb'} size={1} path={mdiCalendarAlert} />
    //         </Tooltip>
    //       ) : '-'
    //     );
    //   },
    // },
    {
      dataIndex: ['day', 'created'],
      key: 'dayCreated',
      title: 'Дата создания',
      sorter: (a, b) => {
        if (!a?.day?.created) {
          return -1;
        }
        if (!b?.day?.created) {
          return 1;
        }
        return getDayjsDateFromString(b?.day?.created).unix()
        - getDayjsDateFromString(a?.day?.created).unix();
      },
      render: (cell) => (cell ? dayjs(cell).format('DD MMM YY, HH:mm') : '-'),
    },
  ];

  useEffect(() => {
    setDataTable(defaultDataTable);
  }, [JSON.stringify(defaultDataTable)]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataTable}
        onHeaderRow={onHeaderRow}
        pagination={false}
      />
      <CustomTablePagination
        onShowSizeChange={onSizePerPageTable}
        pageSizeOptions={['25', '50', '100', '200']}
        onChangePage={onChangePagination}
        currentPage={currentPage}
        total={totalActors || 0}
        pageLimit={pageSize}
      />
    </>

  );
}

export default DayUserReportUsersTable;

DayUserReportUsersTable.propTypes = {
  defaultDataTable: array,
  setConfig: func,
  config: object,
};
