import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get, omit } from 'lodash';
import { getDeliveryPartition } from '../../config/selectors';
import {
  getAllDepartmentsEmployeeRequestsList, getDepartments, getIsFetchingEmployeeRequest, getTotalRequests,
} from '../employeeRequestSelector';
import useEmployeeRequestAPI from '../../../api/useEmployeeRequestAPI';

function useEmployeeRequests({
  defaultConfig = {},
} = {}) {
  const { getAllDepartmentsRequestsList, getDepartmentsList } = useEmployeeRequestAPI();

  const partitionUuid = useSelector(getDeliveryPartition);
  const isFetching = useSelector(getIsFetchingEmployeeRequest);
  const total = useSelector(getTotalRequests);
  const allDepartmentsEmployeeRequestsList = useSelector(getAllDepartmentsEmployeeRequestsList);
  const departmentsList = useSelector(getDepartments);

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    offset: 0,
    limit: 10,
  });

  useEffect(() => {
    setConfig((prev) => ({ ...prev, ...defaultConfig }));
  }, [JSON.stringify(defaultConfig)]);

  useEffect(() => {
    const newConfig = {
      depth: 0,
      ...config,
    };
    getAllDepartmentsRequestsList(partitionUuid, newConfig);
  }, [JSON.stringify(config)]);

  useEffect(() => {
    if (allDepartmentsEmployeeRequestsList.length) {
      const UUIDs = [...new Set(allDepartmentsEmployeeRequestsList.map((item) => item.parent))];
      getDepartmentsList(config?.parent || partitionUuid, { entity_uuids: UUIDs });
    }
  }, [allDepartmentsEmployeeRequestsList]);

  return {
    allDepartmentsEmployeeRequestsList,
    departmentsList,
    isFetching,
    paginationOptions: {
      total,
      pageLimit: get(config, 'limit', 10),
      changePageLimit: (newLimit) => {
        setCurrentPage(1);
        setConfig((prev) => ({ ...prev, offset: 0, limit: newLimit }));
      },
      newCurrentPage: currentPage,
      onChangePageCallback: (newPage) => {
        setCurrentPage(newPage);
        setConfig((prev) => ({ ...prev, offset: (newPage - 1) * prev.limit }));
      },
      getPaginationOptions: (newPart) => {
        const valueSearch = get(newPart, 'search', '');

        if (valueSearch) {
          setConfig((prev) => ({
            ...prev,
            ...omit(newPart, ['search']),
            searchData: {
              value: valueSearch,
              ignore_case: true,
              fields: { params: ['title', 'id'] },
            },
          }));
        } else {
          setConfig((prev) => ({ ...omit(prev, 'searchData'), newPart }));
        }
      },
    },
  };
}

export default useEmployeeRequests;
