import React, { useEffect, useMemo, useState } from 'react';

import { get, head, trim } from 'lodash';
import { func, string } from 'prop-types';

import {
  AutoSizer, List,
} from 'react-virtualized';
import {
  Button, Divider, Flex, Input, Popover,
  Tooltip,
} from 'antd';

import Icon from '@mdi/react';
import { mdiClose, mdiPencil } from '@mdi/js';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import EditButton from './EditButton';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import { entityRead } from '../../../api/actions/entity';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';

export default function EntityDepartmentForArtifact({
  department,
  artifactsStorage,
  className = '',
  onEditCallback,
}) {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [isEdit, setIsEdit] = useState(false);
  const [valueSearch, onChangeSearch] = useState('');

  const [departments, setDepartments] = useState([]);
  const [currentDepartment, setCurrentDepartment] = useState({});

  const {
    getListOfDepartments,
  } = useDepartmentsAPI();

  const departmentUUID = department;
  const departmentLabel = get(currentDepartment, ['params', 'title'], '');

  const listOfDepartments = useMemo(() => departments
    .filter((dep) => dep?.params?.title?.toLowerCase()
      .includes(valueSearch.toLowerCase())), [JSON.stringify(departments), valueSearch]);

  const toggleBtn = () => setIsEdit((prev) => !prev);

  const onChangeValue = (value) => {
    if (department !== value?.uuid) {
      onEditCallback({ department: value?.uuid }, { parent: value?.uuid }, { fullReload: true });
    } else {
      onEditCallback({ department: '' }, { parent: artifactsStorage }, { fullReload: true });
    }
  };

  const rowRenderer = ({ index, style }) => {
    const {
      uuid = '',
      params: {
        title = '',
      },
    } = listOfDepartments?.[index];

    return (
      <Flex
        align="center"
        key={uuid}
        style={{
          ...style,
          // borderTop: +index === 0 ? '1px solid #DCDCDC' : '',
          borderBottom: '1px solid #DCDCDC',
        }}
        className={cn('px-4 cursor-pointer', {
          'bg-sky-200': uuid === departmentUUID,
          'hover:bg-gray-100': uuid !== departmentUUID,
        })}
        onClick={() => onChangeValue({
          uuid,
          label: title,
        })}
      >
        {title.length > 20 ? `${title.slice(0, 20)}...` : (
          <span
            style={{
              maxWidth: 380,
            }}
          >
            {title}
          </span>
        )}
      </Flex>
    );
  };

  const initFunc = async () => {
    const res = await getListOfDepartments(partitionUUID, {}, [
      'GET_DEPARTMENTS_FOR_SELECT_REQUEST',
      'GET_DEPARTMENTS_FOR_SELECT_SUCCESS',
      'GET_DEPARTMENTS_FOR_SELECT_FAILURE',
    ]);
    if (res) {
      setDepartments(res?.data);
    }
  };

  const initCurrentDepartmentFunc = async () => {
    const res = await dispatch(entityRead({
      data: {
        entity_uuid: department,
        parent: partitionUUID,
      },
      constants: [
        'GET_DEPARTMENT_FOR_SELECT_REQUEST',
        'GET_DEPARTMENT_FOR_SELECT_SUCCESS',
        'GET_DEPARTMENT_FOR_SELECT_FAILURE',
      ],
    }));
    if (res) {
      setCurrentDepartment(head(res));
    }
  };

  useEffect(() => {
    if (isEdit) {
      initFunc();
    }
  }, [isEdit]);

  useEffect(() => {
    if (department) {
      initCurrentDepartmentFunc();
    } else {
      setCurrentDepartment({});
    }
  }, [JSON.stringify(department)]);

  return (
    <Popover
      open={isEdit}
      placement="leftBottom"
      trigger="click"
      destroyTooltipOnHide
      onClick={toggleBtn}
      title={(
        <Flex
          vertical
          className="pt-2 px-2"
        >
          <Flex
            className="mb-1"
            justify="space-between"
            align="center"
          >
            <span className="text-lg">Отдел</span>
            <Icon
              className="cursor-pointer"
              onClick={toggleBtn}
              path={mdiClose}
              size={1}
            />
          </Flex>
          <Flex
            className="ml-1"
          >
            <AntDesignSearchBox54origins
              maxWidth={238}
              valueSearch={valueSearch}
              onSearch={onChangeSearch}
            />
          </Flex>
        </Flex>
      )}
      overlayInnerStyle={{
        padding: 0,
      }}
      content={(
        <AutoSizer
          style={{
            width: 238,
            height: 300,
            border: '1px solid #DCDCDC',
          }}
          className="!p-0 -mt-4"
        >
          {({ width, height }) => (
            <List
              className="w-full h-full !p-0 !pb-1"
              height={300}
              rowHeight={50}
              width={238}
              rowCount={listOfDepartments?.length || 0}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      )}
    >
      <Flex
        align="center"
        className={className}
      >
        <span
          className="mr-2 text-base"
        >
          Отдел:
        </span>
        <Button
          size="small"
          shape="default"
          className={cn('!w-7 mr-2 flex items-center justify-center !h-7 p-0 !rounded-full border-white text-blue-500 hover:!border-blue-500', {})}
        >
          <Flex>
            <Icon path={mdiPencil} size={0.85} />
          </Flex>
        </Button>
        {departmentLabel
          ? (
            <span
              className="text-blue-500"
            >
              {departmentLabel?.length > 35 ? (
                <Tooltip
                  title={departmentLabel}
                >
                  {`${departmentLabel?.slice(0, 35)}...`}
                </Tooltip>
              ) : departmentLabel }
            </span>
          ) : (
            <span
              className="text-gray-500"
            >
              Нет отдела
            </span>
          )}
      </Flex>
    </Popover>
  );
}

EntityDepartmentForArtifact.propTypes = {
  department: string,
  className: string,
  artifactsStorage: string,
  onEditCallback: func,
};
