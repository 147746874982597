import React, { useEffect, useState } from 'react';
import {
  List, Tag, Badge, Button, Modal, Form, Input, Select,
  Spin,
} from 'antd';
import {
  CalendarOutlined,
  MedicineBoxOutlined,
  ClockCircleOutlined,
  FileTextOutlined,
  UserAddOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlayCircleOutlined,
  FormOutlined,
  AuditOutlined,
  ScheduleOutlined,
  FileAddOutlined,
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  CheckCircleFilled,
  ThunderboltOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import './EventsList.css';
import { useSelector } from 'react-redux';
import PageWrapper from '../../components/PageWrapper';
import CreateMetaEventModal from '../../components/metaevents/create/CreateMetaEventModal';
import useMetaeventStorage from '../../api/useMetaeventStorage';
import useMetaeventsAPI from '../../api/useMetaeventsAPI';
import { getMetaeventStorageUUID } from '../../redux/metaeventStorage/metaeventStorageSelectors';
import EditMetaEventModal from '../../components/metaevents/edit/EditMetaEventModal';

const TRIGGER_SECTIONS = {
  vacation: {
    title: 'Отпуск',
    triggers: [
      {
        id: '1',
        title: 'Создание заявки на отпуск',
        value: 'create-vacation-request',
        icon: <FormOutlined />,
        color: '#1890ff',
        description: 'Инициация процесса оформления отпуска сотрудником',
      },
      {
        id: '2',
        title: 'Предварительное согласование отпуска',
        value: 'pre-approve-vacation-request',
        icon: <AuditOutlined />,
        color: '#faad14',
        description: 'Первичное рассмотрение заявки руководителем',
      },
      {
        id: '3',
        title: 'Утверждение отпуска',
        value: 'approve-vacation-request',
        icon: <CheckCircleOutlined />,
        color: '#52c41a',
        description: 'Финальное утверждение отпуска',
      },
      {
        id: '4',
        title: 'Отклонение отпуска',
        value: 'reject-vacation-request',
        icon: <CloseCircleOutlined />,
        color: '#f5222d',
        description: 'Отказ в предоставлении отпуска',
      },
      {
        id: '5',
        title: 'Две недели до отпуска',
        value: 'two-weeks-before-vacation',
        icon: <CalendarOutlined />,
        color: '#13c2c2',
        description: 'Автоматические действия за две недели до отпуска',
      },
      {
        id: '6',
        title: 'Начало отпуска',
        value: 'vacation-start',
        icon: <PlayCircleOutlined />,
        color: '#1890ff',
        description: 'Автоматические действия в день начала отпуска',
      },
    ],
  },
  sickLeave: {
    title: 'Больничный',
    triggers: [
      {
        id: '10',
        title: 'Создание больничного',
        value: 'create-sick-leave',
        icon: <MedicineBoxOutlined />,
        color: '#f5222d',
        description: 'Ргистрация периода нетрудоспособности',
      },
    ],
  },
  dayOff: {
    title: 'Отгул',
    triggers: [
      {
        id: '7',
        title: 'Запрос на отгул',
        value: 'create-day-off',
        icon: <ScheduleOutlined />,
        color: '#722ed1',
        description: 'Создание заявки на отгул',
      },
      {
        id: '8',
        title: 'Утверждение отгула',
        value: 'approve-day-off',
        icon: <CheckCircleOutlined />,
        color: '#722ed1',
        description: 'Согласование отгула руководителем',
      },
      {
        id: '9',
        title: 'Начало отгула',
        value: 'day-off-start',
        icon: <PlayCircleOutlined />,
        color: '#722ed1',
        description: 'Автоматические действия в день начала отгула',
      },
    ],
  },
  dailyReport: {
    title: 'Дневной отчет',
    triggers: [
      {
        id: '12',
        title: 'Создание дневного отчета',
        value: 'create-daily-report',
        icon: <FileAddOutlined />,
        color: '#52c41a',
        description: 'Ежедневная отчетность по выполненным работам',
      },
    ],
  },
  monthlyReport: {
    title: 'Месячный отчет',
    triggers: [
      {
        id: '11',
        title: 'Отправка месячного отчета',
        value: 'create-monthly-report',
        icon: <FileTextOutlined />,
        color: '#52c41a',
        description: 'Формирование и отправка ежемесячного отчета',
      },
    ],
  },
  contractors: {
    title: 'Подрядчики',
    triggers: [
      {
        id: '14',
        title: 'Увольнение подрядчика',
        value: 'terminate-contractor',
        icon: <CloseCircleOutlined />,
        color: '#f5222d',
        description: 'Процесс завершения работы с подрядчиком',
      },
    ],
  },
  onboarding: {
    title: 'Онбординг',
    triggers: [
      {
        id: '15',
        title: 'Начало онбординга',
        value: 'start-onboarding',
        icon: <UserAddOutlined />,
        color: '#059669',
        description: 'Процесс адаптации нового сотрудника',
      },
      {
        id: '16',
        title: 'Завершение онбординга',
        value: 'complete-onboarding',
        icon: <CheckCircleOutlined />,
        color: '#059669',
        description: 'Завершение процесса адаптации сотрудника',
      },
    ],
  },
};

function EventsList() {
  useMetaeventStorage();

  const {
    createMetaevent, getListOfMetaevents, deleteMetaevent, updateMetaevent,
  } = useMetaeventsAPI();

  const metaeventStorageUUID = useSelector(getMetaeventStorageUUID);

  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [metaevents, setMetaevents] = useState([]);

  const [form] = Form.useForm();

  const [searchQuery, setSearchQuery] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [collapsedSections, setCollapsedSections] = useState({});

  const [editingMetaEvent, setEditingMetaEvent] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const toggleSection = (sectionKey) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [sectionKey]: !prev[sectionKey],
    }));
  };

  const initMetaevents = async () => {
    if (selectedTrigger?.value && metaeventStorageUUID) {
      setIsLoading(true);
      try {
        const res = await getListOfMetaevents(metaeventStorageUUID, {
          entity_type: 'metaevent',
          params: {
            trigger: selectedTrigger.value,
          },
        });
        const newMetaevents = res?.data?.map((event, index) => ({
          ...event,
          isActive: index === 0,
        }));
        setMetaevents(newMetaevents || []);
      } catch (error) {
        console.error('Error loading metaevents:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCreateMetaEvent = async (values) => {
    await createMetaevent(metaeventStorageUUID, {
      ...values,
      entity_type: 'metaevent',
      params: {
        ...values,
        trigger: selectedTrigger.value,
      },
    });
    initMetaevents();
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleTriggerSelect = (trigger) => {
    setMetaevents([]);
    setSelectedTrigger(trigger);
  };

  useEffect(() => {
    if (metaeventStorageUUID) {
      initMetaevents();
    }
  }, [selectedTrigger, metaeventStorageUUID]);

  const filteredTriggers = Object.entries(TRIGGER_SECTIONS).reduce((acc, [key, section]) => {
    const filteredSection = {
      ...section,
      triggers: section.triggers.filter(
        (trigger) => trigger.title.toLowerCase().includes(searchQuery.toLowerCase())
          || trigger.description.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    };
    if (filteredSection.triggers.length > 0) {
      acc[key] = filteredSection;
    }
    return acc;
  }, {});

  const totalTriggers = Object.values(TRIGGER_SECTIONS)
    .reduce((sum, section) => sum + section.triggers.length, 0);

  const handleDeleteMetaEvent = (metaEvent) => {
    Modal.confirm({
      title: 'Удаление метасобытия',
      content: 'Вы уверены, что хотите удалить это метасобытие?',
      okText: 'Удалить',
      cancelText: 'Отмена',
      okButtonProps: { danger: true },
      onOk: async () => {
        await deleteMetaevent(metaEvent.uuid);
        initMetaevents();
      },
    });
  };

  const handleEditMetaEvent = (metaEvent) => {
    setEditingMetaEvent(metaEvent);
    setIsEditModalVisible(true);
  };

  const handleUpdateMetaEvent = async (values) => {
    await updateMetaevent(editingMetaEvent.uuid, {
      params: {
        ...values,
      },
    });
    initMetaevents();
    setEditingMetaEvent(null);
    setIsEditModalVisible(false);
  };

  const handleViewMetaEvent = (metaEvent) => {
    console.log('Просмотр метасобытия:', metaEvent);
  };

  const handleMakeActive = (metaEvent) => {
    Modal.confirm({
      title: 'Изменение активного метасобытия',
      content: 'Вы уверены, что хотите сделать это метасобытие активным? Предыдущее активное метасобытие станет неактивным.',
      okText: 'Да, сделать активным',
      cancelText: 'Отмена',
      onOk: () => {
        const updatedMetaevents = metaevents.map((event) => ({
          ...event,
          isActive: event.uuid === metaEvent.uuid,
        }));
        setMetaevents(updatedMetaevents);
      },
    });
  };

  return (
    <div className="events-panel mt-2">
      <div className="events-types glass-effect">
        <div className="section-header">
          <div className="header-title">
            <span className="text-2xl font-semibold">Триггеры</span>
            <span className="event-count">
              {totalTriggers}
              {' '}
              триггеров
            </span>
          </div>
        </div>
        {/* <div className="search-box">
            <Input
              prefix={<SearchOutlined style={{ color: '#718096' }} />}
              placeholder="Поиск триггеров..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              allowClear
            />
          </div> */}
        <div className="events-list">
          {Object.entries(filteredTriggers).map(([key, section]) => (
            <div key={key} className="trigger-section">
              <div className="trigger-section-header">
                {section.title}
                <div
                  className={`collapse-button ${collapsedSections[key] ? 'collapsed' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSection(key);
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      toggleSection(key);
                    }
                  }}
                >
                  <CaretDownOutlined />
                </div>
              </div>
              <div className={`trigger-section-content ${collapsedSections[key] ? 'collapsed' : ''}`}>
                {section.triggers.map((trigger) => (
                  <div
                    key={trigger.id}
                    className={`event-card ${selectedTrigger?.id === trigger.id ? 'selected-card' : ''}`}
                    onClick={() => handleTriggerSelect(trigger)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleTriggerSelect(trigger);
                      }
                    }}
                  >
                    <div
                      className="event-card-icon"
                      style={{
                        backgroundColor: `${trigger.color}15`,
                        color: trigger.color,
                      }}
                    >
                      {trigger.icon}
                    </div>
                    <div className="event-card-content">
                      <h3>{trigger.title}</h3>
                      <p>{trigger.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="meta-events glass-effect">
        <div className="section-header">
          <div className="header-title">
            <span className="text-2xl font-semibold">Метасобытия</span>
            {selectedTrigger && (
            <>
              <span className="header-subtitle">
                "
                {selectedTrigger.title}
                "
              </span>
              <span className="event-count">
                {metaevents?.length || 0}
                {' '}
                метасобытий
              </span>
            </>
            )}
          </div>
          {/* <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}
            disabled={!selectedTrigger}
            className="add-button"
          >
            Добавить
          </Button> */}
        </div>
        {selectedTrigger ? (
          <Spin spinning={isLoading}>
            <List
              dataSource={metaevents}
              renderItem={(metaEvent) => (
                <div className={`meta-event-card mb-4 ${metaEvent.isActive ? 'active' : ''}`}>
                  <div className="meta-event-header">
                    <div className="title-row">
                      <div className="title-section">
                        {metaEvent.isActive && (
                        <div className="active-indicator">
                          <CheckCircleFilled />
                          Активно
                        </div>
                        )}
                        <h3>{metaEvent.params.title}</h3>
                      </div>
                      <Tag color={selectedTrigger.color}>
                        {selectedTrigger.title}
                      </Tag>
                    </div>
                  </div>
                  <div className="meta-event-description-label">
                    Инструкция:
                  </div>
                  <p className="meta-event-description">
                    {metaEvent.params.instruction}
                  </p>
                  <div className="meta-event-footer">
                    <div className="date-info">
                      <ClockCircleOutlined />
                      <span>
                        Создано:
                        {' '}
                        {new Date(metaEvent.created).toLocaleDateString('ru-RU', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </span>
                      {metaEvent.modified !== metaEvent.created && (
                      <>
                        <span>•</span>
                        <span>
                          Обновлено:
                          {' '}
                          {new Date(metaEvent.modified).toLocaleDateString('ru-RU', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })}
                        </span>
                      </>
                      )}
                    </div>
                    <div className="meta-event-actions">
                      <Button
                        type="text"
                        icon={<EyeOutlined />}
                        onClick={() => handleViewMetaEvent(metaEvent)}
                      >
                        Подробнее
                      </Button>
                      <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={() => handleEditMetaEvent(metaEvent)}
                      >
                        Редактировать
                      </Button>
                      {!metaEvent.isActive && (
                      <Button
                        type="text"
                        icon={<ThunderboltOutlined />}
                        onClick={() => handleMakeActive(metaEvent)}
                        className="make-active-btn"
                      >
                        Сделать активным
                      </Button>
                      )}
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        className="delete-btn"
                        onClick={() => handleDeleteMetaEvent(metaEvent)}
                      >
                        Удалить
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            />
          </Spin>
        ) : (
          <div className="empty-state">
            <FileTextOutlined className="empty-icon" />
            <p>Выберите тип события слева для просмотра метасобытий</p>
          </div>
        )}
      </div>
      {/* <CreateMetaEventModal
        isVisible={isModalVisible}
        trigger={selectedTrigger}
        onCancel={() => setIsModalVisible(false)}
        onSubmit={handleCreateMetaEvent}
      /> */}
      {/* <EditMetaEventModal
        isVisible={isEditModalVisible}
        trigger={selectedTrigger}
        metaEvent={editingMetaEvent}
        onCancel={() => {
          setIsEditModalVisible(false);
          setEditingMetaEvent(null);
        }}
        onSubmit={handleUpdateMetaEvent}
      /> */}
    </div>
  );
}

export default EventsList;
