import React from 'react';

import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { array, func, string } from 'prop-types';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import useAPI from '../../../api/useAPI';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';

export default function CountDepartmentKPIButton({
  departments = [],
  buttonClassName = '',
  onCountKPICallback,
}) {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);

  const {
    getMonthlyReport,
  } = useAPI();

  const {
    updateDepartment,
  } = useDepartmentsAPI();

  const initMonthlyReports = async (departmentUUID) => {
    const res = await getMonthlyReport(departmentUUID, {
      depth: 0,
      params: {
        date: [dayjs().subtract(1, 'month').format('YYYY-MM')],
      },
    });

    const reports = res?.data ?? [];

    const newData = reports?.reduce((acc, report) => {
      acc.internalHours += +get(report, 'params.statistics.internalWork', 0);
      acc.clientHours += +get(report, 'params.statistics.clientWork', 0);
      acc.total += +get(report, 'params.statistics.totalWorks', 0);
      return acc;
    }, {
      month: dayjs().subtract(1, 'month').format('YYYY-MM'),
      total: 0,
      internalHours: 0,
      clientHours: 0,
    });
    if (!newData?.internalHours || !newData?.clientHours) return 0;

    return updateDepartment(departmentUUID, {
      parent: partitionUUID,
      params: {
        project_kpi: !newData?.internalHours
        || !newData?.clientHours
          ? '0'
          : +(newData?.internalHours / newData?.clientHours).toFixed(2),
      },
    }, [
      'GET_EMPLOYEES_FOR_DEPARTMENT_REQUEST',
      'GET_EMPLOYEES_FOR_DEPARTMENT_SUCCESS',
      'GET_EMPLOYEES_FOR_DEPARTMENT_FAILURE',
    ]);
  };

  const onCountKPI = async () => {
    await Promise.all(departments.map((el) => initMonthlyReports(el?.uuid)));
    if (onCountKPICallback) {
      onCountKPICallback();
    }
  };

  return (
    <Button
      size="small"
      onClick={onCountKPI}
      className={`${buttonClassName} !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white`}
    >
      Пересчитать KPI
    </Button>
  );
}

CountDepartmentKPIButton.propTypes = {
  departments: array,
  buttonClassName: string,
  onCountKPICallback: func,
};
