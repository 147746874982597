import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  get, isEmpty, isEqual, omit,
} from 'lodash';
import { excludeSectionFilterResult } from './filterHelper';
import useArtifactsAPI from '../../../api/useArtifactsAPI';

const useArtifacts = ({
  parentUUID,
  defaultConfig = {},
}) => {
  const dispatch = useDispatch();

  const { getListOfArtifacts } = useArtifactsAPI();

  const [artifacts, setArtifacts] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    limit: 20,
    offset: 0,
    ...defaultConfig,
  });
  const [artifactFilterConfig, setFilterConfig] = useState({
    type: [
      'instruction',
      'file',
      'user',
    ],
    // status: [
    //   'active',
    // ],
  });

  const prepareFilterConfig = (newFilterConfig) => {
    const fixedConfig = { ...newFilterConfig };
    if (!isEmpty(fixedConfig)) {
      const filterKeys = Object.keys(fixedConfig);
      filterKeys.forEach(
        (key) => fixedConfig[key] = excludeSectionFilterResult(fixedConfig[key]),
      );
    }
    return fixedConfig;
  };

  const initFunc = async () => {
    if (parentUUID) {
      setIsFetching(true);
      const artTypes = get(artifactFilterConfig, ['type'], []);
      const artStatuses = get(artifactFilterConfig, ['status'], []);

      const newConfig = {
        depth: 1,
        entity_type: artTypes,
        // params: {
        //   status: artStatuses,
        // },
        ...config,
      };

      const res = await getListOfArtifacts(parentUUID, newConfig, [
        'GET_ARTIFACTS_FOR_DEP_PAGE_REQUEST',
        'GET_ARTIFACTS_FOR_DEP_PAGE_SUCCESS',
        'GET_ARTIFACTS_FOR_DEP_PAGE_FAILURE',
      ]);

      setArtifacts(res?.data ?? []);
      setTotal(res?.total ?? 0);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    setConfig((prev) => ({ ...prev, ...defaultConfig }));
  }, [JSON.stringify(defaultConfig)]);

  useEffect(() => {
    initFunc();
  }, [parentUUID, JSON.stringify(config), JSON.stringify(artifactFilterConfig)]);

  return {
    artifacts,
    isFetching,
    paginationOptions: {
      artifactFilterConfig,
      filterCallback: (newConfig) => {
        const fixedConfig = prepareFilterConfig(newConfig);

        if (!isEqual(artifactFilterConfig, fixedConfig)) {
          setFilterConfig(fixedConfig);
          setConfig((prev) => ({ ...prev, offset: 0 }));
          setCurrentPage(1);
        }
      },
      pageLimit: get(config, 'limit', 5),
      total,
      changePageLimit: (newLimit) => {
        setCurrentPage(1);
        setConfig((prev) => ({ ...prev, offset: 0, limit: newLimit }));
      },
      newCurrentPage: currentPage,
      onChangePageCallback: (newPage) => {
        setCurrentPage(newPage);
        setConfig((prev) => ({ ...prev, offset: (newPage - 1) * prev.limit }));
      },
      getPaginationOptions: (newPart) => {
        const valueSearch = get(newPart, 'search', '');

        if (valueSearch) {
          setConfig((prev) => ({
            ...prev,
            ...omit(newPart, ['search']),
            searchData: {
              value: valueSearch,
              ignore_case: true,
              fields: { params: ['title', 'id'] },
            },
          }));
        } else {
          setConfig((prev) => ({ ...omit(prev, 'searchData'), ...newPart }));
        }
      },
    },
    onReloadArtifacts: initFunc,
    valueSearch: get(config, 'search_data.value', ''),
    onChangeSearch: (value) => {
      setCurrentPage(1);
      if (value === '') {
        setConfig((prev) => ({
          ...omit(prev, 'search_data'),
          limit: get(prev, 'limit', 5),
          offset: 0,
        }));
        return;
      }
      setConfig((prev) => ({
        ...prev,
        limit: get(prev, 'limit', 5),
        offset: 0,
        search_data: {
          fields: {
            uinfo: {
              first_name: 'first_name',
              last_name: 'last_name',
            },
          },
          value,
        },
      }));
    },
  };
};

export default useArtifacts;
