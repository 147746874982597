import React from 'react';

import { Button } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { func, string } from 'prop-types';
import useTriggersVacation from '../../../../events/useTriggersVacation';

export default function AddNewVacation({
  saveCallback,
  actor,
  ...buttonProps
}) {
  const { run, EventComponent } = useTriggersVacation();

  return (
    <>
      <Button
        onClick={() => run('create-vacation-request', {
          saveCallback,
          actor,
        })}
        icon={<PlusCircleFilled />}
        {...buttonProps}
      >
        Добавить заявку на отпуск
      </Button>
      <EventComponent />
    </>
  );
}

AddNewVacation.propTypes = {
  saveCallback: func,
  actor: string,
};
