import React from 'react';

import { Redirect } from 'react-router-dom';

import UserVacationsPage from '../pages/admin/userVacations/UserVacationsPage';
import HelpPage from '../pages/user/HelpPage';
import ProfilePage from '../pages/user/Profile/ProfilePage';
import SettingsPage from '../pages/user/SettingsPage';
import AuthHelper from '../utils/authUtils';

import ErrorBoundary from '../components/ErrorBoundary';
import TimeDashboard from '../pages/user/TimeDashboard';
import ReportsPage from '../pages/user/ReportsPage';
import VacationPage from '../pages/user/VacationPage';
import UserDashboardRoot from '../pages/admin/userDashboard/UserDashboardRoot';

import UsersStatistics from '../pages/user/statistics/UsersStatistics';
import AdminStatistics from '../pages/admin/statistics/AdminStatistics';
import GitVersion54origins from '../54origins/components/publicInterface54Origins/GitVersion54origins';
import ShortLinksRedirect from '../components/ShortLinksRedirect';
import UserDashboardForAdmin from '../pages/admin/userDayDashboard/UserDashboardForAdmin';
import DepartmentPage from '../pages/admin/departments/DepartmentPage';
import MyDepartmentPage from '../pages/user/myDepartment/MyDepartmentPage';
import AdminDashboard from '../pages/admin/adminDashboard/AdminDashboard';
import ReportStatisticsPage from '../pages/user/statistics/ReportStatisticsPage';
import EmployeeRequestsPage from '../pages/admin/employeeRequests/EmployeeRequestsPage';
import DepartmentManagement from '../pages/user/myDepartment/DepartmentManagement';
import DepartmentReportsPage from '../pages/department_management/DepartmentReportsPage';
import DepartmentVacationsPage from '../pages/department_management/DepartmentVacationsPage';
import EventsPage from '../pages/hr/EventsPage';
import EntitiesAndLogsPage from '../pages/admin/EntitiesAndLogsPage';
import DepartmentContractorsPage from '../pages/department_management/DepartmentContractorsPage';
import UsersForHrPanelPage from '../pages/hr/UsersForHrPanelPage';
import MainDepartmentLayer from '../components/departments/MainDepartmentLayer';
import EventsManagmentPage from '../pages/hr/EventsManagmentPage';
import OnboardingPage from '../pages/hr/OnboardingPage';
import EventsPanelPage from '../pages/hr/EventsPanelPage';

const checkApt = (component) => (
  <ErrorBoundary>
    {AuthHelper.isAuthorised() ? component : <Redirect to="/login" />}
  </ErrorBoundary>
);

export const routes = [
  {
    path: '/profile',
    component: checkApt(<ProfilePage />),
    exact: true,
  },
  {
    path: '/reports',
    component: checkApt(<ReportsPage />),
    exact: true,
  },
  {
    path: '/vacations',
    component: checkApt(<VacationPage />),
    exact: true,
  },
  {
    path: '/mydepartment',
    component: checkApt(<MyDepartmentPage />),
    exact: true,
  },
  {
    path: '/settings',
    component: checkApt(<SettingsPage />),
    exact: true,
  },
  {
    path: '/help',
    component: checkApt(<HelpPage />),
    exact: true,
  },
  {
    path: '/deliverydashboard',
    component: checkApt(<TimeDashboard />),
    exact: true,
  },
  {
    path: '/version',
    component: checkApt(<GitVersion54origins />),
  },
  {
    path: '/s',
    component: checkApt(<ShortLinksRedirect />),
  },
  {
    path: '/onboarding',
    component: checkApt(<OnboardingPage />),
  },
];

export const hrRoutes = [
  {
    path: '/statistics/*',
    component: checkApt(<ReportStatisticsPage />),
    exact: true,
  },
  {
    path: '/hrpanel/users/',
    component: checkApt(<UsersForHrPanelPage />),
    exact: true,
  },
  {
    path: '/events',
    component: checkApt(<EventsPanelPage />),
    exact: true,
  },
];

export const departmentRoutes = [
  {
    path: '/departmentReports',
    component: checkApt(<DepartmentReportsPage />),
    exact: true,
  },
  {
    path: '/departmentManagement',
    component: checkApt(<DepartmentManagement />),
    exact: true,
  },
  {
    path: '/departmentVacations',
    component: checkApt(<DepartmentVacationsPage />),
    exact: true,
  },
  {
    path: '/departmentContractors',
    component: checkApt(<DepartmentContractorsPage />),
    exact: true,
  },
  {
    path: '/requests',
    component: checkApt(<EmployeeRequestsPage isDepartmentRoutes />),
    exact: true,
  },
  {
    path: '/department',
    component: checkApt(<MainDepartmentLayer />),
  },
];

export const adminRoutes = [
  // {
  //   path: '/statistics/*',
  //   component: checkApt(<ReportStatisticsPage />),
  //   exact: true,
  // },
  {
    path: '/admin/vacations',
    component: checkApt(<UserVacationsPage />),
    exact: true,
  },
  {
    path: '/admin/statistics',
    component: checkApt(<AdminStatistics />),
    exact: true,
  },
  {
    path: '/departments',
    component: checkApt(<DepartmentPage />),
  },
  {
    path: '/userdashboard',
    component: checkApt(<UserDashboardRoot />),
  },
  {
    path: '/dayuserreport',
    component: checkApt(<UserDashboardForAdmin />),
  },
  {
    path: '/admin/dashboard',
    component: checkApt(<AdminDashboard />),
  },
  {
    path: '/logs',
    component: checkApt(<EntitiesAndLogsPage />),
  },
];
