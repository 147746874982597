import React from 'react';
import { Modal, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import CMECreateVacationRequestForm from '../create/CMECreateVacationRequestForm';
import './EditMetaEventModal.css';
import { pick } from 'lodash';

function EditMetaEventModal({
  isVisible,
  onCancel,
  onSubmit,
  trigger,
  metaEvent,
}) {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (metaEvent) {
      form.setFieldsValue(pick(metaEvent?.params, ['title', 'instruction']));
    }
  }, [metaEvent, form]);

  const handleSubmit = (values) => {
    onSubmit({
      ...values,
    });
    form.resetFields();
  };

  const handleCancel = () => {
    const hasUnsavedChanges = form.isFieldsTouched();

    if (hasUnsavedChanges) {
      Modal.confirm({
        title: 'В форме есть несохраненные данные',
        content: 'Вы уверены, что хотите закрыть окно? Все несохраненные данные будут потеряны.',
        okText: 'Да, закрыть',
        cancelText: 'Нет, остаться',
        onOk: () => {
          form.resetFields();
          onCancel();
        },
      });
    } else {
      onCancel();
    }
  };

  return (
    <Modal
      title={(
        <>
          <EditOutlined />
          Редактирование метасобытия
        </>
      )}
      open={isVisible}
      onCancel={handleCancel}
      footer={null}
      width={600}
      className="edit-meta-event-modal"
      maskClosable={false}
    >
      {trigger && (
        <CMECreateVacationRequestForm
          form={form}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isEdit
        />
      )}
    </Modal>
  );
}

export default EditMetaEventModal;
