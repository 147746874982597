import React from 'react';
import {
  Typography, Card, Alert, Flex,
} from 'antd';
import {
  UserOutlined,
  LaptopOutlined,
  FileTextOutlined,
  TeamOutlined,
  WifiOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;

// Компонент для иконки с градиентным фоном
function IconWrapper({ icon: Icon }) {
  return (
    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 text-white text-xl">
      <Icon />
    </div>
  );
}

function OnboardingPage() {
  return (
    <div className="min-h-screen ">
      <div className=" p-6">
        {/* Заголовок с подсветкой */}
        <div className="text-center mb-12">
          <div className="inline-block">
            <Title level={1} className="!mb-2">
              <span className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400">
                Добро пожаловать в команду!
              </span>
            </Title>
            <div className="h-1 w-32 mx-auto bg-gradient-to-r from-blue-600 to-blue-400 rounded-full" />
          </div>
        </div>

        <Alert
          message="Важная информация"
          description="Пожалуйста, внимательно ознакомьтесь со всеми разделами этой страницы"
          type="info"
          showIcon
          className="mb-8 shadow-md rounded-lg border-l-4 border-l-blue-500"
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <Card
            title={(
              <div className="flex items-center space-x-3">
                <IconWrapper icon={ClockCircleOutlined} />
                <span className="text-lg font-semibold">План онбординга</span>
              </div>
          )}
            className="hover:shadow-xl w-full transition-shadow duration-300 rounded-xl border-none shadow-md bg-white"
            headStyle={{ borderBottom: '2px solid #f0f0f0', padding: '20px' }}
            bodyStyle={{ padding: '24px' }}
          >
            <Paragraph>
              <Flex justify="space-between" className="mr-20">
                <ul className="list-none space-y-4">
                  <li className="flex items-start space-x-2">
                    <CheckCircleOutlined className="text-blue-500 mt-1" />
                    <span>
                      <Text strong>Организационные вопросы</Text>
                      <ul className="list-disc pl-6 mt-1">
                        <li>Оформление документов в HR</li>
                        <li>Получение пропуска</li>
                        <li>Знакомство с офисом</li>
                      </ul>
                    </span>
                  </li>

                  <li className="flex items-start space-x-2">
                    <CheckCircleOutlined className="text-blue-500 mt-1" />
                    <span>
                      <Text strong>Рабочее окружение</Text>
                      <ul className="list-disc pl-6 mt-1">
                        <li>Настройка рабочего места</li>
                        <li>Получение доступов к системам</li>
                        <li>Настройка ПО</li>
                      </ul>
                    </span>
                  </li>
                </ul>
                <ul>

                  <li className="flex items-start space-x-2">
                    <CheckCircleOutlined className="text-blue-500 mt-1" />
                    <span>
                      <Text strong>Знакомство с командой</Text>
                      <ul className="list-disc pl-6 mt-1">
                        <li>Встреча с командой проекта</li>
                        <li>Знакомство с ментором</li>
                        <li>Встреча с руководителем</li>
                      </ul>
                    </span>
                  </li>

                  <li className="flex items-start space-x-2">
                    <CheckCircleOutlined className="text-blue-500 mt-1" />
                    <span>
                      <Text strong>Погружение в работу</Text>
                      <ul className="list-disc pl-6 mt-1">
                        <li>Изучение документации</li>
                        <li>Знакомство с проектами</li>
                        <li>Первые рабочие задачи</li>
                      </ul>
                    </span>
                  </li>
                </ul>
              </Flex>
            </Paragraph>
          </Card>
          {/* Информация об отделе */}
          <Card
            title={(
              <div className="flex items-center space-x-3">
                <IconWrapper icon={TeamOutlined} />
                <span className="text-lg font-semibold">Информация об отделе</span>
              </div>
            )}
            className="hover:shadow-xl transition-shadow duration-300 rounded-xl border-none shadow-md bg-white"
            headStyle={{ borderBottom: '2px solid #f0f0f0', padding: '20px' }}
            bodyStyle={{ padding: '24px' }}
          >
            <Paragraph>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  Руководитель отдела:
                  <Text strong>Иванов Иван Иванович</Text>
                </li>
                <li>
                  Основные направления:
                  <ul className="list-circle pl-6 mt-2">
                    <li>Разработка внутренних продуктов</li>
                    <li>Поддержка клиентских проектов</li>
                    <li>Интеграционные решения</li>
                  </ul>
                </li>
                <li>
                  Команда состоит из 25 человек:
                  <ul className="list-circle pl-6 mt-2">
                    <li>15 разработчиков</li>
                    <li>5 QA-инженеров</li>
                    <li>3 бизнес-аналитика</li>
                    <li>2 проектных менеджера</li>
                  </ul>
                </li>
              </ul>
            </Paragraph>
          </Card>

          {/* Ментор и контакты */}
          <Card
            title={(
              <div className="flex items-center space-x-3">
                <IconWrapper icon={UserOutlined} />
                <span className="text-lg font-semibold">Ваш ментор и контакты</span>
              </div>
            )}
            className="hover:shadow-xl transition-shadow duration-300 rounded-xl border-none shadow-md bg-white"
            headStyle={{ borderBottom: '2px solid #f0f0f0', padding: '20px' }}
            bodyStyle={{ padding: '24px' }}
          >
            <Paragraph>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  Ваш ментор:
                  <Text strong>Петров Петр Петрович</Text>
                  <ul className="list-circle pl-6 mt-2">
                    <li>Telegram: @petr_mentor</li>
                    <li>Email: petr.mentor@company.com</li>
                    <li>Тел.: +7 (999) 123-45-67</li>
                  </ul>
                </li>
                <li>
                  График встреч с ментором:
                  <ul className="list-circle pl-6 mt-2">
                    <li>Ежедневный созвон: 10:00 - 10:30</li>
                    <li>Еженедельная встреча: пятница, 15:00 - 16:00</li>
                  </ul>
                </li>
                <li>
                  HR-менеджер:
                  <Text strong>Сидорова Анна</Text>
                  <ul className="list-circle pl-6 mt-2">
                    <li>Email: hr@company.com</li>
                    <li>Тел.: +7 (999) 765-43-21</li>
                  </ul>
                </li>
              </ul>
            </Paragraph>
          </Card>

          {/* Техническое обеспечение */}
          <Card
            title={(
              <div className="flex items-center space-x-3">
                <IconWrapper icon={LaptopOutlined} />
                <span className="text-lg font-semibold">Техническое обеспечение</span>
              </div>
            )}
            className="hover:shadow-xl transition-shadow duration-300 rounded-xl border-none shadow-md bg-white"
            headStyle={{ borderBottom: '2px solid #f0f0f0', padding: '20px' }}
            bodyStyle={{ padding: '24px' }}
          >
            <Title level={4}>Получение ноутбука</Title>
            <Paragraph>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  Системный администратор:
                  <Text strong>Александр Техподдержкин</Text>
                </li>
                <li>Кабинет: 404</li>
                <li>Внутренний телефон: 1234</li>
                <li>Email: support@company.com</li>
              </ul>
            </Paragraph>

            <Title level={4} className="mt-4">
              <WifiOutlined className="mr-2" />
              Wi-Fi подключение
            </Title>
            <Paragraph>
              <ul className="list-disc pl-6 space-y-2">
                <li>Основная сеть: Company_WiFi</li>
                <li>Пароль: CompanyPass2024</li>
                <li>Гостевая сеть: Company_Guest</li>
                <li>Пароль для гостей: Guest2024</li>
              </ul>
            </Paragraph>
          </Card>

          {/* Отчетность */}
          <Card
            title={(
              <div className="flex items-center space-x-3">
                <IconWrapper icon={FileTextOutlined} />
                <span className="text-lg font-semibold">Отчетность</span>
              </div>
            )}
            className="hover:shadow-xl transition-shadow duration-300 rounded-xl border-none shadow-md bg-white"
            headStyle={{ borderBottom: '2px solid #f0f0f0', padding: '20px' }}
            bodyStyle={{ padding: '24px' }}
          >
            <Title level={4}>Дневной отчет</Title>
            <Paragraph>
              <ul className="list-disc pl-6 space-y-2">
                <li>Срок: ежедневно до 18:00</li>
                <li>
                  Система для заполнения:
                  <a href="http://reports.company.com">reports.company.com</a>
                </li>
                <li>
                  Что включать:
                  <ul className="list-circle pl-6 mt-2">
                    <li>Список выполненных задач</li>
                    <li>Затраченное время на каждую задачу</li>
                    <li>Возникшие проблемы и их решения</li>
                    <li>План на следующий день</li>
                  </ul>
                </li>
              </ul>
            </Paragraph>

            <Title level={4} className="mt-4">Месячный отчет</Title>
            <Paragraph>
              <ul className="list-disc pl-6 space-y-2">
                <li>Срок: до 5-го числа каждого месяца</li>
                <li>
                  Форма отчета:
                  <a href="http://reports.company.com/monthly">Шаблон месячного отчета</a>
                </li>
                <li>
                  Требования к заполнению:
                  <ul className="list-circle pl-6 mt-2">
                    <li>Основные достижения за месяц</li>
                    <li>Статистика по выполненным задачам</li>
                    <li>Предложения по улучшению процессов</li>
                    <li>Планы на следующий месяц</li>
                  </ul>
                </li>
              </ul>
            </Paragraph>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default OnboardingPage;
